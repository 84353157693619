  
import styled from "styled-components";

export let HomeWrapper = styled.div`
  .slick-arrow {
    background-color: #61dafb;
    height: 35px;
    width: 35px;
    border-radius: '20'
   
  }
  .slick-arrow:hover,
  .slick-arrow:active,
  .slick-arrow:focus {
    background-color: #61dafb !important;
  }
`;