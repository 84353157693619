import React, { PureComponent } from 'react';
import { Button, Card, Row, Container, FormControl, Modal } from 'react-bootstrap';
import { searchSoat } from '../../../services/soat.service';
import { DateToString } from '../../../utilities/formats';
import { ClientInfo } from './componets/client-info';
import { VehicleInfo } from './componets/vehicle-info';
import { SendPolicy } from './componets/send-policy'
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { /* faHome , */faBackspace } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../../shared/header/header';


export class SoatComprar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showClientInfo: false,
            showVehicleInfo: false,
            showPaymentInfo: false,
            documentNumber: "",
            licensePlate: "",
            documentType: 1,
            insurance: 1,
            date: this.getDate(1),
            date2: this.getDate(1),
            showDate: false,
            loading: false,
            soats: [{ id: -1 }],
            datepolicy: this.getDate(1),
            dataContact: {},
            dataVehicle: {},
            getVehiculo: {},
            temps: {},
            idSoat: {}
            /* endDatesend:{}, */
        }
        this.minDate = this.getDate(1);
 
    }

  
    componentDidMount =async () => {
        const product = await JSON.parse(localStorage.getItem("products")); 
        if (product.soats) {
           //this.soats = product.soats;  
            this.setState({ loading: false,soats:product.soats })
           
        }
      
    }

    getDate = (addDays = 0) => {
        let date = new Date();
        date.setDate(date.getDate() + addDays);
        return DateToString(date, true);
    }

    clearData = () => {
        this.setState({
            loading: false,
            date: this.getDate(1),
            date2: this.getDate(1),
            documentNumber: "",
            licensePlate: "",
            dataContact: {},
            getVehiculo: {},
            showClientInfo: false,
            showPaymentInfo: false,
            showVehicleInfo: false,
        })
    }


    setSearchedData = (res, flag) => {
        
        if (flag) {
            if (res.errores) {
                if (res.errores.observacion === "'NoneType' object has no attribute 'update'") {
                    const error = () => toast.error('Error, No existe informacion de estos datos');
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (res.errores.observacion) {
                    const error = () => toast.error(res.errores.observacion);
                    error();
                    if (error) {
                        this.clearData();
                    }
                }
                else {
                    const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuniquese con MóvilGo");
                    error();
                }
            } else {

                let str = JSON.stringify(res).replace(/\\/g, '');
                str = str.replace('""""', '""');
                str = str.replace('"{', '{');
                str = str.replace('}"', '}');
                str = str.replace('""""', '""');
                str = str.replace('"{', '{');
                str = str.replace('}"', '}');

                const json = JSON.parse(str);
                const TarifaError = json.soat.respuesta_tarifa.error;
                const Vehicle = json.soat.respuesta_vehiculo.VehicleBodyTypeId
                const Poliza = json.soat.respuesta_poliza_vigente.Message
                const Mesajetarifa = json.soat.respuesta_tarifa.Message
                if (Vehicle === null) {
                    const error = () => toast.error("Error, Algunos datos vienen incompletos. Comuniquese con MovilGo");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (TarifaError) {
                    const error = () => toast.error("Error, No se puede realizar la compra dirijasea ISOAT ");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (Poliza !== "") {

                    const error = () => toast.error("Error, Existe una o varias polizas vigentes coincidente con los datos de la placa ");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (Mesajetarifa) {
                    const error = () => toast.error("Error, La tarifa Viene incompleta");
                    error();
                    if (error) {
                        this.clearData();
                    }
                } else if (json) {
                    this.setState({
                        dataVehicle: json,
                        showVehicleInfo: true,
                        loading: false,
 
                    });
                } else {
                    const error = () => toast.error("Error, Ocurrio un error al obtener datos, verfique la Cédula ingresada.");
                    error();
                    if (error) {
                        this.clearData();
                    }
                 
                }
            }
        } else {
            const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuníquese con MóvilGo");
            error();
        
            if (error) {
                this.clearData();
            }
        }
    }

    sendData = () => {
               

        if (this.state.licensePlate.match(/[^a-zA-Z0-9\s/]/) !== null || this.state.licensePlate === '') {
            this.setState({ loading: false });
            const error = () => toast.error("Error, La placa no debe contener caracteres especiales o estar vacia");
            error();
        } else {
           this.setState({ loading: true })
            let date = this.state.date.split("-");
            const year = parseInt(date[0]) + 1;

            searchSoat(
                this.state.documentNumber.trim(),
                this.state.licensePlate.trim(),
                this.state.soats[0].id ,
                this.state.date,
                year + "-" + date[1] + "-" + date[2],
                this.setSearchedData,
            );
            
        }
    }

    contact = (response) => {
        
        this.setState({ dataContact: response })
    };
    vehicle = (response) => {
        this.setState({ getVehiculo: response })
    }

    render() {
        return (
        <>
            <div style={{position:'relative',index:1,display:'inlineBlock',marginTop:20}}>
                        <Header deslogear={this.state.deslogear} newlogeo={(flag)=>this.setState({deslogear:flag})} /> 
            </div>
            <Container style={{height:600,width:'30%',marginTop:'1%'}} >
                <div><h2 style={{color:'white'}}>Comprar Soat</h2></div>
                <ToastContainer />
                <Row className="justify-content-md-center">
                    <Card className='reloadsCard' style={{ minWidth: '40%' }}>
                        <Card.Body>
                            <div> <h3>Ingrese número de documento</h3> </div>
                            <FormControl placeholder="Cédula"
                                arial-label="Numero de identificación de la persona"
                                value={this.state.documentNumber}
                                onChange={e => this.setState({ documentNumber: e.target.value.replace(/\D/, '') })} />
                            <div> <h3>Ingrese placa del vehiculo</h3> </div>
                            <FormControl placeholder='Placa'
                                arial-label="Numero de identificación del vehiculo"
                                value={this.state.licensePlate}
                                onChange={(event) => this.setState({ licensePlate: event.target.value })} />
                            <div> <h3>Seleccione fecha de inicio</h3> </div>
                            <div>
                                <input type="date" name="trip-start"
                                    value={this.state.date} min={this.minDate}//value={this.state.date2} 
                                    onChange={e => { this.setState({ date: e.target.value },);  }}  /*console.log(e.target.value) { date2: e.target.value } */
                                />
                            </div>
                            <Button variant={'info'} className="mt-2"
                                onClick={() => { this.sendData(); }}>
                                Buscar
                            </Button>
                        </Card.Body>
                    </Card>

                    {this.state.loading && <LoadingMovilGo />}

                    
                    <Modal show={this.state.showVehicleInfo} onHide={() => this.clearData()} backdrop="static">
                        <Modal.Header style={{ textAlign: "center",}} closeButton >
                            <Modal.Title  >Compra Soat</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <VehicleInfo
                                data={this.state.dataVehicle}
                                infoVehicle={() => this.vehicle()}
                                dataVehicle={this.state.getVehiculo}
                                closeAction={() => this.clearData()}
                                confirmAction={() => this.setState({
                                showVehicleInfo: false, showClientInfo: true
                                })} >
                            </VehicleInfo>
                        </Modal.Body>
                    </Modal>

                    <Modal show={this.state.showClientInfo} onHide={() => this.clearData()} backdrop="static">
                        <Modal.Header closeButton >
                            <FontAwesomeIcon style={{ marginTop: 10 }} icon={faBackspace} size="lg" color='#BDBDBD'
                                onClick={() => this.setState({ showClientInfo: false, showVehicleInfo: true })} />
                        </Modal.Header>
                        <Modal.Body>
                            <ClientInfo
                                data={this.state.dataVehicle}
                                dataContact={this.state.dataContact}
                                back={() => this.setState({ showVehicleInfo: true, showClientInfo: false, })}
                                infoContact={this.contact}
                                confirmAction={() => this.setState({ showClientInfo: false, showPaymentInfo: true })}
                            />
                        </Modal.Body>
                    </Modal>

                 
                    <Modal show={this.state.showPaymentInfo} onHide={() => this.clearData()} backdrop="static">
                        <Modal.Header closeButton >
                            <FontAwesomeIcon style={{ marginTop: 10 }} icon={faBackspace} size="lg" color='#BDBDBD'
                                onClick={() => this.setState({ showClientInfo: true, showPaymentInfo: false })} />
                        </Modal.Header>
                        <Modal.Body>
                            <SendPolicy
                                data={this.state.dataVehicle}
                                date={this.state.date2}
                                back={() => this.setState({ showClientInfo: true, showPaymentInfo: false })}
                                dataContact={this.state.dataContact}
                                infoVehicle={this.state.getVehiculo}
                                productId={this.state.soats[0].id}
                                closeAction={() => this.clearData()}
                                loading={this.state.loading}
                                setLoading={(loading) => this.setState({ loading })}
                            />
                        </Modal.Body>
                    </Modal>
                </Row>
            </Container>
        </>
            
        )
    }
}

