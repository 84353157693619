import React, { useState,useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { saveClient } from '../../../services/cliente.service';
import { LoadingMovilGoRegistro } from '../../../shared/loadingMovilGo';

export const ModalRegistro = (props) => {
    const { visible, closeModal, id_product, setCliente, cedula, setParther,clientOdoo } = props;
    //const [cedula, setCedula] = useState('');
    const [nombre, setNombre] = useState(clientOdoo.x_name1 ? clientOdoo.x_name1:'');
    const [apellido, setApellido] = useState(clientOdoo.x_lastname1 ? clientOdoo.x_lastname1:'');
    const [direccion, setDireccion] = useState(clientOdoo.street ? clientOdoo.street:'' );
    const [email, setEmail] = useState(clientOdoo.login ? clientOdoo.login:'' );
    const [celular, setCelular] = useState(clientOdoo.mobile ? clientOdoo.mobile:'' );
    const [opacity, setOpacity] = useState(1);
    const [loading, setLoading] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [errors, setErrors] = useState( {
        'name': '',
        'lastname': '',
        'email':'',
        'mobile': '',
        'direccion': '',
    });
   
    const saved = (response) => {
        if (response) {
            setCliente(cedula, nombre, response.id);
            setParther(response.id);
            setLoading(false);
            setOpacity(1)
            closeModal(false);
        }
        cleanFields()
    }
    const cleanFields = () => {
        setNombre('');
        setApellido('');
        setDireccion('');
        setEmail('');
        setCelular('');
    }
    const registrarCliente = () => {
        if (!viriryFile()) {
            return
        } 
        const client = {
            'x_name1': nombre.split(' ')[0],
            'x_name2': nombre.split(' ')[1] ,
            'x_lastname1': apellido.split(' ')[0],
            'x_lastname2': apellido.split(' ')[1] ,
            'mobile': celular,
            'email': email,
            'direccion': direccion,
            'xidentification': cedula,
            product_id: id_product
        } 
        
        console.log("este men paso")
        setLoading(true);
        setOpacity(0.4)
        saveClient(client, saved);
    }

    const onBlur = () => {
        if (email !== '') {
            const validate = correo.test(email);
            if (validate) {
                setEmailValid(false);
            } else {
                setEmailValid(true);
            }
        }else{
            setEmailValid(false); 
        }
    }
    const viriryFile = () => {
        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        const nameValid= nombre.match(/[^a-zA-Z0-9\s/]/)
        const lastValid=apellido.match(/[^a-zA-Z0-9\s/]/)  
        let flag = true;
        const keys = [
            'nombre',
            'apellido',
            'celular',
            'direccion',
            'email',
        ]
        const errors = {};
        keys.forEach(element => {
            if(element==='email'&&!emailValid){
                flag = false;
                setEmailValid(true);
                errors[element] = "El correo en incorrecto"
            }else if (element==='nombre'&&nameValid) {
                flag = false;
                errors[element] = "El nombre no debe llevar tildes ni caracteres especiales"
            }else if (element==='apellido'&&lastValid) {
                flag = false;
                errors[element] = "El apellido no debe llevar tildes ni caracteres especiales"
            }else if (element.trim() === "") {
                flag = false;
                errors[element] = "Este campo es obligatorio"
            } else {
                errors[element] = ""
            }
        });
        setErrors(errors)
        return flag;
    }

    let correo = /^[^@\s]+@[^@\.\s]+(\.[^@\.\s]+)+$/;
    
    return (
        <>
            <Modal
                size='lg'
                show={visible}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className={'defaultTitle'}>Datos Personales</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: '100%', height: '100%' }}>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                {loading && < LoadingMovilGoRegistro />}
                            </div>
                            <div style={{ opacity: opacity }}>
                                <Form.Group className={'defaultTitle'}>
                                    <Form.Label>Cédula</Form.Label>
                                    <Form.Control disabled={true} value={cedula} type="text" placeholder="Cédula" />
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control value={nombre} onChange={(event) => setNombre(event.target.value)} type="text" placeholder="Nombre" />
                                        <p style={{ fontSize: 12, color: 'red' }}>{errors.nombre} <br></br></p>
                                    <Form.Label>Apellido</Form.Label>
                                    <Form.Control value={apellido} onChange={(event) => setApellido(event.target.value)} type="text" placeholder="Apellido" />
                                        <p style={{ fontSize: 12, color: 'red' }}>{errors.apellido} <br></br></p>
                                    <Form.Label>Direccion</Form.Label>
                                    <Form.Control  value={direccion} onChange={(event) => setDireccion(event.target.value)} type="text" placeholder="Direccion" />
                                        <p style={{ fontSize: 12, color: 'red' }}>{errors.direccion} <br></br></p>
                                    <Form.Label>Correo Electronico</Form.Label>
                                    <Form.Control value={email} onChange={(event) => setEmail(event.target.value)} type="email" placeholder="Correo Electonico(Opcional)" />
                                        <p style={{ fontSize: 12, color: 'red' }}>{errors.email} <br></br></p>
                                    <Form.Label >Celular</Form.Label>
                                    <Form.Control value={celular} onChange={(event) => setCelular(event.target.value)} type="number" placeholder="Celular" />
                                        <p style={{ fontSize: 12, color: 'red' }}>{errors.celular} <br></br></p>
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closeModal(false)}>
                        Cancelar
                        </Button>
                    <Button
                        variant="info"
                        onClick={() => registrarCliente()}
                        disabled={!cedula || !nombre || !apellido || !direccion || !celular || (!correo.test(email) ? email : "")}
                    >
                        Crear Cliente
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}