import React, { PureComponent } from 'react';
import { Row,Modal } from 'react-bootstrap';
import ReactShadowScroll from 'react-shadow-scroll';
import { SearchBoletas } from './search-boleta';
import { ListBallotsUser } from './list';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ModalAbonar } from './modal-abonar';
import { getClient } from '../../../services/cliente.service';
import { searchPayments } from '../../../services/rifa.service';
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import Header from '../../../shared/header/header';
import HeaderCardoso from '../../../shared/header/header-cardoso';
class Abonos extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            deslogear:false,
            visible: false,
            cedula: '',
            selectedPrice: '',
            boleta: '',
            visibleBoletas: false,
            listaAbonos: [],
            cont: 0,
            prices: [],
            visiblePayTicket: false,
            selectedPayment: {},
            loading:false,
            pay:false,
            product:{},
            idHeader: localStorage.getItem('idDelPadre')
        }
        this.listaAbonos = {};
        this._isMounted = false;
    }

   
    componentDidMount = async() => {
        
        (async()=>{
            this.setState({loading:true})
            this.searchClient()
        })();

    }
   
    searchClient=async()=>{
        await getClient(async(res,flag)=>{
            
            //console.log("getcliente flag",flag)
            //console.log("getcliente response",res)
            if(flag){
                //console.log("paso al if de client")
                this.setState({document:res.xidentification})
                const rifa = JSON.parse(localStorage.getItem('datosRifa'));
                await searchPayments('cedula', res.xidentification, rifa.id, this.foundPayments);
                //this.findPayments()
            }else{
                this.setState({loading:false})
                const error = () => toast.error("¡No se encontraron Datos del cliente !");
                error();
            }

        })
    }

    foundPayments = (response, flag) => {
        //console.log("flag foundPayments",flag)
        //console.log("resonse foundPayments",response)
        this.setState({loading:false})
        if (flag) {
            let _list = { abonos: [] }
            response.abonos.forEach(element => {
                    const _element = element;
                    _element['boleta'] = this.state.document;
                    _list.abonos.push(_element)
                });
            this.setListAbonos(_list);    
            
        } else {
           const error=()=> toast.error("Ocurrio un error al consultar la boletas")
           error()
        }

    }

    selectPrice = (price) => {
        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });
        }
    }


    setListAbonos = (list) => {
        const noEncontre = () => toast.error("¡No se encontraron resultados !");
        this.listaAbonos = list;

        if (this.listaAbonos.abonos.length === 0) {
            noEncontre();
        }else{
            this.setState({ listaAbonos: list, cont: this.listaAbonos.abonos.length,showModalBoletas:true });
            this.price();
        }
        
        
    }

    price = () => {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        
        const prices = [
            rifa.precio,
            rifa.precio / 2,
            "Otro valor"
        ];
        this.setState({ prices })
    }
    render() {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        return (
            <>
                <style>
                    {`
                    .box{
                        width: 120%;
                        heigth: 25%;
                        overflow: hidden;
                    }
                `}
                </style>
                <ToastContainer autoClose={2000} />
                <div style={{position:'relative',index:1,display:'inlineBlock',marginTop:20}}>
                    {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                        newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                        deslogear={this.state.deslogear} 
                                                                        newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>
                <h2 style={{marginTop:'5%', color:'white'}} className={'defaultTitle'}>Abonos</h2>

                <div style={{ padding: '10px 10% 10px 10%',height:500 }}>

                    <Row className="justify-content-md-center" >

                        <div className='box'
                            style={{ maxHeight: '200px', maxWidth: '80rem' }}>

                            

                        </div>
                        <Row style={{ maxWidth: '100%' }} className="justify-content-md-center">
                            <h4 style={{color:'white'}} className={'defaultTitle'}>{rifa.name}</h4>
                        </Row>
                    </Row>
                    <div  style={{backgroundColor:'red'}}>
                         {this.state.loading && <LoadingMovilGo />}
                    </div>
                   
                        <Modal
                            centered
                            size="lg"
                            show={this.state.showModalBoletas}
                            keyboard={false}
                            onHide={()=>{this.setState({showModalBoletas:false});}}
                            
                        >
                
                            <Modal.Body  style={{width:840,marginLeft:-20}}>
                                <ReactShadowScroll  isShadow={false} scrollWidth={10} >
                                    <div style={{height:400}}>
                                        <ListBallotsUser listaAbonos={this.state.listaAbonos.abonos} setPayment={(data) => this.setState({ selectedPayment: data })} setVisible={() => this.setState({ visiblePayTicket: true })} />
                                    </div>
                                </ReactShadowScroll>

                            </Modal.Body>
                            
                        </Modal>
                    <ModalAbonar 
                        dataTicket={this.state.selectedPayment} 
                        visible={this.state.visiblePayTicket} 
                        closeModal={() => this.setState({ visiblePayTicket: false ,showModalBoletas:false})} 
                        navigate={(ruta)=>this.props.navigate(ruta)}
                    />
                </div>
            </>
        )
    }

}

export default Abonos;