
var CryptoJS = require("crypto-js");
export const Encrypt = (data) => {

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
    localStorage.setItem("Session", ciphertext);
}

export const Desencrypt = () => {
    const data = localStorage.getItem('Session');
    if (data) {
        var bytes = CryptoJS.AES.decrypt(data, 'my-secret-key@123');
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return (decryptedData);
    }
    return null;

}

