import React, { PureComponent } from 'react';
import { ListOperator } from '../../shared/list-operator';
import woman from '../../assets/home/img4.png';
import { Summary } from './components/summary';
import { ListPrices } from '../../shared/list-prices';
import { Container, Row, Col, Form, Card, Button, Image } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../services/product.service';
import { LoadingMovilGo } from '../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/modalNotificationTransaction';
import { ToastContainer, toast } from 'react-toastify';
import { ManagmentErrors } from '../../shared/managmentErrors';
import { jsPdfGenerator } from '../../shared/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';
import Header from '../../shared/header/header';
import { PayScreen } from '../../shared/pay/pay';
import { Url_home, idClienteFinal } from '../../utilities/odoo-config';
import HeaderCardoso from '../../shared/header/header-cardoso';
class RecargasDeportivas extends PureComponent {

    constructor(props) {
        super(props);
        this.recargasDeportivas = []/* this.props.recargasDeportivas */;
        this.state = {
            deslogear: false,
            visible: false,
            loading: true,
            product: {},
            localProduct: {},
            selectedOperator: {
                id: -1,
                image_medium: null,
                name: ''
            },
            recargasDeportivas: [],
            selectedPrice: '',
            phone: '',
            idClient: '',
            modalNotification: false,
            opacity: 1,
            active: false,
            idHeader: localStorage.getItem('idDelPadre')
        }
        this.listRecargasDeportivas = [];
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.clean = ''
    }

    getResponse = (flag, response) => {
        if (flag) {
            this.response = response;
            const success = () => toast.success("Recarga realizada exitosamente");
            success();
            this.setState({
                modalNotification: true, opacity: 1, idClient: '', selectOperator: {
                    id: -1,
                    image_medium: null,
                    name: ''
                },
                phone: '',
                selectPrice: ''
            });
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            this.setState({ opacity: 1 })
        }
    }
    componentDidMount = async () => {
        const product = await JSON.parse(localStorage.getItem("products"));
        //console.log("product.recargasDeportivas",product.recargasDeportivas)
        if (product.recargasDeportivas) {
            this.recargasDeportivas = product.recargasDeportivas;
            this.setState({ loading: false, active: true })

        } else {
            this.setState({ loading: false })
            const error = () => toast.error("Este producto no esta disponible");
            error();
        }

    }


    // Check the real id of the require values 
    sendData = async () => {
        this.phone = this.state.phone;
        this.valor = this.state.selectedPrice;
        this.operador = this.state.selectedOperator.name
        //const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal")); 
        //  
        const product = {
            product_id: this.state.selectedOperator.id,
            clienteexterno_user_id: idClienteFinal,
            atributes: {
                numero: this.state.phone,
                precio: this.state.selectedPrice,
                document: this.state.idClient
            }
        }
        this.setState({
            opacity: 0.4,
            pay: true,
            product: product,
            localProduct: {
                nameProduct: "recargaDeportiva",
                operador: this.operador,
                phone: this.phone,
                valor: FormatMoney(this.valor)
            }
        })

        await Transaction(product, this.getResponse);
    }

    selectOperator = (operator) => {
        this.cleanFields();
        this.setState({ selectedOperator: operator });
    }

    selectPrice = (price) => {
        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });
        }
    }
    closeModal = (flag) => {
        this.setState({ modalNotification: flag });

        const data = [
            "Factura No: " + this.response.valida.id,
            "Fecha:" + this.response.valida.fecha,
            "No.Aprobacion:" + this.response.valida.numero_aprobacion,
            "Producto: " + this.operador,
            "Celular: " + this.phone,
            "Valor: " + FormatMoney(this.valor)
        ]
        jsPdfGenerator(data);
    }

    cleanFields = () => {
        this.setState({
            clean: false,
            selectedPrice: ''
        })
    }

    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                ` }
                </style>
                <ToastContainer />
                <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
                {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>

                {this.state.idHeader !== "movilgo" ?
                    <div>
                        <h2 style={{ color: '#0D606E', marginLeft: '40%', marginTop: '1%' }} className={'defaultTitle'}>Apuestas Deportivas</h2>
                        <div className="container" style={{ height: 633, opacity: this.state.opacity }}>
                            {this.state.active && <div>
                                <Row className="justify-content-md-center">
                                    <Col>
                                        <ListOperator onClick={(i) => this.selectOperator(i)} operators={this.recargasDeportivas} />
                                    </Col>
                                </Row>
                                <Card className='reloadsCard'>
                                    <Card.Body>
                                        <Container className="" fluid='md'>
                                            <div>
                                                {this.state.selectedOperator.image_medium && <Image src={'data:image/jpeg;base64,' + this.state.selectedOperator.image_medium} style={{ maxHeight: '80px' }} />}
                                            </div>
                                            <Row >

                                                <Col md={12} xs={12} lg={6} className="my-auto">
                                                    <Form.Group >
                                                        <h4 className={'defaultTitle'}>Número de Celular</h4>
                                                        <Form.Control
                                                            placeholder='Celular'
                                                            arial-label="Amount (to the nearest dollar)"
                                                            value={this.state.phone}
                                                            maxLength={10}
                                                            onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group >
                                                        <h4 className={'defaultTitle'}>Número de Cédula</h4>
                                                        <Form.Control
                                                            placeholder='Cédula'
                                                            arial-label="Amount (to the nearest dollar)"
                                                            value={this.state.idClient}
                                                            maxLength={10}
                                                            onChange={(event) => this.setState({ idClient: event.target.value.replace(/\D/, '') })}
                                                        />
                                                    </Form.Group>

                                                    <div className="justify-content-md-center col-xs-12 mb-2"  >
                                                        <ListPrices clean={(flag) => this.setState({ clean: flag })} limpiar={this.state.clean} onClick={(i) => this.selectPrice(i)} prices={Prices} /><br />
                                                        {this.state.visible &&

                                                            <NumberFormat className="form-control mt-1"
                                                                thousandSeparator={true}
                                                                prefix='$ '
                                                                placeholder='$ Montos de Mil'
                                                                value={this.state.selectedPrice}
                                                                maxLength={9}
                                                                onChange={(event) => this.setState({ selectedPrice: parseInt(event.target.value.replace("$", "").replace(",", "").replace(",", "")) })}
                                                            />
                                                        }
                                                    </div>

                                                </Col>

                                                <Col md={12} xs={12} lg={6}>

                                                    <h4 className={'defaultTitle'}>Resumen de Compra</h4>
                                                    <Card>
                                                        <Summary
                                                            operator={this.state.selectedOperator.name}
                                                            price={this.state.selectedPrice}
                                                            phone={this.state.phone}
                                                            idClient={this.state.idClient}
                                                        />
                                                    </Card>
                                                </Col>

                                            </Row>
                                        </Container>
                                    </Card.Body>

                                    {!this.state.loading && <Card.Footer className="text-muted">
                                        <Button
                                            size="lg"
                                            disabled={!this.state.phone ||
                                                !this.state.selectedOperator.name ||
                                                !this.state.selectedPrice ||
                                                !this.state.idClient}
                                            variant={'info'}
                                            onClick={this.sendData}
                                        >
                                            Finalizar
                                        </Button>
                                    </Card.Footer>}
                                </Card>
                            </div>}


                        </div>

                    </div> :

                    <div className="container" style={{ marginTop: '5%', height: 633, width: '40%' }}>
                        <p>Esta pagina no esta disponible.</p>
                        <Button
                            style={{ backgroundColor: '#019CA1', border: 'none', color: 'white', fontSize: 25, borderWidth: 2, borderColor: '#07A2BA' }}
                            onClick={() => window.open(Url_home, '_self')}>  Volver al inicio</Button>

                        <Col md={8} xs={8} sm={8} lg={8}>
                            <Image
                                style={{ marginTop: '30%', marginLeft: '20%', marginBottom: '10%' }}
                                src={woman} />
                        </Col>
                    </div>


                }
                {this.state.pay && <PayScreen
                    visible={this.state.pay}
                    closemodal={(flag) => this.setState({ pay: flag })}
                    product={this.state.product}
                    localProduct={this.state.localProduct} />}

                {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.closeModal(flag)} type={"Pin"} message={"Recarga de " + this.state.selectedOperator.name + " realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}



const Prices = [
    1000,
    2000,
    3000,
    5000,
    10000,
    20000,
    'Otro valor'
]

export default RecargasDeportivas;