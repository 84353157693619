import React, { Component } from 'react';
import { CarruselRifas } from './components/carousel-rifas';
import Header from '../../shared/header/header';
import HeaderCardoso from '../../shared/header/header-cardoso';
export default class RifasComponent extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            visible: false,
            deslogear:false,
            selectedRifa: {
                id: -1,
                image_medium: null,
                name: '',
                precio: '',
                fecha_sorteo: ''
            },
            idHeader: localStorage.getItem('idDelPadre')
        }
    }   
    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                `}
                </style>
                <div style={{position:'relative',index:1,display:'inlineBlock',marginTop:20}}>
                {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>
                <h1 style={{color:'#0D606E',marginLeft:'45%',marginTop:'1%'}} className={'defaultTitle'}>Rifas</h1>

                <div style={{marginBottom:5 ,padding: '30px 2% 0px 2%',height:550 }}>
                    <CarruselRifas />
                </div>
            </>

        )
    }
}
