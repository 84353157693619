
export const OdooConfig = async () => {


        const config = {
                //pruebas normal
                 /* host: 'https://pruebasmovilgo.movilgo.com.co',               
                port:'',
                database:'pruebasmovilgo',
                encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni' */   
                
                //pruebas (prueba_nueva base de datos)
                // host: 'https://pruebasmovilgo.movilgo.com.co',               
                // port:'',
                // database:'movilgo_copia',
                // encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni' 
                
                //produccion movilgo
                 /* host: 'https://movilgoadmin.movilgo.com.co',               
                 port:'',
                 database:'movilgo',
                 encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni' */

                 //produccion republica dominicana
                 host: 'https://movilgoadmin.movilgo.com.co',               
                 port:'',
                 database:'republicadominicana',
                 encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni'
                
                //ads
                /* host: '192.168.1.19',               
                port:'8069',
                database:'movilgosss',
                encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni' */

        
                /*  host: 'movilgopruebas.movilgo.com.co',
                host: 'http://190.249.179.10',
                port:'8070',
                database:'movilgoprod',
                encryptKey: 'zEA2tiTWsWABVz7El8OBxfLN7K5X1Qni' */
        }

        return config;
}
//pruebas
/* export const idClienteFinal='376'
export const clientSession = { user:'clientefinal', password:'1111' } 
export const idClienteFinalCardoso='619'
export const clientSessionClienteFinal = { user:'clientefinalcardoso', password:'1111' } 
export const Url_vendedor='http://45.79.43.96:8099/' //https://vendedorpruebas.movilgo.com.co/
export const Url_home='http://45.79.43.96:8098/' */

//produccion
/* export const idClienteFinal=376
export const clientSession = { user:'clientefinal', password:'1111' }  
export const idClienteFinalCardoso='619'
export const clientSessionClienteFinal = { user:'clientefinalcardoso', password:'1111' }
export const Url_vendedor='https://vendedor.movilgo.com.co/'
export const Url_home='https://movilgo.com.co/' */

//produccion Republica dominicana
export const idClienteFinal=376
export const clientSession = { user:'clientefinal', password:'1111' }  
export const idClienteFinalCardoso='619'
export const clientSessionClienteFinal = { user:'clientefinalrepublica', password:'1111' }
export const Url_vendedor='https://rdvendedor.movilgo.com.co/'
export const Url_home='https://rdcliente.movilgo.com.co/'

export const version = '1.3'
