import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';
export const SoatService = async (data, callback) => {
    const config = await OdooConfig();
    const args = [[
        0, data
    ]]

    const user = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "soatSolicitarDatos",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });

    client.connect((err, response) => {
        if (err) {
            return callback(response, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }
    })

}

export const searchSoat = async ( documento, placa,product_id, fecha, fechaVencimiento, callback) => {
    const config = await OdooConfig();
    const args = [[
        0, { product_id, documento, placa, fecha, fechaVencimiento}
    ]]
    const user = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "soatSolicitarDatos",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });

    client.connect((err, response) => {
        
        if (err) {
            
            return callback(response, false)
        } else {     
               
            client.execute_kw(params.model, params.method, args, (err2, response2) => { 
                
                               
                if (err2) {
                    
                    callback(err2, false);
                    return false;
                }
                callback(response2, true);
                
            });
        } 
        
        
        
    })
    
}

export const searchPolisaSoat = async (source_request, Numberplate, InsurancePolicyNumber,product_id ,email, callback) => {
    const config = await OdooConfig();
    const args = [[0, {source_request, Numberplate, InsurancePolicyNumber,product_id ,email}]]
    
    const user = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "soatDatosReimpresion",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: user.user,
        password: user.password
    });
    
    client.connect((err, response) => {
        
        if (err) {
            
            return callback(err, false)
        } else {     
               
            client.execute_kw(params.model, params.method, args, (err2, response2) => { 
               if (err2) {
                    
                    
                    return callback(err2, false);
                }
                return callback(response2, true);
                
            });
        } 
        
        
        
    })
    
}