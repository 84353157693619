import React, { useState, useEffect,useRef,Fragment} from 'react';
import { LoadingMovilGo } from '../../loadingMovilGo';
import Select from "react-select";
import { Button, Modal,Form ,Row,Col} from "react-bootstrap";
import { getCities, } from '../../../services/pay.service';
import {capitalize, map} from 'lodash';
import card from '../../../assets/pagos/tarjeta_movil.png'
import { newLogin } from '../../../services/login.service';
import { payMenCARD } from '../../../services/pay.service';
import { ModalNotificationTransaction } from '../../modalNotificationTransaction';
import {  toast } from 'react-toastify';
import { ManagmentErrors } from '../../managmentErrors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import card1 from '../../../assets/pagos/card_movil.css';
export const PayCardScreen =(props)=>{
    const {visible,product,userLogin,closeModal} = props;

    const mapRef = []
    const fechaRef=[]
    const [digitosLectura, setDigitosLectura] = useState([]);
    const [fecha, setFecha] = useState([]);
    const [cardName,setCardName]=useState('')
    const [csv,setCSV]=useState('')
    const [documentCard,setDocumentCard]=useState('')
    const mensaje ="Este campo es obligatorio"
    const [modalNotification,setModalNotification]=useState(false)
    const [cardError,setCardError]=useState(false)
    const [departments,setDepartments]=useState([])
    const [allCities,setAllCities]=useState([])
    const [cities,setCities]=useState([])
    const [loading,setLoading]=useState(false)
    const [show,setShow]=useState(false)
    const [selectedDepartment,setSelectedDepartment]=useState({value:-1,label:'Selecion un Departamento'})
    const [selectedCity,setselectedCity]=useState({value:-1,label:'Selecion una Ciudad'})
    const [response,setResponse]=useState({})
    const [date,setDate]=useState();
    const [ip,setIp]=useState()
    const documentOptions=[
        {value:'CC',label:'Cedula'},
            {value:'CE',label:'Cedula Extranjera'},
            {value:'NIT',label:'Número de Identificación Tributaria'},
            {value:'TI',label:'Tarjeta de Identidad'},
            {value:'PP',label:'Pasaporte'},
            {value:'IDC',label:'Identificador único De Cliente'},
            {value:'CEL',label:'Línea Móvil'},
            {value:'RC',label:'Registro Civil'},
            {value:'DE',label:'Documento de Identificación Extranjero'}
    ]
    const [data,setData]=useState({fullName:'',
        email:'',
        contactPhone:'',
        street1:'',
        street2:'',
        documentType:{value:-1,label:'Seleccione un Documento'},
        dniNumber:'',
        postalCode:'',
        personTypeSelected:'',
        selectedDepartment:{value:-1,label:'Selecion un Departamento'},
        selectedCity:{value:-1,label:'Selecion una Ciudad'}
    })
    const [error,setError]=useState({
        errorFullName:false,
        errorEmail:false,
        errorContactPhone:false,
        errorStreet1:false,
        errorStreet2:false,        
        errorDniNumber:false,
        errorPostalCode:false,

    })
    for (let index = 0; index < 16; index++) {
        mapRef.push(useRef())
      }   
      
    for (let index = 0; index < 4; index++) {
        fechaRef.push(useRef())
      }
    useEffect(()=>{
        
        loadData() 
     },[])

    const loadData=async()=>{
        
        //console.log("productis del  card",product)
        getCities(userLogin,async(flag,res)=>{
            ////console.log("flag del getcities",flag)
            ////console.log("res del getcities",res)
            let cityOptions=[]
            let departmentsOptions=[]

            if(flag){
                res.departamentos.forEach((element)=>{
                    departmentsOptions.push({value:element.stateId,label:element.nombre})
                    departmentsOptions.sort((a, b) => {
                        return a.label < b.label ? -1 : 1;
                      });
                })
                res.cuidades.forEach((element)=>{
                    cityOptions.push({label: element.cityName,value: element.cityId,stateId: element.stateId,})
                    cityOptions.sort((a, b) => {
                        return a.label < b.label ? -1 : 1;
                      });
                })
                setDepartments(departmentsOptions)
                setAllCities(cityOptions)                
                setLoading(false)
                getDate()
                createDigitos()

            }else{
                setLoading(false)
                if(res.message==='No UID returned from authentication.'){
                    newLogin((flag)=>{
                        
                        if(flag){
                            loadData()
                        }
                    })
                }else{
                    alert("Error", "No se pudo realizar la consulta");
                    closeModal(false)
                }
                //alert("Error", "No se pudo realizar la consulta");
                
            }
        })
        
     }
    const createDigitos=()=>{
        let digitos = [];
        let fecha=[]
        for (let index = 0; index < 16; index++) {
          if((index+1)%4===0){            
            digitos.push({
                style:{...styles.input,marginRight:8,
                }
            }) 
          }else{            
            digitos.push({
                style:{...styles.input,marginRight:2
                }
            })
          }
        }
        for (let index = 0; index <4; index++) {

            if(index===1){            
                fecha.push({
                  style:{...styles.fecha,marginRight:8,
                  }
              }) 
            }else{            
                fecha.push({
                  style:{...styles.fecha,marginRight:2
                  }
              })
            }
          }
        setFecha(fecha) 
        setDigitosLectura(digitos);
        setShow(true)
    } 
   

    const getDate=()=>{
            const d = new Date();
                let month=(d.getMonth()+1)
                let day=d.getDate()
                let horas=d.getHours()
                let min=d.getMinutes()
                let seg=d.getSeconds()
               
                let fecha= d.getFullYear()+"-"+month+"-"+day
                let hora =horas + ':' + min + ':' + seg;
                let date = fecha+" "+hora
                setDate(date) 
            
    }
    const getCardType =(number)=>{
                // visa
                var re = new RegExp("^4");
                if (number.match(re) != null)
                    return "Visa";
            
                // Mastercard 
                // Updated for Mastercard 2017 BINs expansion
                 if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) 
                    return "Mastercard";
            
                // AMEX
                re = new RegExp("^3[47]");
                if (number.match(re) != null)
                    return "AMEX";
            
                // Discover
                re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
                if (number.match(re) != null)
                    return "Discover";
            
                // Diners
                re = new RegExp("^36");
                if (number.match(re) != null)
                    return "Diners";
            
                // Diners - Carte Blanche
                re = new RegExp("^30[0-5]");
                if (number.match(re) != null)
                    return "Diners - Carte Blanche";
            
                // JCB
                re = new RegExp("^35(2[89]|[3-8][0-9])");
                if (number.match(re) != null)
                    return "JCB";
            
                // Visa Electron
                re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
                if (number.match(re) != null)
                    return "Visa Electron";
            
                return "";
            
    }
    const sendToOdoo=()=>{
            //console.log("selectedDepartment",date)
            if(verifyField(true,true,true,true,true,true,true,true,true,true,true)){ 
                setLoading(true)
                let numbercard= digitosLectura.map(data=>data.value== undefined ? "":data.value)
                let fechacard=fecha.map(data=>data.value)
                let digitos = numbercard.toString().replace(/,/g, '');
                let fechadigitos = fechacard.toString().replace(/,/g, '');
                let typecard=getCardType(digitos)
                ////console.log("tipo de tarjeta",typecard)
                const payproduct={   
                    "tx_value": product.atributes.precio ? product.atributes.precio:product.atributes.valorPagar,
                    "currency": "COP",
                    "fullName": data.fullName,
                    "emailAddress": data.email,
                    "contactPhone": data.contactPhone,
                    "dniNumber": data.dniNumber,
                    "street1": data.street1,
                    "street2": data.street2,
                    "city": data.selectedCity.label,
                    "state":  data.selectedDepartment.label,
                    "country": "CO",//por ahora se quema cuando sean pagos desde otros paises se tiene que selecionar 
                    "postalCode": data.postalCode,
                    "phone": data.contactPhone,
                    "number": digitos, 
                    "cedulatarjeta":documentCard,
                    "securityCode": csv,
                    "expirationDate":"20"+fechadigitos.substr(2,3)+"/"+fechadigitos.substr(0,2) ,
                    "name": cardName.toUpperCase(),
                    "paymentMethod": typecard.toUpperCase(),//calcular que tipo de tarjeta es 
                    "paymentCountry": "CO", 
                    "ipAddress":'', 
                    "userAgent": "Movilgo APP " +date,
                    "INSTALLMENTS_NUMBER": 1,
                    "product":product 
                    }
                    //console.log("product del card",payproduct)
                   payMenCARD(payproduct,userLogin/* {user:'8001blanca',password:'8001'} */, async(flag,res)=>{
                    setLoading(false)
                    let response=JSON.parse(res)
                        if(flag){
                            if(response[0]){
                                if(response[0].errores){
                                    if(response[0].errores.error){
                                        alert(response[0].errores)
                                    }else{
                                      alert(response[0].errores)  
                                    }
                                    
                                }else if(response[0].error){
                                    alert("Se presento un error al realizar la compra")
                                }

                            }else if(response.errores){
                                if(response.errores.observacion){
                                    alert(response.errores.observacion)
                                }else if(response.errores.error){
                                    alert(response.errores.error)
                                }
                                
                                

                            }else if(response.error){
                                alert(response.error)
                            }else{
                                //console.log("ingreso al else ")
                                setModalNotification(true)
                                setResponse(response)
                                const success = () => toast.success("Recarga comprada exitosamente");
                                success();
                            }
                            
                            //const isOk =await pdfPay(localProduct,res)
                        }else{
                            /* const message = ManagmentErrors(res.errores.id);
                            const error = () => toast.error(message);
                            error(); */
                            alert("se presento un error al realizar la transaccion, vuelva a intentarlo")
                        }
            
                    })  
            }
    
    }
    const verifyField=(fullName,email,phone,street,street2,dni,postalCode,documentType,deparment,city,card)=>{
            let numbercard= digitosLectura.map(data=>data.value== undefined ? "":data.value)
            let fechacard=fecha.map(data=>data.value)
        
            if(fullName){
                
                if(data.fullName===""){           
                    setError({...error,errorFullName:true})
                    return false
                }else{            
                    setError({...error,errorFullName:''})
                    
                }
            }
        
            if(email){
                if(!data.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                    setError({...error,errorEmail:'Formato del correo incorrecto'})
                    return false
                }else{
                    setError({...error,errorEmail:false})
                    
                }
            }
            if(phone){
                if(data.contactPhone===""){
                    setError({...error,errorContactPhone:true})
                    return false
                }else if(data.contactPhone.length<7){
                    setError({...error,errorContactPhone:'El numero es demasiado corto'})
                    return false
                }else {
                    setError({...error,errorContactPhone:false})
                    
                }
            }
            if(street){
                if(data.street1===""){
                    setError({...error,errorStreet1:true})
                    return false
                }else{
                    setError({...error,errorStreet1:false})
                    
                }
            }
            if(dni){
                if(data.dniNumber===""){
                    setError({...error,errorDniNumber:true})
                    return false
                }else{
                    setError({...error,errorDniNumber:false})
                    
                }
            }
            if(postalCode){
                if(data.postalCode==""){
                    setError({...error,errorPostalCode:true})
                    return false
                }else{
                    setError({...error,errorPostalCode:false})
                    
                }
            }
            if(documentType){
                if(data.documentType.value==="-1"){            
                    setError({...error,errorDocumentType:true})
                    return false
                }else{
                    setError({...error,errorDocumentType:false})
                    
                }
            }
            
            if(deparment){
                if(data.selectedDepartment.value==-1){            
                    setError({...error,errorDeparment:true})
                    return false
                }else{
                    setError({...error,errorDeparment:false})
                    
                }
            }
            if(city){
                if(data.selectedCity.value===-1){
                    setError({...error,errorCity:true})
                    return false
                }else{
                    setError({...error,errorCity:false})
                    
                }
            }
        
            if(street2){
                if(data.street2===""){
                    setError({...error,errorStreet2:true})
                    return false
                }else{
                    setError({...error,errorStreet2:false})
                    
                }
            }
            if(card){
                if(numbercard.includes(undefined)||fechacard.includes(undefined)||cardName===''||csv==''||documentCard==''){
                    setCardError(true)
                    return false
                }else{
                    setCardError(false)
                }
    
            }
        
            return true
        
    }
    
    const departmentSelect=(department)=>{
            ////console.log("department",data)
            setCities([])
            let cityOptions=[]
            allCities.forEach((element)=>{            
                if(parseInt(department.value)===parseInt(element.stateId)){
                    //////console.log(element)
                    cityOptions.push({label: element.label,value: element.value,stateId: element.stateId,})
                    cityOptions.sort((a, b) => {
                        return a.label < b.label ? -1 : 1;
                        });
                }
            })
           
            setCities(cityOptions)
    
    }
    
        return(
            <Modal
                style={{ opacity: 1 }}
                size='md'
                show={visible}/* props.visible */
            >
                <Modal.Header className='header'  >
                    <Modal.Title className={'defaultTitle'} style={{ color: 'white' }}>Pago con Tarjeta </Modal.Title>
                    <Button onClick={() => {closeModal(false,false)}} type="button" style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none' }}>
                        <FontAwesomeIcon color={'white'} className="mr-2 align-self-center" icon={faTimes} />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                <Col md={12} xs={12} sm={12} lg={12} >
                    <div className='altura'>
                        {show&&<div>
                            <div className='tarjeta' >
                            
                                <div className='separar_campos' >
                                <div className='digitos_tarjeta' >
                                <div style={{ backgroundImage: `url({card})` }}></div>
                                    {map(digitosLectura, (item, index) => (                                        
                                            <input className='
                                            numero_tarjeta'
                                                key={index}                                                
                                                inputmode='tel'
                                                maxLength={1}
                                                style={item.style}
                                                value={item.value}
                                                onChange={text => {                                            
                                                    let digitos2 = [...digitosLectura];
                                                    digitos2[index].value = text.target.value.replace(/\D/, '')
                                                    setDigitosLectura(digitos2);
                                                    index < digitosLectura.length - 1 &&
                                                    text.target.value.length > 0 &&
                                                    mapRef[index + 1].current.focus();
                                                    text.length === 0 &&
                                                    index > 0 &&
                                                    mapRef[index].current.focus();
                                                
                                                }}
                                                onKeyPress={({nativeEvent}) => {
                                                nativeEvent.key === 'Backspace' &&
                                                    index > 0 &&
                                                    mapRef[index - 1].current.focus();
                                                }}
                                                ref={mapRef[index]}
                                            />
                                                    
                                    ))}

                                </div> 
                                </div>
                                <Row  style={{marginTop:10,marginLeft:10}}>
                                    <Col  md={5} xs={5} sm={5} lg={5}>
                                        <Form.Control className='documento'
                                            placeholder="Documento" 
                                            onBlur={() => verifyField(true)} 
                                            value={documentCard} 
                                            style={{backgroundColor:'transparent'}}
                                            onChange={(event) => setDocumentCard(event.target.value.replace(/\D/, ''))}
                                            type='numeric' 
                                            />
                                    </Col>
                                    <Col  md={7} xs={7} sm={7} lg={7}>
                                        <div className='valido'> 
                                            <h6 className='titulo' >Valido hasta </h6> 
                                        </div>
                                        <Row >
                                            <div className='mm_aa' >
                                            {map(fecha, (item, index) => (                                        
                                                    <input 
                                                        key={index}
                                                        maxLength={1}
                                                        style={item.style}
                                                        value={item.value}
                                                        onChange={text => {                                            
                                                            let digitos3 = [...fecha];
                                                            digitos3[index].value = text.target.value.replace(/\D/, '')
                                                            setFecha(digitos3);
                                                            index < fecha.length - 1 &&
                                                            text.target.value.length > 0 &&
                                                            fechaRef[index + 1].current.focus();
                                                            text.length === 0 &&
                                                            index > 0 &&
                                                            fechaRef[index].current.focus();
                                                        
                                                        }}
                                                        onKeyPress={({nativeEvent}) => {
                                                        nativeEvent.key === 'Backspace' &&
                                                            index > 0 &&
                                                            fechaRef[index - 1].current.focus();
                                                        }}
                                                        ref={fechaRef[index]}
                                                    />
                                                            
                                            ))}

                                            </div>  
                                        </Row>
                                            <p className='letra' style={{marginLeft:'50%',color:'white',width:'60%',justifyContent:'space-between',height:'20%',position:'relative'}}>MM   /   AA</p>
                                        
                                    </Col>
                                        
                                           
                                </Row>
                                {/* <div style={{marginTop:10,marginLeft:210,flexDirection:'row'}}>
                                           
                                    
                                    
                                    
                                </div> */}
                                <br></br>
                                <Row  style={{marginLeft:20,marginTop:'-10%'}}>
                                    <Col  md={6} xs={6} sm={6} lg={6}>
                                        <Form.Control className='nombre'
                                            placeholder="Nombre " 
                                            
                                            value={cardName} 
                                            style={{backgroundColor:'transparent'}}
                                            onChange={(event) => setCardName(event.target.value)} 
                                            type="text" 
                                        /> 
                                    </Col>
                                    <Col  md={4} xs={4} sm={4} lg={4}>
                                        <Form.Control  className='csv'
                                            placeholder="CSV"
                                            value={csv} 
                                            style={{backgroundColor:'transparent'}}
                                            onChange={(event) => setCSV(event.target.value.replace(/\D/, ''))} 
                                        />
                                    </Col>   
                                    
                                </Row>
                            </div>
                            {cardError && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}
                            <Form style={{ width: '100%', height: '100%' }}>
                                <div style={{ opacity: 1,marginBottom:'10%' }}>
                                    <Form.Group className={'defaultTitle'}>
                                        <Form.Control 
                                            placeholder="Nombre Completo" 
                                            onBlur={() => verifyField(true)} 
                                            value={data.fullName} 
                                            onChange={(event) => setData({...data,fullName:event.target.value})} 
                                            type="text" 
                                            />
                                        <br/>    
                                        {error.errorFullName && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}
                                                                            
                                        <Form.Control 
                                            placeholder="Email"
                                            onBlur={() => verifyField(false,true)} 
                                            value={data.email} 
                                            onChange={(event) => setData({...data,email:event.target.value})} 
                                            type="email"
                                            />
                                        <br/>    
                                        {error.errorEmail && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}
                                        
                                        <Form.Control 
                                            placeholder="Celular"
                                            onBlur={() => verifyField(false,false,true)} 
                                            value={data.contactPhone} 
                                            onChange={(event) => setData({...data,contactPhone:event.target.value.replace(/\D/, '') })} 
                                            arial-label="Amount (to the nearest dollar)"
                                            maxLength={10} 
                                            />
                                        <br/>
                                        {error.errorContactPhone && <p style={{ fontSize: 12, color: 'red' }}>  {mensaje}<br></br></p>}
                                        
                                        <Form.Control 
                                            placeholder="Direccion"
                                            onBlur={() => verifyField(false,false,false,true)} 
                                            value={data.street1} 
                                            onChange={(event) => setData({...data,street1:event.target.value})} 
                                            type="text" 
                                            />
                                        <br/>
                                        {error.errorStreet1 && <p style={{ fontSize: 12, color: 'red' }}> {mensaje}<br></br></p>}
                                        <Form.Control 
                                            placeholder="Otra Direccion"
                                            onBlur={() => verifyField(false,false,false,false,true)} 
                                            value={data.street2} 
                                            onChange={(event) => setData({...data,street2:event.target.value})} 
                                            type="text" 
                                            />
                                        <br/>
                                        {error.errorStreet2 && <p style={{ fontSize: 12, color: 'red' }}> {mensaje} <br></br></p>}
                                        <Fragment>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"                                    
                                                value={data.documentType}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="color"
                                                options={documentOptions}
                                                onChange={(documentType) => { setData({...data,documentType})}}
                                            />
                                        </Fragment>
                                        <br/>

                                        {error.errorDocumentType && <p style={{ fontSize: 12, color: 'red' }}> {mensaje} <br></br></p>}
                                        
                                        <Form.Control 
                                            placeholder="Numero De Documento"
                                            arial-label="Amount (to the nearest dollar)"
                                            onBlur={() => verifyField(false,false,false,false,false,true,false,false,false,false)} 
                                            value={data.dniNumber} 
                                            onChange={(event) => setData({...data,dniNumber:event.target.value.replace(/\D/, '') })} 
                                            maxLength={10} 
                                            />
                                        <br/>    
                                        {error.errorDniNumber && <p style={{ fontSize: 12, color: 'red' }}> {mensaje} <br></br></p>}
                                        <Form.Control 
                                            placeholder="Codigo Postal"
                                            arial-label="Amount (to the nearest dollar)"
                                            onBlur={() => verifyField(false,false,false,false,false,false,true,false,false,false)} 
                                            value={data.postalCode} 
                                            maxLength={6}
                                            onChange={(event) => setData({...data,postalCode:event.target.value.replace(/\D/, '') })} 
                                            
                                            />
                                        <br/>    
                                        {error.errorPostalCode && <p style={{ fontSize: 12, color: 'red' }}>{mensaje} <br></br></p>}
                                        
                                        
                                        <Fragment>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"                                    
                                                value={data.selectedDepartment}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="color"
                                                options={departments}
                                                onChange={(selectedDepartment) => { setData({...data,selectedDepartment});
                                                departmentSelect(selectedDepartment)
                                                                                    }}
                                            />
                                        </Fragment>
                                        <br/>
                                        {error.errorDeparment&& <p style={{ fontSize: 12, color: 'red' }}>{mensaje}<br></br></p>}
                                        <Fragment>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"                                    
                                                value={data.selectedCity}
                                                isDisabled={false}
                                                isLoading={false}
                                                isClearable={true}
                                                isRtl={false}
                                                isSearchable={true}
                                                name="color"
                                                options={cities}
                                                onChange={(selectedCity) => { setData({...data,selectedCity});}}
                                            />
                                        </Fragment>
                                        <br/>
                                        {error.errorCity&& <p style={{ fontSize: 12, color: 'red', marginBottom:'10%' }}>{mensaje}<br></br></p>}
                                        
                                        </Form.Group>
                                </div> 
                            </Form>  
                        </div>}

                    </div>
                </Col>
                    
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {closeModal(false)}}>
                        Cancelar
                        </Button>
                    <Button
                        variant="info"
                        onClick={() => sendToOdoo()}
                        /* disabled={!identificacion || !nombre || !apellido || !direccion || !celular || (!correo.test(email) ? email : "")} */
                    >
                        Comprar
                    </Button>
                </Modal.Footer>
                <div style={{marginLeft:130}}>
                {modalNotification && <ModalNotificationTransaction 
                                                closeModal={(flag,dataResponse) => {closeModal(flag,dataResponse)
                                                    setModalNotification(flag)}} 
                                                reponse={response}    
                                                message={"Su Transaccion fue exitosa"} />}
                 
                  {loading && <LoadingMovilGo />}  
                </div>                                                            
                
             </Modal>
            
        )
    
}
const styles = {
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        alignSelf: 'center',
        marginVertical: 10,
        color: 'white'
    },
    input:{        
        backgroundColor:'transparent',        
        width:20,        
        borderBottomColor:'white',
        

        
    },fecha:{        
        color:'white',
        backgroundColor:'transparent',  
        fontSize:15,
        height:'95%',
        borderBottomWidth:1,
        width:30,       
        borderBottomColor:'white',
        borderBottomWidth:1,
        
    },
    input2:{
        marginLeft:5,
        borderBottomColor:'white',
        borderBottomWidth:1,
        width:17
    }, 
    searchPiker: {
        width:'95%',
        alignSelf:'center',
        marginTop:8
    }
}