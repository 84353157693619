
import React, {Component} from 'react';
import {Image, Row, Col} from 'react-bootstrap';
import fondo from '../../assets/home/fondo.png';
import woman from '../../assets/home/img4.png'
import Header from '../../shared/header/header';

import fondo2 from '../../assets/home/fotos-05.jpg'
import { idClienteFinal, idClienteFinalCardoso } from '../../utilities/odoo-config';
import HeaderCardoso from '../../shared/header/header-cardoso';
  export default class HomeComponent extends Component{
    constructor(props) {
        super(props)
        this.state={
                showRegister:false, 
                deslogear:false,
                idHeader: localStorage.getItem('idDelPadre')
        }
      }
      componentDidMount=()=>{
        
        //console.log("componentDidMount props",this.props)
      }
      render(){
        return(<>
                <div>
                    <div style={{height:1000}}>
                        <div style={{position:'absolute',index:1,display:'inlineBlock',marginTop:20}}>
                            {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />}
                        </div>{/* 
                        <div className='row' style={{backgroundImage: `url(${fondo2})`,width:'100%',height:900}}>
                            <div className='container' style={{height:'25%',position:'relative',marginTop:'5%'}}>
                                <CarruselHomeNuevo/>
                                
                            </div>
                        </div> */}
                        <Row>
                            <Col md={4} xs={4} sm={4} lg={4} >
                                <div style={{marginTop:'50%',marginLeft:'20%',}}>
                                    <p style={{textAlign:'left', color:'#0D606E',borderBottomStyle:'solid',borderBottomWidth:2,borderBottomColor:'#0D606E', fontSize: 70, left:80}}>
                                        ¿Que quieres hacer hoy?
                                    </p>
                                    <div>
                                        <p style={{textAlign:'left', color:'#0D606E', fontSize: 40, left:20, marginTop:'10%' }}>                                        
                                            Nuestra App
                                        </p>
                                        <p style={{textAlign:'left', color:'#0D606E', fontSize: 20, left:20 }}>                                        
                                            Contamos con una aplicacion móvil para facilitarte el trabajo a la hora de ralizar ventas,rifas,consultas y mucho más ...
                                        </p>    
                                    </div>
                                                                        
                                </div>
                                
                            </Col>
                            <Col md={8} xs={8} sm={8} lg={8}>                              
                                <Image                             
                                    style={{marginTop:'30%' ,marginLeft:'20%',marginBottom:'10%'}} 
                                    src={woman}            
                                /> 
                            </Col>
                        </Row>
                        

                    </div>
                </div>
                    
           </>)}
    }

