import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Container, Col } from 'react-bootstrap';
import { FormatMoney } from '../../../utilities/formats';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { HomeWrapper } from '../../../shared/style'
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
export const CarruselRifas = () => {
    
    const [cards, setCards] = useState([])
   
    const navigate = useNavigate();
    const capturarRifa = async (ruta,id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion) => {
        
        localStorage.setItem('datosRifa', JSON.stringify({ id, name, sorteo, precio, imagen, porcentaje_recaudo, resolucion }));
        navigate(ruta)
    }
    const size = useWindowSize();
    const [numberCards, setNumberCards] = useState(2);
    const [active,setActive]=useState(false)
    function useWindowSize() {

        const [windowSize, setWindowSize] = useState({ width: undefined, height: undefined, });

        useEffect(() => {
           
            function handleResize() {
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            window.addEventListener("resize", handleResize);
            handleResize();

            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowSize;
    }

    /* useEffect(() => {
        
        if (size.width <= 768 && size.height <= 1024) {
            setNumberCards(1)
        }
        else if (size.width >= 768 && size.height >= 800) {
            setNumberCards(2)
        }

    }, [size]); */


    let settings = {
        arrows: true,
        dots: false,
        accesibility: true,
        infinite: false,
        speed: 300,
        slidesToShow: numberCards,
        slidesToScroll: 1,
        adaptiveHeight: false
    };
   
    useEffect(() => {
        loadData()
    }, []);

     const loadData=async()=>{
            const product = await JSON.parse(localStorage.getItem("products"));
            if(product){

                let aux = [];
                product.rifas.forEach(element => {   
                    //console.log("los elementos jojoo",element)
                    if(element.numero_resolucion_rifa!=false){
                        aux.push(
                            <div key={element.id} className="col-xs-12" style={{ padding: '5' }}>
                                <Card className="text-center" border="info" style={{ margin: 'auto', minHeight: '29rem' }}>
                                    <Card.Header>{element.name}</Card.Header>
                                    <Card.Body>
                                        <Card.Img src={'data:image/jpeg;base64,' + element.image_medium} style={{ maxHeight: '335px', maxWidth: '350' }} alt="Card image" />
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        <Row>
                                            <Col>
                                                {element.porcentaje_recaudo < 100 && 
                                                <Button variant="info" style={{ color: 'white' }} onClick={() => capturarRifa('/Rifas/Abonos',element.id, element.name, element.fecha_sorteo, element.precio, element.image_medium,  element.porcentaje_recaudo,element.numero_resolucion_rifa)}>
                                                    Abonar
                                                </Button>}
                                            </Col>
                                            <Col>
                                                <p>
                                                    {element.fecha_sorteo}
                                                    <br />
                                                    {FormatMoney(element.precio)}
                                                </p>
                                            </Col>
                                            <Col>
                                                <Button variant="info" style={{ color: 'white' }} onClick={() => capturarRifa('/Rifas/Compras',element.id, element.name, element.fecha_sorteo, element.precio, element.image_medium, element.porcentaje_recaudo, element.numero_resolucion_rifa)}>
                                                    Comprar
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </div>
                        );
                    }        
                    
                })
                setCards(aux)
                setActive(true)
            }else{
                const error = () => toast.error("Este producto no esta disponible");
                error();
            }
            
    }
    return (
        
        <Container>
            <ToastContainer />
            <HomeWrapper>
                <div className="clearfix mt-1 mb-2">
                </div>
                <Col>

                </Col>
                {active&&<div>
                    <Slider {...settings} >

                        {cards}
                    </Slider>
                </div>}
                
            </HomeWrapper>
        </Container>

    )
}