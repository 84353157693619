export const parseToString=(data)=>{
    let str = JSON.stringify(data).replace(/\\/g, '');
        str = str.replace('""""', '""');
        str = str.replace('"{', '{');
        str = str.replace('}"', '}');
        str = str.replace('""""', '""');
        str = str.replace('"{', '{');
        str = str.replace('}"', '}');
        const json = JSON.parse(str);
    return json    
}