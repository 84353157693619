import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { ItemMoves, ItemError,ItemPending } from './item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


export const ListMoves = (props) => {
    const { moves = [], products, rigthPage, leftPage, contPage} = props
  
    return (
        <>
            <div style={{ marginTop: 15 }}>
                <div className="row">
                    <div className="col">

                    </div>
                    <div className="col">
                        <h3>Movimientos</h3>
                    </div>
                    <div className="col" >

                        <div className='row' style={{ marginLeft: '40%' }}>
                            <div className="col" >
                                <label style={{ fontSize: 19 }}>Página: {contPage}</label>
                            </div>
                            <div className="col">

                                <Button variant="link" onClick={leftPage}>
                                    <FontAwesomeIcon color={'black'} icon={faChevronLeft} />
                                </Button>
                                <Button variant="link" onClick={rigthPage}>
                                    <FontAwesomeIcon color={'black'} icon={faChevronRight} />
                                </Button>

                            </div>
                        </div>

                    </div>
                </div>
                <Table striped bordered hover variant="info" >
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Descripción</th>
                            <th>Valor</th>
                            <th>Tipo</th>
                            <th>Aprobación</th>
                            {/* <th>Celular</th> */}
                           {/*  <th>Comisión</th> */}
                            <th>Fecha</th>
                            <th>Hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        {moves.map((obj, i) => (
                            <ItemMoves key={i} itemMoves={obj} products={products} />
                        ))}

                    </tbody>
                </Table>
            </div>
        </>
    )
}


export const ListErrors = (props) => {
    const { errors = [], rigthPage, leftPage, contPage } = props

    return (
        <>
            <div style={{ marginTop: 15 }}>
                <div className="row">
                    <div className="col">

                    </div>
                    <div className="col">
                        <h3>Errores</h3>
                    </div>
                    <div className="col" >
                        <div className='row' style={{ marginLeft: '40%' }}>
                            <div className="col" >
                                <label style={{ fontSize: 19 }}>Página: {contPage}</label>
                            </div>
                            <div className="col">

                                <Button variant="link" onClick={leftPage}>
                                    <FontAwesomeIcon color={'black'} icon={faChevronLeft} />
                                </Button>
                                <Button variant="link" onClick={rigthPage}>
                                    <FontAwesomeIcon color={'black'} icon={faChevronRight} />
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>

                <Table striped bordered hover variant="info">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Valor</th>
                            <th>Celular</th>
                            <th>Hora</th>
                            <th>Tipo</th>
                            <th>Observación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {errors.map((obj, i) => (
                            <ItemError key={i} itemError={obj} />
                        ))}

                    </tbody>
                </Table>
            </div>
        </>
    )
}

export const ListPending = (props) => {
    const { pending = [], rigthPage, leftPage, contPage } = props

    return (
        <>
            <div style={{ marginTop: 15 }}>
                <div className="row">
                    <div className="col">

                    </div>
                    <div className="col">
                        <h3>Movimientos Pendientes</h3>
                    </div>
                    
                </div>

                <Table striped bordered hover variant="info">
                    <thead>
                        <tr>
                            <th>Tipo</th>
                            <th>Fecha</th>
                            <th>Observacion</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pending.map((obj, i) => (
                            <ItemPending key={i} itemError={obj} />
                        ))}

                    </tbody>
                </Table>
            </div>
        </>
    )
}


