import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';
import { clientSession } from '../utilities/odoo-config';
export const searchClient = async (product_id, cedula, callback) => {
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[
        0, { product_id, cedula }
    ]]
    const params = {
        model: "movilgo.webservice",
        method: "buscarCliente",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    console.log("args de salida del buscarCliente",args)
    client.connect((err, response) => {
        if (err) {        
            return callback(response, false)
        } else {
            //console.log("jejejej")
            client.execute_kw(params.model, params.method, args, (err, response) => {
                //console.log("error del buscar cliente en rifas",err)
                //console.log("response del buscar cliente en rifas",response)
                if (err) {
                    callback(err, false);
                    return false;
                }
                callback(response, true);
            });
        }

    })
}

export const saveClient = async (usuario, callback) => {
    const args = [[
        0, usuario
    ]]
    const config = await OdooConfig();
    const data = await Desencrypt();
    const params = {
        model: "movilgo.webservice",
        method: "registrarCliente",
    }
    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    client.connect((err, response) => {
        if (err) {
            return callback(err, false)
        } else {
            client.execute_kw(params.model, params.method, args, (err, response) => {
                if (err) {
                    return callback(err, false);
                }else{
                   return callback(response, true); 
                }
                
            });
        }
    })
}

export const registNewUser = async (data,  callback) => {
   
    const config = await OdooConfig();
    //const session = { user: 'Clientefinal2', password: '1234' }
    const args = [ [0,[data]]]
    const params = {
            model: "movilgo.webservice",
            method: "registroCliente",
        }
    
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username:clientSession.user,
        password: clientSession.password
    });
    console.log("oddo del register",odoo)
    odoo.connect((err, response) => {
        console.log("err del conect registroCliente",err)
        console.log("response del conect registroCliente",response)
        if (err) {
            return callback(err, false)
        } else {
            odoo.execute_kw(params.model, params.method, args, (err2, response2) => {
                console.log("respons del register clietn",response2)
                console.log("err2 del register clietn",err2)
                if (err) {
                    return callback(err2,false);
                }
                return callback(response2, true);
            });
        }
    })    
}


export const getClient = async (callback) => {
    let finish = false;
    const config = await OdooConfig();
    const data = await Desencrypt();
    console.log("data por favor",data)
    const args = [ [0,{id:data.id}]]
    const params = {
            model: "movilgo.webservice",
            method: "obtenerDatosCliente",
        }
    setTimeout(() => {
            if (finish) {
                return;
            }
            alert("Tiempo limite excedidoo");
            
            return callback(false, "");
        }, 30000);   
    
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    console.log("args del perfil",args)
    odoo.connect((err, response) => {
        
        if (err) {
            callback(err, false)
        } else {
            //console.log("esto se envia para el getclient",params)
            return odoo.execute_kw(params.model, params.method, args, (err2, response2) => {
                finish = true
                if (err2) {
                     callback(err2,false);
                }
                callback(response2, true);
            });
        }
    })    
}

export const updateClient = async (id,obj,callback) => {
   
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [ [0,{id:id,informacion:obj}]]
    const params = {
            model: "movilgo.webservice",
            method: "actualizarInformacion",
        }
    
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });
    
   
    odoo.connect((err, response) => {
        if (err) {
            return callback(false)
        } else {
            odoo.execute_kw(params.model, params.method, args, (err2, response2) => {
                if (err2) {
                    return callback(false);
                }
                return callback(true);
            });
        }
    })    
}


export const updatePassword = async (id,password,callback) => {
   
    const config = await OdooConfig();
    const data = await Desencrypt();
    const args = [[0,{id:id,password: password}]]
    const params = {
            model: "movilgo.webservice",
            method: "cambiarContrasena",
        }
       
   
    const odoo = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    odoo.connect((err, response) => {
        if (err) {
            return callback(false)
        } else {
            odoo.execute_kw(params.model, params.method, args, (err, response) => {
                //console.log("cambiarContrasena error",err)
                //console.log("cambiarContrasena response",response)
                if (err) {
                    return callback(false);
                }else{
                    return callback(true);
                }
                
            });
        }
    })    
}
