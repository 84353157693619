import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { FormatMoney } from '../../../utilities/formats';
import { ItemBallotsUser } from './item';

export const ListPrices = (props) => {

    const { prices = [], onClick } = props;

    const [clicked, setClickedBtn] = useState(false);
    const [indexBtn, setIndexBtn] = useState(-1);
    const handleClick = (index) => {
        setClickedBtn(true);
        setIndexBtn(index);
    }
    return (
        <div>
            {prices.map((price, i) => {
                let btn_class;
                if (indexBtn !== -1) {
                    if (indexBtn === i) {
                        btn_class = clicked ? "#bedb02" : ""
                    }

                }
                return (
                    <Button variant='info' className='m-1' style={{ width: 100, backgroundColor: btn_class }} key={i} onClick={() => { onClick(price); handleClick(i) }}>{FormatMoney(price)}</Button>
                )
            })
            }
        </div>
    )

}

export const ListBallotsUser = (props) => {
    const { listaAbonos = [], setPayment, setVisible } = props
    return (
        <>
            <div style={{ marginTop: 15 }}>
                <Table striped bordered hover variant="info" responsive="sm">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Boleta</th>
                            <th>Cliente</th>
                            <th>Celular</th>
                            <th>Saldo</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaAbonos.map((obj, i) => (
                            <ItemBallotsUser key={i} itemBallotsUser={obj} setPayment={setPayment} setVisible={setVisible} />
                        ))}
                    </tbody>
                </Table>
            </div>
        </>
    )
}