import React, { useState, useEffect,Fragment } from "react";
import PropTypes from "prop-types";
import {Row,Button,Card,Container,FormControl,InputGroup} from "react-bootstrap";
import Select from "react-select";

export const VehicleInfo = (props) => {
  
  const { data, dataVehicle, infoVehicle, confirmAction } = props;
  
  const fuelType = require("../../../../assets/data/fuel-type");
  const country = require("../../../../assets/data/country");
  const vehicle = data.soat.respuesta_vehiculo;
  const contactVehicle = data.soat.respuesta_vehiculo.Contact;


  const [Contacto, setContacto] = useState({FirstName: "",LastName: "",DocumentTypeId: "",DocumentNumber: "",});
  const [flagPropietario, setFlagPropietario] = useState(false);
  const [flagMotorNumberEdit, setFlagMotorNumberEdit] = useState(false);
  const [flagChasisNumberEdit, setFlagChasisNumberEdit] = useState(false);
  const [flagVinEdit, setFlagVinEdit] = useState(false);
  const [flagPassengersEdit, setFlagPassengersEdit] = useState(false);
  const [flagMotorNumberNotEdit, setFlagMotorNumberNotEdit] = useState(true);
  const [flagChasisNumberNotEdit, setFlagChasisNumberNotEdit] = useState(true);
  const [flagVinNotEdit, setFlagVinNotEdit] = useState(true);
  const [flagPassengersNotEdit, setFlagPassengersNotEdit] = useState(true);
  const [selectDocumento, setSelectDocumento] = useState({label: '-- Seleccione una opcion --',value: ''});
  const tipoDocumento = [{label: 'Cedula Ciudadania', value: 1},{label: 'Cedula de Extranjeria', value: 2},{label: 'Nit', value: 3},{label: 'Tarjeta de identidad', value: 4},{label: 'Pasaporte', value: 5}];
  const [InfoVehicle, setInfoVehicle] = useState({ChasisNumber: vehicle.ChasisNumber,
    MotorNumber: vehicle.MotorNumber,
    PassengerCapacity: vehicle.PassengerCapacity,
    Vin: vehicle.Vin,
    VehicleBodyTypeName:vehicle.VehicleBodyTypeName
  });
  const [flagVehicleBodyTypeName, setFlagVehicleBodyTypeName] = useState(false);
  const [flagVehicleBodyTypeNameNotEdit, setFlagVehicleBodyTypeNameNotEdit] = useState(true);
  
  const isObjEmpty = (obj) => {
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  };
  
  const loadData = () => {

    
    if (isObjEmpty(dataVehicle) === false) {
    
        setInfoVehicle({
        ChasisNumber: dataVehicle.ChasisNumber,
        MotorNumber: dataVehicle.MotorNumber,
        PassengerCapacity: dataVehicle.PassengerCapacity,
        Vin: dataVehicle.Vin,
        VehicleBodyTypeName:dataVehicle.VehicleBodyTypeName
      })
    } else if(isObjEmpty(dataVehicle) ===true&&(InfoVehicle.ChasisNumber === ''||parseInt(InfoVehicle.ChasisNumber) === 0)&&parseInt(vehicle.VehicleYear)<2011){
       
      setInfoVehicle({
        ChasisNumber: 'NA',
        MotorNumber: dataVehicle.MotorNumber,
        PassengerCapacity: dataVehicle.PassengerCapacity,
        Vin: dataVehicle.Vin,
        VehicleBodyTypeName:dataVehicle.VehicleBodyTypeName
      })
    }

    if (contactVehicle !== null) {

      setFlagPropietario(true);
      setContacto({
        ...Contacto,
        FirstName: vehicle.Contact.FirstName,        
        LastName: vehicle.Contact.LastName,      
        DocumentTypeId: vehicle.Contact.DocumentTypeId,     
        DocumentNumber: vehicle.Contact.DocumentNumber,
      });
    }
  };

  const verifyFields = () => {

    if (
     
      (InfoVehicle.ChasisNumber === ''&&vehicle.VehicleYear>=2011) ||
      InfoVehicle.MotorNumber === '' ||
      InfoVehicle.PassengerCapacity === '' ||
      ((InfoVehicle.Vin === null||InfoVehicle.Vin === '')&&vehicle.VehicleYear>=2011)     
    ) {
      alert('Alerta', 'Todos los campos son requerido')
    } else {
      sendData();
    }
  };

  const changeTypes = () => {   
    if (vehicle.ChasisNumber === "" || vehicle.ChasisNumber === null) {
      setFlagChasisNumberEdit(true)
      setFlagChasisNumberNotEdit(false)
    }
    if (InfoVehicle.MotorNumber === "" || InfoVehicle.MotorNumber === null) {
      setFlagMotorNumberEdit(true)
      setFlagMotorNumberNotEdit(false)
    } 
    if (InfoVehicle.VehicleBodyTypeName === "" || InfoVehicle.VehicleBodyTypeName === null) {
      setFlagVehicleBodyTypeName(true)
      setFlagVehicleBodyTypeNameNotEdit(false)
    } 
    if (vehicle.PassengerCapacity === "" || vehicle.PassengerCapacity === null || vehicle.PassengerCapacity === 0) {
      setFlagPassengersEdit(true)
      setFlagPassengersNotEdit(false)
    }
    if ((vehicle.Vin === "" || vehicle.Vin === null)&&parseInt(vehicle.VehicleYear)>=2011) {
      setFlagVinEdit(true)
      setFlagVinNotEdit(false)
    }
  }

  const sendData = () => {
    infoVehicle({ 
      ChasisNumber: InfoVehicle.ChasisNumber,
      MotorNumber: InfoVehicle.MotorNumber,
      PassengerCapacity: InfoVehicle.PassengerCapacity,
      Vin: InfoVehicle.Vin,
      VehicleBodyTypeName:InfoVehicle.VehicleBodyTypeName
    }) 
    confirmAction(false);
  };

  useEffect(() => {
   const descuento=data.soat.respuesta_tarifa.Law2161Discount;

    if (descuento) {
        if(descuento.HasDiscount){
            alert(`Esta placa tiene un descuento de `)
        }
     
    }
    changeTypes();
    loadData(); 
  }, []);

  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={styles.container}>
        <Container>
          <Row className="justify-content-md-center">
            <Card
              className="reloadsCard"
              style={{ minWidth: "100%", borderColor: "#078FA5" }}
            >
              <Card.Body>

                <div style={{width:'100%'}}>
                  <div style={{borderBottomWidth: 1,backgroundColor: "#07A2BA",pAlign: "center",}}>
                    <p style={styles.title2}>Datos del Vehículo</p>
                  </div>
                  <div style={styles.rowContainer} className="row">
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Placa</p>
                      <p>{vehicle.NumberPlate}</p>
                    </div>
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Modelo</p>
                      <p>{vehicle.VehicleYear}</p>
                    </div>
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Cilindraje</p>
                      <p>{vehicle.CylinderCapacity}</p>
                    </div>
                  </div>
                  <div style={styles.rowContainer} className="row">
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Pais</p>
                      <p>{country[vehicle.CountryId]}</p>
                    </div>
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Ciudad</p>
                      <p>{vehicle.CityName}</p>
                    </div>
                  </div>
                  <div style={styles.rowContainer} className="row">
                    {flagMotorNumberEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>N.º motor</p>
                        <InputGroup className="mb-2">
                          <FormControl
                            placeholder=""
                            value={InfoVehicle.MotorNumber}
                            onChange={(e) =>setInfoVehicle({...InfoVehicle, MotorNumber: e})}
                          />
                        </InputGroup>
                      </div>
                      )}
                    {flagMotorNumberNotEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>N.º motor</p>
                        <p>{vehicle.MotorNumber}</p>
                      </div>
                    )}

                    {flagChasisNumberEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>N.º de chasis</p>
                        <InputGroup className="mb-2">
                          <FormControl
                            placeholder=""
                            value={InfoVehicle.ChasisNumber}
                            onChange={(e) =>setInfoVehicle({...InfoVehicle, ChasisNumber: e})}
                          />
                        </InputGroup>
                      </div>
                    )}
                    {flagChasisNumberNotEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>N.º de chasis</p>                        
                        {InfoVehicle.ChasisNumber.toString() === 'NA'&& (<p>NA</p>)}
                        {InfoVehicle.ChasisNumber.toString() !== 'NA'&&(<p>{InfoVehicle.ChasisNumber}</p>)}                        
                  
                      </div>
                    )}
                    {flagVinEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>VIN</p>
                        <InputGroup className="mb-2">
                          <FormControl
                            placeholder=""
                            value={InfoVehicle.Vin}
                            onChange={(e) =>setInfoVehicle({...InfoVehicle, Vin: e})}
                          />
                        </InputGroup>
                      </div>
                    )}
                    {flagVinNotEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>VIN</p>
                        <p>{InfoVehicle.Vin}</p>
                        {/* <p>{vehicle.Vin}</p> */}
                        
                      </div>
                    )}

                  </div>
                  <div style={styles.rowContainer} className="row">
                  {flagPassengersEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>Pasajeros</p>
                        <InputGroup className="mb-2">
                          <FormControl
                            placeholder=""
                            value={InfoVehicle.PassengerCapacity}
                            onChange={(e) =>
                              setInfoVehicle({...InfoVehicle, PassengerCapacity: e})}
                          />
                        </InputGroup>
                      </div>
                    )}
                    {flagPassengersNotEdit && (
                      <div style={styles.colContainer}>
                        <p style={styles.title}>Pasajeros</p>
                        <p>{vehicle.PassengerCapacity}</p>
                      </div>
                    )}
                    <div style={styles.colContainer}>
                        <p style={styles.title}>Tarjeta Op</p>
                        <p>{vehicle.ActionRadio ? vehicle.ActionRadio:"--"}</p>
                    </div>
                    <div style={styles.colContainer}>
                        <p style={styles.title}>Carga</p>
                        <p>{vehicle.LoadCapacity}</p>
                    </div>
                  </div>

                  <div style={styles.rowContainer} className="row">
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Marca</p>
                      <p>{vehicle.BrandName}</p>
                    </div>
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Linea</p>
                      <p>{vehicle.VehicleLineDescription}</p>
                    </div>
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Clase</p>
                      <p>{vehicle.VehicleClassName}</p>
                    </div>
                  </div>

                  <div style={styles.rowContainer} className="row">
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Combustible</p>
                      <p>{fuelType ? fuelType[vehicle.FuelTypeId]: vehicle.FuelTypeId}</p>
                    </div>

                    {flagVehicleBodyTypeNameNotEdit && <div  style={styles.colContainer}>
                      <div>
                        <p style={styles.title}>Carroceria</p>
                        <p>{vehicle.VehicleBodyTypeName}</p>
                      </div>
                    </div>}

                    {flagVehicleBodyTypeName&&<div style={styles.colContainer}>
                      <p style={styles.title}>Carroceria</p>
                      <InputGroup className="mb-2">
                          <FormControl
                            placeholder=""
                            value={InfoVehicle.VehicleBodyTypeName}
                            onChange={(e) =>
                              setInfoVehicle({...InfoVehicle, VehicleBodyTypeName: e})}
                          />
                        </InputGroup>
                
                    </div>}

                    <div style={styles.colContainer}>
                      <p style={styles.title}>Servicio</p>
                      <p>{vehicle.ServiceTypeName}</p>
                    </div>
                  </div>
              
                  <div style={styles.rowContainer} className="row">

                  {flagPropietario && (
                    <div style={{width:'100%'}}>
                      <div style={{ borderBottomWidth: 1, borderColor: "#078FA5" }}>
                        <p style={styles.title2 }>Propietario del Vehiculo</p>
                      </div>

                      <div style={styles.rowContainer} className="row">

                      <div style={styles.colContainer}>
                        
                          <p style={styles.title}>Nombre</p>
                          <InputGroup className="mb-2">
                            <FormControl
                              value={Contacto.FirstName}
                              onChange={e => setContacto({...Contacto,FirstNames:e.target.value})}
                             
                            />
                          </InputGroup>
                        </div>
                        
                        <div style={styles.colContainer}>
                        
                          <p style={styles.title}>Apellido</p>
                          <InputGroup className="mb-2">
                            <FormControl
                              value={Contacto.LastName}
                              onChange={e => setContacto({...Contacto,LastName:e.target.value})}
                              
                            />
                          </InputGroup>
                        </div>

                       
                      </div>

                      <div style={styles.rowContainer}  className="row">
                        <div style={{ width: "58%" }}>
                          <p   style={styles.title}>Tipo de Documento</p>
                          <Fragment>
                            <Select className="basic-single" classNamePrefix="select"
                              value={selectDocumento} isDisabled={false}
                              isLoading={false} isClearable={true}
                              isRtl={false} isSearchable={true}
                              name="color"  options={tipoDocumento}
                              onChange={(value) => { setSelectDocumento(value);
                              }}
                            />
                          </Fragment>
                        </div>
                      <div style={styles.rowContainer}  className="row">
                          <div style={{ width: "38%" }}>
                            <p style={styles.title}>N.º de Documento</p>
                            <InputGroup >
                              <FormControl
                                value={Contacto.DocumentNumber}
                                onChange={e => setContacto({...Contacto,DocumentNumber:e.target.value})}
                              
                              />
                            </InputGroup>
                          </div>
                      </div>
                        

                        
                      </div>
                    </div>
                  )}
                                      
                  </div>
                  
                </div>

                <div className="mb-2" style={{ align: "center", textAlign: "center" }} >
                  <Button onClick={verifyFields}
                    style={{backgroundColor: "#07A2BA",marginTop: "2%",widt: "50%",}}
                  > Siguiente
                  </Button>
                </div>

              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

VehicleInfo.propTypes = {
  vehicle: PropTypes.object,
  
};
VehicleInfo.defaultProps = {
  vehicle: {},
 
};
const styles = {
  title: {
    fontWeight: "bold",
    align: "center",
  },
  title2: {
    fontWeight: "bold",
    textAlign: "center",
  },
  container: {
    paddingHorizontal: 10,
    maxHeight: "100%",
    //backgroundColor: '#078FA5'
  },
  titleSummary: {
    alignSelf: "center",
    fontWeight: "bold",
    fontSize: 18,
    color: "#07A2BA",
  },
  colContainer: {
    width: "33%",
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 5,
    width: "100%",
    paddingLeft: 15,
  },
};
