import React from 'react';
import { jsPdfGenerator } from '../../../shared/pdfTransaction';
import { FormatMoney } from '../../../utilities/formats';

export const ItemMoves = (props) => {
    const { itemMoves, products } = props;
    let aprobation = '--';
    //console.log("esto es lo de movimientos",itemMoves)
     const printBill = () => {
        let data = [];
        if (itemMoves.categ_id_name === "Rifas") {
            let fechaSorteo = '';
            let resolucion = ''
            products.rifas.forEach(element => {

                if (element.id === itemMoves.producto_id){
                    fechaSorteo = element.fecha_sorteo
                    resolucion = element.numero_resolucion_rifa;
                }
            });

            data = [
                "Factura No: " + itemMoves.id + " COPIA",
                "Fecha:" + itemMoves.fecha,
                "No.Aprobacion:" + itemMoves.rifa_aprobacion,
                "Producto: " + itemMoves.descripcion,                
                "Resolucion: " + resolucion,
                "Fecha del sorteo: " + fechaSorteo,                
                "Número de boleta: " + itemMoves.rifa_numero_boleta,
                "Cliente: " + itemMoves.nombre_cliente,
                "Valor: " + FormatMoney(itemMoves.valor)
            ]
        } else if (itemMoves.categ_id_name === "Recargas" || itemMoves.categ_id_name === "Voz" || itemMoves.categ_id_name === "Voz y Datos"
            || itemMoves.categ_id_name === "Aplicaciones" || itemMoves.categ_id_name === "Datos"
            || itemMoves.categ_id_name === "Todo incluido") {

            data = [
                "Factura No: " + itemMoves.id + " COPIA",
                "Fecha:" + itemMoves.fecha,
                "No.Aprobacion:" + itemMoves.recarga_aprobacion,
                "Producto: " + itemMoves.descripcion,
                "Celular: " + itemMoves.recarga_numero_celular,
                "Valor: " + FormatMoney(itemMoves.valor)
            ]
        } else if (itemMoves.categ_id_name === "Netflix" ||
            itemMoves.categ_id_name === "Xbox" ||
            itemMoves.categ_id_name === "Imvu" ||
            itemMoves.categ_id_name === "Playstation" ||
            itemMoves.categ_id_name === "Rixty" ||
            itemMoves.categ_id_name === "Spotify" || itemMoves.categ_id_name === "Minecraft") {
            data = [
                "Factura No: " + itemMoves.id + " COPIA",
                "Fecha:" + itemMoves.fecha,
                "No.Aprobacion:" + itemMoves.recarga_aprobacion,
                "Producto: " + itemMoves.categ_id_name,
                "Celular: " + itemMoves.recarga_numero_celular,
                "Valor: " + FormatMoney(itemMoves.valor)
            ]

        } else if (itemMoves.categ_id_name === "Recargas deportivas") {
            data = [
                "Factura No: " + itemMoves.id + " COPIA",
                "Fecha:" + itemMoves.fecha,
                "No.Aprobacion:" + itemMoves.recarga_aprobacion,
                "Producto: " + itemMoves.descripcion,
                "Celular: " + itemMoves.recarga_numero_celular,
                "Valor: " + FormatMoney(itemMoves.valor)
            ]
        }else if(itemMoves.categ_id_name ==="Bingo"){
            
            data = [
                "Factura No: " + itemMoves.id + " COPIA",
                "Fecha:" + itemMoves.fecha,
                "No.Aprobacion:" + itemMoves.bingo_aprobacion,
                "Producto: GANA BINGO ",
                "Cedula: "+ itemMoves.bingo_cedula,
                "Cliente: "+itemMoves.bingo_cliente_nombre + " " +itemMoves.bingo_apellido_cliente,
                "Celular: " + itemMoves.bingo_numero_celular,
                "Valor: " + FormatMoney(itemMoves.valor)
            ]
        }else if(itemMoves.categ_id_name==="Soat"){
            let str = JSON.stringify(itemMoves.soat_datos).replace(/\\/g, '');
              str = str.replace('""""', '""');
              str = str.replace('"{', '{');
              str = str.replace('}"', '}');
              str = str.replace('""""', '""');
              str = str.replace('"{', '{');
              str = str.replace('}"', '}');
            const jsondataSoat = JSON.parse(str);
            data = [
                'Factura No: ' + itemMoves.id + ' COPIA',
                'Fecha:' + itemMoves.fecha,
                "No. Aprobación: "+ itemMoves.recarga_aprobacion,
                'Producto: ' + itemMoves.categ_id_name,
                'Celular:' + jsondataSoat.Contact.Phone,
                'Cedula:'+jsondataSoat.Contact.DocumentNumber,
                'Cliente: ' + jsondataSoat.Contact.FirstName+" "+jsondataSoat.Contact.FirstName1+" "+jsondataSoat.Contact.LastName+" "+jsondataSoat.Contact.LastName1,
                'Placa: ' + itemMoves.soat_placa,
                'Valor: ' + FormatMoney(itemMoves.valor.toFixed(2)),
              ];
        } 
        
       /*  else if(itemMoves.categ_id_name==="Certificados"){
            let str = JSON.stringify(itemMoves.cer).replace(/\\/g, '');
              str = str.replace('""""', '""');
              str = str.replace('"{', '{');
              str = str.replace('}"', '}');
              str = str.replace('""""', '""');
              str = str.replace('"{', '{');
              str = str.replace('}"', '}');
            const jsondataSoat = JSON.parse(str);
            data = [
                // 'Factura No: ' + itemMoves.id + ' COPIA',
                // 'Fecha:' + itemMoves.fecha,
                // "No. Aprobación: "+ itemMoves.recarga_aprobacion,
                // 'Producto: ' + itemMoves.categ_id_name,
                // 'Celular:' + jsondataSoat.Contact.Phone,
                // 'Cedula:'+jsondataSoat.Contact.DocumentNumber,
                // 'Cliente: ' + jsondataSoat.Contact.FirstName+" "+jsondataSoat.Contact.FirstName1+" "+jsondataSoat.Contact.LastName+" "+jsondataSoat.Contact.LastName1,
                // 'Placa: ' + itemMoves.soat_placa,
                // 'Valor: ' + FormatMoney(itemMoves.valor.toFixed(2)),
              ];
        }   */      
        jsPdfGenerator(data)        
    }
    
    const tipo = () => {
        let mitabla = ''
        let categflag = false

        if (itemMoves.categ_id_name === "Recargas") {
            mitabla = 'Recargas'
            categflag = true
        } else if (itemMoves.categ_id_name === "Todo incluido" ||
            itemMoves.categ_id_name === "Voz" ||
            itemMoves.categ_id_name === "Datos" ||
            itemMoves.categ_id_name === "Voz y Datos" ||
            itemMoves.categ_id_name === "Aplicaciones") {
            mitabla = 'Paquetes'
            categflag = true
        } else if (itemMoves.categ_id_name === "Rifas") {
            mitabla = 'Rifas'
            categflag = true
        } else if (itemMoves.categ_id_name === "TV") {
            mitabla = 'Tv'
            categflag = true
        } else if (itemMoves.categ_id_name === "Netflix" ||
            itemMoves.categ_id_name === "Xbox" ||
            itemMoves.categ_id_name === "Imvu" ||
            itemMoves.categ_id_name === "Playstation" ||
            itemMoves.categ_id_name === "Rixty" ||
            itemMoves.categ_id_name === "Spotify" ||
            itemMoves.categ_id_name === "Minecraft") {
            mitabla = 'Pines'
            categflag = true
        } else if (itemMoves.categ_id_name === "Recargas deportivas") {
            mitabla = 'RecargasDeportivas'
            categflag = true
        }else if (itemMoves.categ_id_name === "Bingo") {
            mitabla = 'Bingo'
            categflag = true
        }else if (itemMoves.categ_id_name === "Soat") {
            mitabla = 'Soat'
            categflag = true
        }
        
        else if (itemMoves.categ_id_name === "Certificado") {
            mitabla = 'Certificado'
            categflag = true
        }

        return { mitabla: mitabla, categflag: categflag }
    }
    let data = tipo()
    if (itemMoves.rifa_aprobacion) {
        aprobation = itemMoves.rifa_aprobacion;
    } else if (itemMoves.recarga_aprobacion) {
        aprobation = itemMoves.recarga_aprobacion;
    }else if(itemMoves.bingo_aprobacion){
        aprobation = itemMoves.bingo_aprobacion;
    }

    let phone ='----';
    if(itemMoves.recarga_numero_celular){
        phone=itemMoves.recarga_numero_celular;
    }else if(itemMoves.bingo_numero_celular){
        phone=itemMoves.bingo_numero_celular;
    }
    

    return (
        <>
            <tr>
                <td>{itemMoves.id}</td>
                <td>{itemMoves.observacion}</td>
                <td>{FormatMoney(itemMoves.valorpagar.toFixed(2))}</td>
                <td>{itemMoves.tipo}</td>
                <td>{itemMoves.transactionId}</td>
                {/* <td>{phone}</td> */}
                {/* <td>{FormatMoney(itemMoves.comision)}</td> */}
                <td>{itemMoves.datetime.split(" ")[0]}</td>
                <td>{itemMoves.datetime.split(" ")[1]}</td>

                {data.categflag && <td><button className='fa fa-print btn' onClick={() => printBill()}></button></td>}

            </tr>
        </>
    )
}

export const ItemError = (props) => {
    const { itemError } = props
    return (
        <tr>
            <td>{itemError.id}</td>
            <td>{itemError.nombre_cliente}</td>
            <td>{FormatMoney(itemError.valor.toFixed(2))}</td>
            <td>{itemError.recarga_numero_celular?itemError.recarga_numero_celular:'---'}</td>
            <td>{itemError.fecha}</td>
            <td>{itemError.tipo}</td>
            <td>{itemError.observacion}</td>
        </tr>
    )
}

export const ItemPending = (props) => {
    const { itemError } = props
    return (
        <tr>
            <td>{itemError.tipo}</td>
            <td>{itemError.datetime}</td>
            <td>{itemError.observacion}</td>
            <td>{itemError.estado}</td>
        </tr>
    )
}




