import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { ListMoves, ListErrors, ListPending } from './components/list';
import { getBox, getPendingPayments } from '../../services/caja.service';
import { LoadingMovilGo } from '../../shared/loadingMovilGo';
import { ManagmentErrors } from '../../shared/managmentErrors';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../shared/header/header';
import { idClienteFinal } from '../../utilities/odoo-config';
import HeaderCardoso from '../../shared/header/header-cardoso';
export const Caja = () => {

    let [loading, setLoading] = useState(false)
    const [products, setProducts] = useState()
    const [key, setKey] = useState('moves');
    const [pageMoves, setPageMoves] = useState(1);
    const [pageErrors, setPageErrors] = useState(1);
    const [moves, setMoves] = useState([]);
    const [listErrors, setListErrors] = useState([]);
    const [deslogear, setDeslogear] = useState()
    const [pending, setPending] = useState([])

    const viewMoves = async () => {
        /* const idDelPadre = localStorage.getItem("IdPadreCliente") 
        setLoading(true)
        console.log("viewMoves ",pageMoves)
            if (pageMoves > 0) {
                await getBox(0, pageMoves,null ,idDelPadre,initializeMoves);

            } */

        getPendingPayments((res, flag) => {
            const estadoApproved = res.filter(element => element.estado == "APPROVED");
            console.log("Estados Aprobados:.....", estadoApproved)
            initializeMoves(estadoApproved, flag)
        })

    }
    const viewPending = async () => {
        setLoading(true)
        getPendingPayments((res, flag) => {
            console.log(" getPendingPayments.....", flag, res)
            const estadoPendiente = res.filter(element => element.estado !== "APPROVED");
            console.log("Estados por aprobar:.....", estadoPendiente)
            setLoading(false)
            if (flag) {
                setPending(estadoPendiente)
            } else {
                alert("Error", "Se presento un problema al obtener los movimientos pendientes")
            }

        })
    }
    const initializeMoves = (data, flag) => {
        console.log("initializeMoves**********", flag, data)
        setLoading(false)
        if (flag) {

            if (data.length > 0) {

                setMoves(data);

            } else if (data.length === 0) {
                const error = () => toast.error("¡No se encontraron movimientos!");
                error();
                if (pageMoves === 1) {
                    setPageMoves(1)
                } else {
                    setPageMoves(pageMoves - 1)
                }
            } 

        } 
    }

    const viewErrors = async () => {
        const idDelPadre = localStorage.getItem("IdPadreCliente");
        setLoading(true)
        if (pageErrors > 0) {
            await getBox(1, pageErrors, null, idDelPadre, initializeErrors);
        }
    }

    const initializeErrors = (data, flag) => {
        console.log("initializeErrors", flag, data)
        setLoading(false)
        if (flag) {

            if (data.movimientosError.length > 0) {
                setListErrors(data.movimientosError);
            } else if (data.movimientosError.length === 0) {
                //error("¡No se encontraron errores registrados!")
                const error = () => toast.error("¡No se encontraron  errores registrados!");
                error();
                if (pageErrors === 1) {
                    setPageErrors(1)
                } else {
                    setPageErrors(pageErrors - 1)
                }
            }
        } else {
            alert(data.message);
        }
    }

    const initializeproducts = async () => {
        const product = await JSON.parse(localStorage.getItem("products"));
        setProducts(product)
    }

    const ordenarPorEstado = () => {
        getPendingPayments((res, flag) => {
            const estadoApproved = res.filter(element => element.estado == "APPROVED");
            const estadoPendiente = res.filter(element => element.estado !== "APPROVED");
            console.log("Estados Aprobados:.....", estadoApproved)
            console.log("Estados por aprobar:.....", estadoPendiente)
        })
    }

    useEffect(() => {
        ordenarPorEstado()
        initializeproducts()
    }, [])
    useEffect(() => {
        console.log("las keys--", key)
        if (key === 'moves') {
            viewMoves();
        } else if (key === 'pending') {
            viewPending();
        } else if (key === 'errors') {
            viewErrors();
        }

    }, [key, pageMoves, pageErrors])


    return (
        <>
            <ToastContainer autoClose={4000} />
            <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
            {localStorage.getItem('idDelPadre') == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={deslogear}
                                                               newlogeo={(flag) => setDeslogear(flag)} /> : <Header
                                                               deslogear={deslogear}
                                                               newlogeo={(flag) => setDeslogear(flag)} />}
            </div>
            <div style={{ width: '90%', display: 'inlineBlock', marginLeft: 120/* ,height:610 */, marginTop: '5%', marginBottom: '20%' }} className="justify-content-md-center">
                <Tabs
                    className="tab"
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => { setKey(k) }}
                >
                    <Tab eventKey="moves" title="Movimientos Aprobados">
                        {moves.length > 0 &&
                            <ListMoves
                                contPage={pageMoves}
                                rigthPage={() => setPageMoves(pageMoves + 1)}
                                leftPage={() => { if (pageMoves > 1) { setPageMoves(pageMoves - 1) } }}
                                products={products}
                                moves={moves}
                                date={null}
                            />}
                    </Tab>
                    <Tab eventKey="pending" title="Movimientos Pendientes">
                        {pending.length > 0 && <ListPending
                            products={products}
                            pending={pending}
                            date={null}
                        />}
                    </Tab>
                    <Tab eventKey="errors" title="Errores">
                        {listErrors.length > 0 && <ListErrors
                            contPage={pageErrors}
                            rigthPage={() => setPageErrors(pageErrors + 1)}
                            leftPage={() => { if (pageErrors > 1) { setPageErrors(pageErrors - 1) } }}
                            errors={listErrors} />}
                    </Tab>
                </Tabs>

            </div>
            {loading && <LoadingMovilGo />}
        </>
    )
}