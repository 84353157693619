import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const searchPrestamo = async (product_id, tipo, id_solicitud, cedula, callback) => {

  const args = [[0, product_id, id_solicitud, cedula, tipo]];
  const config = await OdooConfig();//SESSION
  const user = await Desencrypt();      
  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username: user.user,
    password: user.password
  });

  const params = {
    model: 'movilgo.webservice',
    method: 'obtenerSolicitudCreditoMovilgo',
  }

  client.connect((err, response) => {
    if (err) {          
      return callback(response, false) ;
    }  
    client.execute_kw(params.model, params.method, args, (err2, response2) => {      
      
      if (err2) {        
        
        return callback(err2, false, null);
      }
      return callback(response2, true, tipo);
    });
    
  })
}

