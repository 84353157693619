import React from 'react';
import { Spinner, FormLabel } from 'react-bootstrap';

export const LoadingMovilGoLogin = (props) => {
  
    return (
        <>
            {<div style={{ backgroundColor: 'rgba(0,0,0,0)',position:'relative', textAlign:'center',  top:'50%',zIndex:2,justifyContent:'center', width:'100%',height:'100%' }}>
                <FormLabel style={{ right: 50, fontSize:60,textShadow: '3px 3px 4px #07A2BA' }}>Móvil</FormLabel>
                <Spinner role="status" size="lg lg" style={{
                    backgroundColor: '#07A2BA',
                    position: 'absolute', top: 5,
                    padding: 0,
                    height:'70%',
                    width:'20%'
                }} animation="grow" ><h1 style={{ color: 'white', fontSize: 65 }}><b>Go...</b></h1>
                </ Spinner>

            </div>}

        </>
    )
}

export const LoadingMovilGo = (props) => {
  
    return (
        <>
            {<div  style={{ backgroundColor: 'rgba(0,0,0,0)', position: 'absolute', zIndex: 2, width: '50%',top:'40%',left:'40%' }}>
            <FormLabel style={{  fontSize:60,textShadow: '3px 3px 4px #07A2BA' }}>Móvil</FormLabel>
                <Spinner role="status" size="lg lg" style={{
                    backgroundColor: '#07A2BA',
                    position: 'absolute', top: 5,
                    padding: 0,height:'70%',
                    width:'15%'
                }} animation="grow" ><h1 style={{ color: 'white', fontSize: 65 }}><b>Go...</b></h1>
                </ Spinner>

            </div>}

        </>
    )
}
export const LoadingMovilGoSoat = (props) => {
  
    return (
        <>
            {<div style={{ backgroundColor: 'rgba(0,0,0,0)', position: 'absolute',left:'20%',width:'300%',zIndex: 2,top:'40%' }}>
            <FormLabel style={{ right: 50, fontSize:60,textShadow: '3px 3px 4px #07A2BA',height:'90%'}}>Móvil</FormLabel>
                <Spinner role="status" size="lg lg" style={{
                    backgroundColor: '#07A2BA',
                    position: 'absolute', top: 5,
                    padding: 0,height:'70%',
                    width:'10%'
                }} animation="grow" ><h1 style={{ color: 'white', fontSize: 65 }}><b>Go...</b></h1>
                </ Spinner>

            </div>}

        </>
    )
}

export const LoadingMovilGoRegistro = (props) => {
  
    return (
        <>
            {<div style={{ backgroundColor: 'rgba(0,0,0,0)', position: 'absolute', zIndex: 2, width: '100%',top:'50%' }}>
            <FormLabel style={{ right: 50, fontSize:60,textShadow: '3px 3px 4px #07A2BA' }}>Móvil</FormLabel>
                <Spinner role="status" size="lg lg" style={{
                    backgroundColor: '#07A2BA',
                    position: 'absolute', top: 5,
                    padding: 0,height:'70%',
                    width:'25%'
                }} animation="grow" ><h1 style={{ color: 'white', fontSize: 65 }}><b>Go...</b></h1>
                </ Spinner>

            </div>}

        </>
    )
}
