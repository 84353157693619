import React, { useState,useEffect} from 'react';
import {Button,Image, Row, Col,} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import { useToasts } from 'react-toast-notifications'
import { LoginComponent } from '../../screens/login/login';
import { Encrypt, Desencrypt} from '../../utilities/cipher';
import {faMoneyBill, faBars,faHouse,faPhone,faCar,faFutbol,faBox ,faCommentSms,faFile,faHome,faUser,faFileArchive} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imagenMovil from '../../assets/header/movilgo.png';
import Modal from 'react-modal';
import Drawer from 'react-modern-drawer'
import { ButtonImage } from '../buttons-services';
import 'react-modern-drawer/dist/index.css'
import { StateParent_id, setData } from '../../services/login.service';
import { OdooConfig, Url_vendedor, } from '../../utilities/odoo-config';
import Odoo from 'odoo-xmlrpc';
import {Register} from './components/register';
import { LoadingMovilGo } from '../loadingMovilGo';
import { Capitalize } from '../../utilities/formats';
import '../../assets/header/header.css';
const Header =(props)=>{
  const {showSeller=false, deslogear,newlogeo,idPadre} = props;
  console.log("idpadre: ", localStorage.getItem('idDelPadre'));
  console.log("idpadre: ", localStorage.getItem('IdPadreCliente'));
   const [viewModalLogin, setViewModalLogin] = useState(false);
   const [viewModalRegister, setViewModalRegister] = useState(false);
   const [username,setUserName]=useState("")
   const [loading, setLoading] = useState(false);
   const [userLog, setUserLog] = useState(false);
   const [isMenuOpen,setIsMenuOpen]=useState(false) 
   Modal.setAppElement('div')
   const { addToast } = useToasts() 
   const navigate = useNavigate();
   
   const initialiceData = async () => {
     console.log("showSeller kjojoj",showSeller)
       const data = await Desencrypt();
       
       if (data) {
           const dataUser=await JSON.parse(localStorage.getItem('dataUser'));
           console.log("dataUser del header",dataUser.x_name1)
           setUserName(dataUser.x_name1)
           setUserLog(true);
       } else {
       }
   }
  if(deslogear){
     refreshPage()
  }
  function refreshPage() {
   setUserLog(false);
     localStorage.removeItem("Session");
     localStorage.removeItem("Permission");
     newlogeo(false)
     navigate("/")
   window.location.reload(true);
 }
 
 useEffect(() => { 
    initialiceData();
  
 
 
 }, [])
 
 const getListProducts = async(products, flag) => {
 
   if (flag) {
     setUserLog(true);
     addToast('Bienvenido a MóvilGo ' + username, {
       appearance: 'success',
       autoDismiss: true,
     })
     await localStorage.setItem("products", JSON.stringify(products.products))
     
     navigate('/') 
     window.location.reload(true); 
     
 
   }
   setLoading(true)
 }
 const initialiceProducts = async () => {
   const config = await OdooConfig();
   const data = await Desencrypt();
   if (data) {
     const permiso = localStorage.getItem('Permission');
     if (permiso !== 'cobrador') {
       const odoo = new Odoo({
         url: config.host,
         port: config.port,
         db: config.database,
         username: data.user,
         password: data.password
       });
       
       setData(odoo, getListProducts, data);
     }
 
   }
 }
 
   const login=(data,flag)=>{    
       if(flag){
         console.log("data.user",data.user)
 
         Encrypt(data.user);
         setLoading(true)
         setViewModalLogin(false);
         setViewModalRegister(false)
         
                 
 
             setTimeout(() => {
               initialiceProducts();
               // window.location.reload();
           }, 2000); 
           
              
           
           return
       }else{
         
         const errorr = JSON.stringify("ErrorSession TypeError: network erro");
         switch (data) {
           
           case 1:
               addToast('Usuario y/o contraseña incorrecta.', {
                   appearance: 'error',
                   autoDismiss: true,
               });
 
               break;
           case 2:
             addToast(errorr, {
                   appearance: 'error',
                   autoDismiss: true,
               });
               break;
 
           case 3:
             addToast('Debe ingresar el usuario y la contraseña.', {
                   appearance: 'error',
                   autoDismiss: true,
               });
 
               break;
           case 4:
             addToast('Debe ingresar el usuario.', {
                   appearance: 'error',
                   autoDismiss: true,
               });
 
               break;
           case 5:
             addToast('Debe ingresar la contraseña.', {
                   appearance: 'error',
                   autoDismiss: true,
               });
               break;
           default:
               break;
         }
 
       }
       setLoading(!loading)
     }
   
     const drawer=(
       
       <div >
           
               {Routes.map((source,i) => {
                 return (
                     <ButtonImage key={i}  source={source} onClick={() => {navigation(source.source)}} />
                   )
                 
             })}
       </div>
       
       
     )
   
 
     const navigation =(ruta)=>{
       setIsMenuOpen(!isMenuOpen)
       navigate(ruta)
     }
     const close = () => {      
       setUserLog(false);
       localStorage.removeItem("Session");
       localStorage.removeItem("Permission");
       /* console.log("id padre desde el header: ", idClienteFinalCardoso) */
       if (localStorage.getItem('idDelPadre') == "movilgo"){
         navigate("/");
         localStorage.removeItem("idDelPadre");
       } else if (localStorage.getItem('idDelPadre') == "cardoso") {
         navigate("/cardoso");
         localStorage.removeItem("idDelPadre");
       }
       
      window.location.reload(true);
   }
    
     return(
             <Row  >
                 <div>
                   <div className='verde'  >
                           <Col md={2} xs={2} sm={2} lg={2} className="my-auto">
                               {userLog&&<div>
                                 <Row>
                                  <FontAwesomeIcon size="2x" style={{marginLeft:'10%',width:'10%',marginTop:'-14%'}} icon={faBars} color='#FFFFFF' onClick={()=>setIsMenuOpen(!isMenuOpen)} /> 
                                   <p style={{color:'white', fontSize: 22, left:80,width:'25%',marginTop:'-14%'}}>Menu</p>
                                   </Row>
                               </div>
                               }
                           </Col>
                           <Col md={6} xs={6} sm={6} lg={6} className="my-auto">
                             <div className='sesion'>
                             <Image                             
                                 style={{ width: '30%', height: '60%',marginLeft:'43%' }} 
                                 src={imagenMovil}          
                             /> 
                             </div> 
                                                   
                           </Col>                             
                           <Col xs={12} sm={12} md={12}   lg={12} className="my-auto">
                           {!userLog&&<div className='menu'>
                           
                                  {showSeller&&<Button
                                       style={{ background:'transparent', border: 'none',color:'white' ,fontSize: 25 }}
                                       onClick={() => window.open(Url_vendedor)} >Vendedor</Button>}
                                   <Button
                                       style={{ background:'transparent', border: 'none',color:'white' ,fontSize: 25 }}
                                       onClick={() => setViewModalLogin(true)} >Iniciar Sesión</Button>
                                   <Button
                                       style={{ background:'transparent', border: 'none',color:'white',fontSize: 25,marginLeft:20 }}
                                       onClick={() => setViewModalRegister(true)} >Registrate</Button>
                           </div>
                             }
                             </Col>  
                           {userLog&&<div className='user' style={{float: 'right',marginright: '3% !important',margintop: '-4%'}}>
 
                              <row className='text-info'>
                               
                             <Button
                                     style={{ background:'transparent', border: 'none',color: 'white !important', fontSize: 23,margintop: '-67%!important',marginleft: '83%' }}
                                     className='nav-link sesion text-info'
                                     >{Capitalize(username)}</Button>
                                     
                             </row>   
                                   
                                   
                                 <Button 
                                     style={{ background:'transparent', border: 'none',color: 'white !important', fontSize: 23,margintop: '-32%',marginleft: '-34%'}}
                                     className='nav-link cerrar text-info'
                                     onClick={() => close()} >Cerrar Sesión</Button>
                                     
                           </div>
                             }
                               
                           
                           {viewModalLogin && 
                             <LoginComponent 
                               loading={loading} 
                               viewModalLogin={(flag) =>setViewModalLogin(flag)} 
                               onLogin={(resp, flag) => { login(resp, flag) }} />}
                           <Register 
                             idPadre={idPadre}
                             visible={viewModalRegister} 
                             closeModal={(flag)=>setViewModalRegister(flag)} 
                             onLogin={(resp, flag) => { login(resp, flag) }}/>
                     
 
                     
                     </div>   
                   
                   <Drawer
                   open={isMenuOpen}
                   onClose={()=>setIsMenuOpen(!isMenuOpen)}
                   direction='left'
                   className='menu_lateral'
                   style={{backgroundColor:'#005F6E' ,width:300}}
                   >
                   <div className="row"> 
                             <div style={{marginTop:14,color:'white',marginleft: 5}}>
                               <h3  >Bienvenido</h3> 
                             </div>
                             <div  style={{color:'white'}}>
                               
                                <p style={{textAlign:'justify'}}>
                                 {/* {userName} */}
                               </p>                              
                             </div>
                   </div>
                   
                   {drawer}
                   </Drawer>
                   
                   
               </div>
               {loading && <LoadingMovilGo />}
             </Row>
         )
 }
 
 const Routes = [
   {
     icon:faUser,
     source: '/Perfil',
     name:'Perfil' 
   },
   {
     icon:faHome,
     source: '/',
     name:'Inicio'
   },{
     icon:faFileArchive,
     source: '/Caja',
     name:'Movimientos' 
   },{
     icon:faFile,
     source: '/Certificados',
     name:'Certificados'
   }
   ,{
       icon:faPhone,
       source: '/Recargas',
       name:'Recargas'
   },
   {
       icon:faCommentSms,
       source: '/Paquetes',
       name:'Paquetes'
   },  
   {
       icon:faHouse,
       source: '/Rifas',
       name:'Rifas'
   },
   {
       icon:faBox,
       source: '/Pines',
       name:'Pines'
   },{
       icon:faFutbol,
       source: '/Recargas-Deportivas',
       name:'Recargas Deportivas'
   },
   {
       icon:faMoneyBill,
       source: '/Prestamos',
       name:'Prestamos'
   },
   
   {
       icon:faCar,
       source: '/Soat',
       name:'Soat'
   },
   
 ]
 
 

export default Header;