import './App.css';
import React, { PureComponent } from 'react';

import Header from './shared/header/header'
import { ToastProvider } from 'react-toast-notifications'


import RoutesHome from './routes'
import Footer from './shared/footer';
class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deslogear:false
    }
  }
   

   
  render(){
      return(
        
        <ToastProvider> 
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
        
            
           <div> 
                
                <div style={{index:2,display:'inlineBlock',maxWidth:'100%',height:'90%'}}>
                  <RoutesHome deslogear={(flag)=>{this.setState({deslogear:flag})}}/> 
                </div>
                
                <div style={{position:'relative',index:3,display:'inlineBlock',height:'10%',maxWidth:'100%'}}>
                  <Footer/>
                </div>                      
                       
            </div>
          
        </ToastProvider>
      
    )
    }
 
}

export default App;
