import React, { PureComponent } from 'react';
import { ListOperator } from '../../shared/list-operator';
import woman from '../../assets/home/img4.png';
import { Summary } from './components/summary';
import { ListPrices } from '../../shared/list-prices';
import { Container, Row, Col, Form, Card, Button, Image } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../services/product.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ManagmentErrors } from '../../shared/managmentErrors';
import { LoadingMovilGo } from '../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/modalNotificationTransaction';
import { FormatMoney } from '../../utilities/formats';
import { jsPdfGenerator } from '../../shared/pdfTransaction';
import { PayScreen } from '../../shared/pay/pay';
import Header from '../../shared/header/header';
import { Url_home, idClienteFinal } from '../../utilities/odoo-config';
import HeaderCardoso from '../../shared/header/header-cardoso';
class Recargas extends PureComponent {

    constructor(props) {
        super(props);
        /* this.recargas = this.props.recargas; */
        this.state = {
            visible: false,
            deslogear: false,
            selectedOperator: {
                id: -1,
                image_medium: null,
                operador: '',
            },
            recargas: [],

            selectedPrice: '',
            phone: '',
            loading: true,
            opacity: 1,
            modalNotification: false,
            pay: false,
            product: {},
            active: true,
            idHeader: localStorage.getItem('idDelPadre')
        }
        this._isMounted = false;
        this.listRecargas = [];
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.clean = ''
    }

    cleanFile = () => {
        this.setState({
            visible: false,
            selectedOperator: {
                image_medium: null,
                operador: '',
            },
            selectedPrice: '',
            phone: '',
            clean: false
        });
    }

    getResponse = (flag, response) => {
        if (flag) {
            this.response = response;

            const success = () => toast.success("Recarga comprada exitosamente");
            success();
            this.setState({ loading: false, opacity: 1, modalNotification: true });
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            this.setState({ loading: false, opacity: 1 });
        }
    }
    componentDidMount = async () => {
        const product = await JSON.parse(localStorage.getItem("products"));
        //console.log(" product de recargas",product)


        this._isMounted = true;
        if (product) {
            this.setState({ recargas: product.recarga, loading: false, active: true })
        } else {
            const error = () => toast.error("Este producto no esta disponible");
            error();
        }

    }
    componentWillUnmount = () => {
        this._isMounted = false;
    }
    sendData = async () => {
        this.phone = this.state.phone;
        this.valor = this.state.selectedPrice;
        this.operador = this.state.selectedOperator.operador

        //const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal"));
        //const recarga = await JSON.parse(localStorage.getItem("products"));
        const product = {
            product_id: this.state.selectedOperator.id,
            clienteexterno_user_id: idClienteFinal,
            atributes: {
                numero: this.state.phone,
                precio: this.state.selectedPrice
            }
        }
        //console.log("producto de venta",product)
        //this.setState({ loading: true, opacity: 0.4 });
        this.setState({ product: product, pay: true, })
        //await Transaction(product, this.getResponse);
        this.cleanFile();
    }

    selectOperator = (operator) => {
        this.cleanFile();
        this.setState({ selectedOperator: operator });
    }

    selectPrice = (price) => {
        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });
        }
    }

    closeModal = (flag, res) => {

        if (!flag && res) {
            const data = [
                "Factura No: " + this.response.valida.id,
                "Fecha:" + this.response.valida.fecha,
                "No.Aprobacion:" + this.response.valida.numero_aprobacion,
                "Producto: Recarga " + this.operador,
                "Celular: " + this.phone,
                "Valor: " + FormatMoney(this.valor)
            ]
            jsPdfGenerator(data);

        }
        //this.setState({ modalNotification: flag });


    }

    render() {

        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                .contenedor{
                    padding: 0px 10vw 0px 5vw;
                    
                    
                }
                ` }
                </style>
                <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
                {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>

                <div className="contenedor" style={{ height: 630, opacity: this.state.opacity }} >

                    {this.state.idHeader !== "movilgo" ?
                        <div>
                            <ToastContainer />
                            <h2 style={{ color: '#0D606E', marginLeft: '45%', marginTop: '1%' }} className={'defaultTitle'}>Recargas</h2>
                            {this.state.active && <div>
                                <Row className="justify-content-md-center">

                                    <Col>
                                        <ListOperator onClick={(operator) => this.selectOperator(operator)} operators={this.state.recargas} />
                                    </Col>
                                </Row>
                                <Card xs={12} md={12} sm={12} lg={12} className='reloadsCard'>
                                    <Card.Body>
                                        <Container className="" fluid='md'>
                                            <div>
                                                {this.state.selectedOperator.image_medium && <Image src={'data:image/jpeg;base64,' + this.state.selectedOperator.image_medium} style={{ maxHeight: '80px' }} />}
                                            </div>

                                            <Row >

                                                <Col md={12} xs={12} lg={6} className="my-auto">
                                                    <Form.Group >
                                                        <h4 className={'defaultTitle'}>Número de Celular</h4>
                                                        <Form.Control
                                                            placeholder='Celular'
                                                            arial-label="Amount (to the nearest dollar)"
                                                            value={this.state.phone}
                                                            maxLength={this.state.selectedOperator.operador === "directv" ? 12 : 10}
                                                            onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}

                                                        />
                                                    </Form.Group>

                                                    <div className="justify-content-md-center col-xs-12 mb-2"  >
                                                        <ListPrices clean={(flag) => this.setState({ clean: flag })} limpiar={this.state.clean} onClick={(i) => this.selectPrice(i)} prices={Prices} /><br />
                                                        {this.state.visible &&

                                                            <NumberFormat className="form-control mt-1"
                                                                thousandSeparator={true}
                                                                prefix='$ '
                                                                placeholder='$ Montos de Mil'
                                                                value={this.state.selectedPrice}
                                                                maxLength={9}
                                                                onChange={(event) => this.setState({ selectedPrice: parseInt(event.target.value.replace("$", "").replace(",", "")) })}
                                                            />
                                                        }
                                                    </div>

                                                </Col>

                                                <Col md={12} xs={12} lg={6}>

                                                    <h4 className={'defaultTitle'}>Resumen de Compra</h4>
                                                    <Card>
                                                        <Summary
                                                            operator={this.state.selectedOperator.operador}
                                                            price={this.state.selectedPrice}
                                                            phone={this.state.phone}
                                                        />
                                                    </Card>
                                                </Col>

                                            </Row>
                                        </Container>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        <Row>
                                            <Col>
                                                <Button size="lg" variant={'info'} onClick={this.cleanFile}>Cancelar</Button>
                                            </Col>

                                            <Col>
                                                <Button disabled={!this.state.phone || !this.state.selectedOperator.operador || !this.state.selectedPrice} size="lg" variant={'info'} onClick={this.sendData}>Pagar</Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </div>}

                        </div> :

                        <div className="container" style={{ marginTop: '5%', height: 633, width: '40%' }}>
                            <p>Esta pagina no esta disponible.</p>
                            <Button
                                style={{ backgroundColor: '#019CA1', border: 'none', color: 'white', fontSize: 25, borderWidth: 2, borderColor: '#07A2BA' }}
                                onClick={() => window.open(Url_home, '_self')}>  Volver al inicio</Button>

                            <Col md={8} xs={8} sm={8} lg={8}>
                                <Image
                                    style={{ marginTop: '30%', marginLeft: '20%', marginBottom: '10%' }}
                                    src={woman} />
                            </Col>
                        </div>



                    }


                </div>

                {/*<PayScreen visible={this.state.pay} closemodal={(flag)=>this.setState({pay:flag})} product={this.state.product}/> */}
                {this.state.pay && <PayScreen
                    visible={this.state.pay}
                    closeModal={(flag, data) => {
                        this.setState({ pay: flag });
                        this.closeModal(flag, data)
                    }}
                    product={this.state.product} />}

            </>
        )
    }
}


const Prices = [
    1000,
    2000,
    3000,
    5000,
    10000,
    20000,
    'Otro valor'
]

export default Recargas;