import React, { useState, Fragment, useEffect } from "react";
import { /* Form, */ Button, Card, Row, Container, InputGroup, FormControl, } from "react-bootstrap";
import { Transaction } from "../../../../services/product.service";
import { FormatMoney, Capitalize } from "../../../../utilities/formats";
import { LoadingMovilGoSoat } from "../../../../shared/loadingMovilGo";
import { ModalNotificationTransactionSoat } from "../../../../shared/modalNotificationTransaction";
import { jsPdfGenerator } from "../../../../shared/pdfTransaction";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { idClienteFinal } from "../../../../utilities/odoo-config";

export const SendPolicy = (props) => {
  const { data, dataContact, productId, closeAction, loading, setLoading } = props;


  const [cellular, setCellular] = useState("");
  const [email, setEmail] = useState("");
  const [modalNotification, setModalNotificacion] = useState(false);
  const [response, setResponse] = useState([]);
  const [descuento,setDescuento]=useState({Amount:0 ,Percentage:0})
  const [iva, setIva] = useState(false);
  const [tipoCiud, setTipoCiud] = useState({ label: "-- Seleccione una opcion --", value: "" });
  const vehicle = data.soat.respuesta_vehiculo;
  const tarifa = data.soat.respuesta_tarifa;


  const ciudOption = [
    { label: "Gran Contribuyente", value: 1 },
    { label: "Autorretenedor", value: 2 },
    { label: "Agente de retencion Iva", value: 3 },
    { label: "Regimen simple de tributacion", value: 4 },
    { label: "No aplica", value: 5 },
    { label: "Contribuyente - Autorretenedor - Agente de Retencion Iva", value: 6, },
    { label: "Autorretenedor-Agente de Retencion Iva", value: 7 },
    { label: "Gran Contribuyente-Agente de Retencion Iva", value: 8 },
  ];


  const clearData = () => {
    setEmail("");
    setCellular("");
    setLoading(false);
  };



  const verifyData = () => {
    const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (cellular.trim() === "") {
      alert("Error" + ", El celular Este  es obligatorio");
      if (alert) {
        clearData();
      }
    } else if (cellular.length !== 10) {
      alert("Error" + " El celular es inválido");
      if (alert) {
        clearData();
      }
    } else if (email.trim() === "") {
      alert("Error" + ", El email es obligatorio");
      if (alert) {
        clearData();
      }
    } else if (!emailValid) {
      alert("Error" + ", El email es  inválido");
      if (alert) {
        clearData();
      }
    } else {
      sendData(true);
    }
  };

  const sendData =async (flag) => {
    //const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal")); 
    //

    if (flag) {
      setLoading(true);
      let numIva = "2";
      if (iva) {
        numIva = "1";
      }
      if (tipoCiud.value === "") {
        tipoCiud.value = 5;
      }

      const datee = props.date.split("-");
     
      const year = parseInt(datee[0]);
     

      const preparedData = {
        Contact: {
          Address: {
            CityId: dataContact.city,
            Name: dataContact.adress.replace(".", ""),
            StateId: dataContact.StateId,
          },
          CiiuId: dataContact.CiiuId,
          Cellular: cellular,
          DocumentNumber: dataContact.document,
          DocumentTypeId: dataContact.DocumentTypeId,
          Email: email.trim(),
          FirstName: Capitalize(dataContact.firstName.split(" ")[0] ? dataContact.firstName.split(" ")[0]:""),
          FirstName1: Capitalize(dataContact.firstName.split(" ")[1] ? dataContact.firstName.split(" ")[1]:""),
          LastName: Capitalize(dataContact.lastName.split(" ")[0] ? dataContact.lastName.split(" ")[0]:""),
          LastName1: Capitalize(dataContact.lastName.split(" ")[1] ? dataContact.lastName.split(" ")[1]:""),
          Phone: cellular,
        },
        
        FromValidateDate: year + "-" + datee[1] + "-" + datee[2],
        RegimenTypeId: numIva,
        Rutid: tipoCiud.value, //Preguntar
        SendPolicy: {
          Address: dataContact.city,
          Cellular: cellular,
          CityId: "17001",
          Email: email.trim(),
        },
        SystemSource: 10,
        ChangeEmisionType: 1,
        Vehicle: {
          BrandId: vehicle.BrandId,
          ChasisNumber: vehicle.ChasisNumber,
          CylinderCapacity: vehicle.CylinderCapacity,
          LoadCapacity: vehicle.LoadCapacity,
          MotorNumber: vehicle.MotorNumber,
          NumberPlate: vehicle.NumberPlate,
          PassengerCapacity: vehicle.PassengerCapacity,
          ServiceTypeId: vehicle.ServiceTypeId,
          VIN: vehicle.Vin,
          VehicleClassId: vehicle.VehicleClassId,
          VehicleClassMinistryId: vehicle.VehicleClassMinistryId,
          VehicleLineDescription: vehicle.VehicleLineDescription,
          VehicleLineId: vehicle.VehicleLineId,
          VehicleYear: vehicle.VehicleYear,
          VehicleBodyTypeId: vehicle.VehicleBodyTypeId,
          FuelTypeId: vehicle.FuelTypeId,
          ////
          EnginePowerTypeId: vehicle.EnginePowerTypeId,
        },
        Payments: [
          {
            MethodOfPaymentId: 1,
            ReferenceNumber1: null,
            ReferenceNumber2: null,
            PaymentAmount: tarifa.TotalWithDiscountAmount,
            Observation: null,
          },
        ],
      };     
      Transaction({ product_id: productId, atributes: preparedData, clienteexterno_user_id:idClienteFinal  }, navigateNext);
    }
  };

  const navigateNext = (flag, response) => {

    if (flag) {
      setResponse(response);
      setModalNotificacion(true);
      setLoading(false);
    } else {

      if (response.errores) {
        if (response.errores.observacion) {
          const error = () => toast.error("Error " + response.errores.observacion);
          error();
        
          if (error) {
            clearData();
          }
        } else {
          const error = () => toast.error("Error " + " No se pudo relizar la transaccion");
          error();
       
          if (error) {
            clearData();
          }
        }
      } else {
  
        const error = () => toast.error("Error " + " Se ha presentado un error de conexion");
        error();
   
        if (error) { clearData(); }
      }
    }
  };

  const closeModal = (flag) => {

    setModalNotificacion(flag);

    const data = [
      "Factura No: " + response.valida.id,
      "Fecha:" + response.valida.fecha,
      "No. Aprobación: " + response.valida.numero_aprobacion,
      "Producto: Soat",
      "Celular: " + cellular,
      "Cedula: " + dataContact.document,
      "Cliente: " + dataContact.firstName + ' ' + dataContact.lastName,  
      "Placa: " + vehicle.NumberPlate,
      "Valor: " + FormatMoney(tarifa.TotalWithDiscountAmount),
    ];

    jsPdfGenerator(data);
    closeAction(true);
  };

  useEffect(()=>{
    if(data.soat.respuesta_tarifa.Law2161Discount){
      setDescuento(data.soat.respuesta_tarifa.Law2161Discount)
    }
},[])

  return (
    <div style={{ backgroundColor: "white" }}>
      <div style={styles.container}>
        <Container><ToastContainer />
          <Row className="justify-content-md-center">
            <Card className="reloadsCard"
              style={{ minWidth: "95%", borderColor: "#078FA5"/* , align: "center" */ }}
            >
              <Card.Body style={{ align: "center" }}>
                <div style={{ margin: 5, borderWidth: 1, padding: 5, borderColor: "#078FA5", borderRadius: 10, }} >
                  <div style={{ borderBottomWidth: 1, backgroundColor: "#07A2BA", textAlign: "center", }} >
                    <p style={{ fontWeight: "bold" }}>Datos Compra</p>
                  </div>
                  <div style={{ borderBottomWidth: 1, textAlign: "center" }}>
                    <p style={styles.title}>Tarifa</p>
                    <hr style={{color:"#078FA5"}}/>
                  </div>

                  <div style={styles.rowContainer} className="row">
                    <div style={styles.colContainer}>
                      <p style={styles.title}>V Prima</p>
                      <p>{tarifa.InsurancePremium}</p>
                    </div>
                    <div style={styles.colContainer}>
                      <p style={styles.title}>Contribucion</p>
                      <p>{tarifa.InsuranceTaxFormatted}</p>
                    </div>
                    <div style={styles.colContainer}>
                      <p style={styles.title}>RUNT</p>
                      <p>{tarifa.InsuranceFineFormatted}</p>
                    </div>
                  </div>

                  <div style={styles.rowContainer2} className="row">
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>Total Soat</p>
                      <p>$ {tarifa.Total + tarifa.ElectricDiscount}</p>
                    </div>                    
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>T. Combustible</p>
                      <p>{vehicle.FuelTypeId}</p>
                    </div>
                  </div>

                  <div style={styles.rowContainer2} className="row">
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>Dto. ley 2161</p>
                      <p>{descuento.Amount }</p>
                    </div>
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>% Dto. ley 2161</p>
                      <p>{descuento.Percentage }%</p>
                    </div>
                  </div>

                  <div style={styles.rowContainer2} className="row">
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>Dto. por Gas</p>
                      <p>$ {tarifa.GasDiscount}</p>
                    </div>
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>% Dto. por Gas</p>
                      <p>{tarifa.PercentageGasDiscount}%</p>
                    </div>
                  </div>

                  <div style={styles.rowContainer2} className="row">
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>Total de Dto</p>
                      <p>$ {vehicle.TotalLawDiscount ? vehicle.TotalLawDiscount : 0}</p>
                    </div>
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>Total de Dto a Gas</p>
                      <p>{tarifa.GasDiscountFormatted}</p>
                    </div>
                  </div>

                  <div style={styles.rowContainer2} className="row">
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>Total con desct</p>
                      <p>{tarifa.TotalWithDiscountAmountFormatted}</p>
                    </div>
                    <div style={styles.colContainer2}>
                      <p style={styles.title}>Total todos desct</p>
                      <p>{tarifa.TotalLawDiscountFormatted}</p>
                    </div>
                  </div>

                  <div style={{ fontWeight: "bold", textAlign: "center" }}>
                    <input type="checkbox" checked={iva}
                      onChange={(e) => { setIva(e.target.checked); }}
                    />
                    <label> Responsable de iva</label>
                  </div>

                  {iva && (<div>
                    <Fragment>
                      <Select className="basic-single" classNamePrefix="select"
                        value={tipoCiud} isDisabled={false} isLoading={false}
                        isClearable={true} isRtl={false} isSearchable={true}
                        name="color"
                        options={ciudOption}
                        onChange={(value) => { setTipoCiud(value); }}
                      />
                    </Fragment>
                  </div>
                  )}
                </div>              
                <InputGroup className="mb-2">
                  <FormControl placeholder="Celular" maxLength={10}
                    arial-label="Amount (to the nearest dollar)"
                    value={cellular}
                    onChange={(e) => { setCellular(e.target.value.replace(/\D/, "")); }}
                  />
                </InputGroup>
                <InputGroup className="mb-2">
                  <FormControl placeholder="Email"
                    value={email}
                    onChange={(e) => { setEmail(e.target.value); }}
                  />
                </InputGroup>

                <div className="mb-2" style={{ align: "center", textAlign: "center" }} >
                  <Button onClick={verifyData}
                    style={{ backgroundColor: "#07A2BA", width: "50%" }}
                  > Comprar
                  </Button>
                </div>
                {modalNotification && (<ModalNotificationTransactionSoat
                  closeModal={(flag) => closeModal(flag)} type={"Soat"}
                  message={"Compra realizada exitosamente"}
                />
                )}
                {loading && <LoadingMovilGoSoat style={{ marginleft: "20%" }} />}
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const styles = {
  title: {
    fontWeight: "bold",
  },
  container: {
    paddingHorizontal: 10,
    maxHeight: "100%",

    /* backgroundColor: '#078FA5' */
  },
  titleSummary: {
    alignSelf: "center",
    fontWeight: "bold",
    fontSize: 18,
    color: "#07A2BA",
  },
  colContainer: {
    width: "32%",
  },
  colContainer2: {
    width: "50%",
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 5,
  },
  rowContainer2: {
    flexDirection: "row",
    justifyContent: "center",
    marginVertical: 25,
  },
  loading: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
  },
};
