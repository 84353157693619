import React from 'react';
import { FormatMoney } from '../../../utilities/formats';

export const Summary = (props) => {
    const { phone, operator, price, idClient } = props

    return (
        <div> 
            <h3 className={'defaultTitle'}>Operador:</h3>
            <h4>{operator}</h4>
            <h3 className={'defaultTitle'}>Cédula:</h3>
            <h4>{idClient}</h4>
            <h3 className={'defaultTitle'}>Celular:</h3>
            <h4>{phone}</h4>
            <h3 className={'defaultTitle'}>Valor de recarga:</h3>
            <h4>{FormatMoney(price)}</h4>
        </div>
    );
}
