
import React, {Component} from 'react';
//import CarruselHome from './components/carruselhome';
import {Button,Image, Row, Col,Card,Modal} from 'react-bootstrap';
import fondo from '../../assets/home/fondo.png';
import fondo2 from '../../assets/home/fondo2.png'
import men from '../../assets/home/img1.png';
import personaje from '../../assets/home/personaje.png'
import { Register } from '../../shared/header/components/register';
import incon from '../../assets/home/icono.png'
import woman from '../../assets/home/img2.png'
import rifa from '../../assets/home/RIFA.jpg'
import Header from '../../shared/header/header';
import { idClienteFinal, idClienteFinalCardoso } from '../../utilities/odoo-config';
import CarruselHome from '../home/components/carruselhome';
import cardoso from '../../assets/cardoso/Sorteo-cardoso.jpg'
import HeaderCardoso from '../../shared/header/header-cardoso';
import cardosostyle from '../cardoso/cardoso.css';
import fondo3 from '../../assets/home/portada2.png';
import fondo4 from '../../assets/home/portadamovil.png';

  export default class HomeCardoso extends Component{
    constructor(props) {
        super(props)
        this.state={
                showRegister:false ,
                deslogear:false,
                showcardoso:false,
                idHeader: localStorage.getItem('idDelPadre'),
        }
      }
      componentDidMount=()=>{
        this.setState({showcardoso:true})
        //console.log("componentDidMount props",this.props)
      }
      render(){
        return(<>
                
                <div>
                    <div className='header' style={{height:2800}} >
                        <div className='fondo1' style={{backgroundImage: `url(${fondo3})`,height:900}}>
                        {<div className='fondo2' >
                            </div>}
                            <div className='verde1' style={{position:'absolute',index:1,display:'inlineBlock',marginTop:50}}>
                                <HeaderCardoso  showSeller={false} 
                                                idPadre={idClienteFinalCardoso} 
                                                deslogear={this.state.deslogear} 
                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />
                                
                            </div>
                            
                            <Row >
                                <Col md={4} xs={4} sm={4} lg={4} >
                                    <div style={{marginTop:100,marginLeft:'30%',}}>
                                        {/* <p className='texto1' style={{ textAlign:'left', color:'#0D606E', fontSize: 70, left:80}}>
                                            
                                            Tener en tu celular todo lo que necesitas es mas sencillo
                                        </p>
                                        <p className='texto2' style={{ textAlign:'left', color:'white', fontSize: 40, left:20 }}>
                                            
                                            Encuentra todos los productos que utilizas en tu dispositivo
                                        </p>                                      */}
                                    </div>
                                    
                                </Col>
                                <Col md={8} xs={8} sm={8} lg={8}>                              
                                {/* <Image                             
                                        style={{marginTop:'10%' ,marginLeft:'20%'}} 
                                        src={men}            
                                    />  */}
                                </Col>
                            </Row>


                        </div>
                       
                        <div  style={{backgroundImage: `url(${fondo2})`,width:'100%'}}>   
                    <div className='fondo4' ></div>
                        {/* <Image       className='fondo4'                       
                                                 style={{ width: '10%',height:50,display:'none'}} 
                                                 src={fondo4}            
                                            />   */}
                                                            
                            <div style={{height:'15%'}}>
                                <Row>
                                    <Col md={4} xs={4} sm={4} lg={4} >
                                        <div style={{marginTop:'10%',marginLeft:'20%'}}>
                                            
                                            <p className='texto1'  style={{ textAlign:'left', color:'#0D606E', fontSize: 70, left:80}}>
                                                Nuestros productos
                                            </p>
                                            
                                             
                                            <p className='texto1'  style={{ textAlign:'left', color:'#0D606E', fontSize: 40, left:80 }}>
                                                Un lugar en donde puedes encontrar todos los servicios de mas utilizas
                                            </p>
                                        
                                        </div>
                                            
                                        
                                        
                                    </Col>
                                    <Col md={8} xs={8} sm={8} lg={8}>
                                        <Card className='texto2' style={{backgroundColor:'white',top:30,width:'80%',left:120}}>
                                            <Card.Body  style={{borderRadius:10,borderWidth:50}}>
                                                <div style={{position:'relative',width:'30%',top:100}}>
                                                    <p className='texto3' style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        RECARGAS Y PAQUETES
                                                    </p>
                                                    <p className='texto3' style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        PINES                                            
                                                    </p>
                                                    <p className='texto3' style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        APUESTAS DEPORTIVAS
                                                    </p>
                                                    <p className='texto3' style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        CERTIFICADOS DE TRADICION
                                                    </p>
                                                    <p className='texto3' style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        RIFAS
                                                    </p>
                                                </div>
                                                <div style={{width:'70%',left:280,position:'relative',bottom:150}}>
                                                    <Image                             
                                                        style={{ width: '40%',height:'50%',borderLeftWidth:8,borderLeftColor:'#0D606E',borderLeftStyle:'solid'}} 
                                                        src={personaje}            
                                                    /> 
                                                </div>
                                                
                                            </Card.Body>
                                        </Card>  
                                    
                                    </Col>  
                                </Row>
                                <div className='linea' style={{width:'80%',position:'relative',left:150,marginTop:70,borderBottomStyle:'solid',borderBottomWidth:10,borderBottomColor:'#0D606E'}}>

                                </div>
                            </div>
                            <div style={{height:'85%'}}>
                                    <div  style={{width:'100%'}}>
                                    <p className='sencillo' style={{ textAlign:'center', color:'#0D606E', fontSize: 70,}}>
                                        Es muy sencillo
                                    </p>
                                </div> 
                                <div style={{position:'relative',marginTop:'2%',marginLeft:'10%'}}>
                                    <div  style={{width:'40%'}}>
                                        <Row className='orientation'>
                                            <Image  className='chulo'                        
                                                style={{ width: '10%',height:50}} 
                                                src={incon}            
                                            />
                                            <p  className='corto1'  style={{ textAlign:'left', color:'#0D606E', fontSize: 30,width:'90%'}}>
                                                Ingresa desde tu computador y registrate con tus datos personajes e informarcion.
                                            </p> 
                                        </Row>                                        
                                    </div>
                                    <div className='linea2' style={{borderTopStyle:'solid',borderTopWidth:5,borderTopColor:'#0D606E',width:'40%',marginTop:30}}>
                                        <Row >
                                            <div className='orientation'>
                                            <Image   className='chulo2'                            
                                                style={{ width: '10%',height:50}} 
                                                src={incon}            
                                            />
                                            <p  className='corto2'  style={{ textAlign:'left', color:'#0D606E', fontSize: 30,width:'90%'}}>
                                                Accede servicio o producto que necesita.
                                            </p> 
                                            </div>
                                        </Row>                                        
                                    </div>
                                    <div className='linea3' style={{borderTopStyle:'solid',borderTopWidth:5,borderTopColor:'#0D606E',width:'40%',marginTop:30}}>
                                        <Row >
                                            <div className='orientation' >
                                            <Image   className='chulo3'                              
                                                style={{ width: '10%',height:50}} 
                                                src={incon}            
                                            />
                                            <p className='corto3' style={{ textAlign:'left', color:'#0D606E', fontSize: 30,width:'90%'}}>
                                                Seleciona el medio de pago.
                                            </p> 
                                            </div>
                                        </Row>                                        
                                    </div>
                                </div>
                                <div className='mujer' style={{position:'relative',marginTop:'-20%',marginLeft:'40%'}}>
                                    <Image                             
                                        style={{ width:'100%',height:'100%'}} 
                                        src={woman}            
                                    />
                                </div>
                                <div style={{position:'relative',marginTop:'-20%',marginLeft:'75%'}}>
                                    <div className='texto-app' >
                                        <Row >
                                            <div className='orientation' >
                                            <p className='app' style={{ textAlign:'left', color:'#0D606E' ,fontSize: 50}}>
                                                Nuestra App
                                            </p>
                                            <p className='ingresa' style={{ textAlign:'left' ,color:'#0D606E', fontSize: 30}}>
                                                Ingresa desde tu computador y registrate con tus datos personajes e informarcion
                                            </p> 
                                            </div>
                                        </Row>                                        
                                    </div>
                                </div>
                            </div> 
                        </div> 
                        
                        
                    </div> 
                </div>
                <Modal onHide={()=>this.setState({show:false})}  style={{width:'100%'}}>
                    <Modal.Body className='fondo' style={{width:500, marginLeft:-420,height:700}}>
                        <Button onClick={()=>this.setState({show:false})} 
                            style={{
                                height: '100%',
                                marginLeft:'34%',
                                marginTop:'-3%', 
                                width: 892,
                                backgroundColor:'transparent',
                                borderWidth:'transparent'}}>
                            
                        </Button>
                            
                    </Modal.Body>
                </Modal>  
                
                <Modal show={this.state.showcardoso} onHide={()=>this.setState({showcardoso:false})} className='modal-foto'  style={{width:'100%'}}>
                    <Modal.Body style={{width:100, marginLeft:-420,height:100, backgroundColor:'transparent',marginTop:'-6%'}}>
                        <Button onClick={()=>this.setState({showcardoso:false})} className='fondo'
                            style={{
                                height: '1%',
                                marginLeft:'239%',
                                marginTop:'-3%', 
                                width: '1%',
                                backgroundColor:'transparent',
                                borderWidth:'transparent'}}>
                            <Card.Img className='cardoso1' src={cardoso}  
                                style={{ 
                                    height: 817, 
                                    width: 900,
                                    marginLeft:'11%',
                                    marginTop:'-3%'}} alt="Card image" />
                        </Button>
                            
                    </Modal.Body>
                </Modal>
                
           </>)}
    }

