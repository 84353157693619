import React, { PureComponent,Fragment } from 'react';
import { LoadingMovilGo } from '../../loadingMovilGo';
import Select from "react-select";
import { Button, Modal,Form } from "react-bootstrap";
import { payMenPSE,getBanks,getCities, } from '../../../services/pay.service';
import { ModalNotificationTransaction } from '../../modalNotificationTransaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import { parseToString } from '../../../utilities/reactiva';
import { ManagmentErrors } from '../../managmentErrors';
import { ToastContainer, toast } from 'react-toastify';
export class PseScreen extends PureComponent {
    constructor() {
        super();
        this.state = {
            buttonsPay: [],            
            
            /* personTypeSelected: {value:'N',label:'Natural'}, 
            fullName:'alfonso delgado santofinio',
            contactPhone:'3208503506',
            dniNumber:'75098380',
            documentType:{value:'CC',label:'Cedula'},
            street1:'la empresa',
            street2:'la casa',
            email:'soporte@reactiva.com.co', 
            postalCode:'170001',
            bank:{value:"1007",label:'BANCOLOMBIA QA'},
            selectedCity:{value:17001,label:"MANIZALES"},
            selectedDepartment:{value:17,label:"CALDAS"}, */ 
            personTypeSelected: {value:'-1',label:'Tipo de Persona'},
            fullName:'',
            contactPhone:'',
            dniNumber:'',
            documentType:{value:-1,label:'Tipo de Documento'},             
            street1:'',
            street2:'',
            city:'',
            email:'',            
            postalCode:'',
            bank:{value:-1,label:'Seleccione un Banco'},
            selectedCity:{value:-1,label:'Selecion una Ciudad'},
            selectedDepartment:{value:-1,label:'Selecion un Departamento'},

            state:'',            
            bankOptions:[],
            cityOptions:[],
            departmentsOptions:[],
            documentOptions:[
            {value:'CC',label:'Cedula'},
            {value:'CE',label:'Cedula Extranjera'},
            {value:'NIT',label:'Número de Identificación Tributaria'},
            {value:'TI',label:'Tarjeta de Identidad'},
            {value:'PP',label:'Pasaporte'},
            {value:'IDC',label:'Identificador único De Cliente'},
            {value:'CEL',label:'Línea Móvil'},
            {value:'RC',label:'Registro Civil'},
            {value:'DE',label:'Documento de Identificación Extranjero'}],
            personTypeOptions:[
                {value:'N',label:'Natural'},
                {value:'J',label:'Juridica'}
            ],
            loading:false,
            modalNotificacion:false,
            ip:1,
            date:this.getDate(),
            errorFullName:'',
            errorContactPhone:'',
            errorDniNumber:'',
            errorStreet1:'',
            errorStreet2:'',
            errorCity:false,
            errorDepartment:false,
            errorPostalCode:'',
            errorBank:false,
            errorDocumentType:false,
            errorPersonType:false,
            mensaje:"Este campo es obligatorio",
            show:false
            

        }
    }
    componentDidMount=()=>{
        //console.log("this.props del pseScreen",this.props)
        this.setState({loading:true})
        this.loadData()
    }
    getDate=()=>{
        const d = new Date();
            let month=(d.getMonth()+1)
            let day=d.getDate()
            let horas=d.getHours()
            let min=d.getMinutes()
            let seg=d.getSeconds()           
            let fecha= d.getFullYear()+"-"+month+"-"+day
            let hora =horas + ':' + min + ':' + seg;
            let date = fecha+" "+hora
            return date
        
        }
    loadData=()=>{
            
            getBanks(this.props.userLogin,async(flag,res)=>{
                //console.log("getbanks flags",flag)
                //console.log("getbanks res",res)
                
                if(flag){
                    let bankOptions=[]
                    let str = JSON.stringify(res).replace(/\\/g, '');
                    str = str.replace('""""', '""');
                    str = str.replace('"{', '{');
                    str = str.replace('}"', '}');
                    str = str.replace('""""', '""');
                    str = str.replace('"{', '{');
                    str = str.replace('}"', '}');
                    const json = JSON.parse(str);
                    ////console.log("getbanks res",json.banks)
                    json.banks.forEach((element)=>{
                        bankOptions.push({value:element.pseCode,label:element.description})
                        bankOptions.sort((a, b) => {
                            return a.label < b.label ? -1 : 1;
                          });
    
                    })
                    this.setState({bankOptions})
                    getCities(this.props.userLogin,async(flag,res)=>{
                        //console.log("gecities flag",flag)
                        //console.log("gecities res",res)
                        let cityOptions=[]
                        let departmentsOptions=[]
    
                        ////console.log("gesticies res.departamentos",res.departamentos)
                        ////console.log("gesticies res.cuidades",res.cuidades)
                        
                        if(flag){
                            res.departamentos.forEach((element)=>{
                                departmentsOptions.push({value:element.stateId,label:element.nombre})
                                departmentsOptions.sort((a, b) => {
                                    return a.label < b.label ? -1 : 1;
                                  });
                            })
                            res.cuidades.forEach((element)=>{
                                cityOptions.push({label: element.cityName,value: element.cityId,stateId: element.stateId,})
                                cityOptions.sort((a, b) => {
                                    return a.label < b.label ? -1 : 1;
                                  });
                            })
                            
                            this.setState({departmentsOptions,cities:cityOptions ,loading:false,show:true})
                            
                        }else{
                            this.setState({loading:false})
                            alert("Error", "No se pudo realizar la consulta");
                            this.props.closeModal(false)
                        }
                    })
                }else{
                    this.setState({loading:false})
                    ////console.log("entro al else",res.data.arguments[0])
                   /*  if(res.data.arguments[0]==="Session expired"){
                        //console.log("esto ingreso al Session expired")
                        //newLogin(this.loadData())
    
                    }else{ */
                        alert("Error", "No se pudo realizar la consulta");
                        this.props.closeModal(false)
    
                   // }
                    
                }
            });
        }
    senToOdoo = () =>{
        if(this.verifyField(true,true,true,true,true,true,true,true,true,true,true,true)){
            //console.log("product de senToOdoo tu odoo",this.props)
             this.setState({loading:true})
                    const product={        
                        "tx_value": this.props.product.atributes.precio ? this.props.product.atributes.precio:this.props.product.atributes.valorPagar,
                        "currency": "COP",
                        "fullName": this.state.fullName, 
                        "emailAddress": this.state.email, 
                        "contactPhone": this.state.contactPhone,
                        "dniNumber": this.state.dniNumber,
                        "street1": this.state.street1,
                        "street2": this.state.street2,
                        "city": this.state.selectedCity.label, 
                        "state": this.state.selectedDepartment.label,
                        "country": "CO",
                        "postalCode": this.state.postalCode,
                        "phone": this.state.contactPhone,
                        "FINANCIAL_INSTITUTION_CODE": this.state.bank.value,
                        "USER_TYPE": this.state.personTypeSelected.value,
                        "PSE_REFERENCE2": this.state.documentType.value,
                        "PSE_REFERENCE3":this.state.dniNumber,
                        "paymentMethod": "PSE",
                        "paymentCountry": "CO",
                        "ipAddress": this.state.ip,
                        "userAgent": "MovilGo APP"+this.state.ip +" "+this.state.date,
                        "product":this.props.product                
                    }
                    console.log("prodcut de salida jejej",product)   
                     payMenPSE(product,this.props.userLogin,async(flag,res)=>{
                        this.setState({loading:false})
                        console.log("flag pay",flag)
                        console.log("res pay",res)
                        let response=JSON.parse(res)
                        
                        
                            if(flag){
                                if(response[0]){
                                    if(response[0].errores){
                                        if(response[0].errores.error){
                                            alert(response[0].errores)
                                        }else{
                                          alert(response[0].errores)  
                                        }
                                        
                                    }else if(response[0].error){
                                        alert("Se presento un error al realizar la compra")
                                    }
    
                                }else if(response.errores){
                                    if(response.errores.observacion){
                                        alert(response.errores.observacion)
                                    }else if(response.errores.error){
                                        alert(response.errores.error)
                                    }
                                }else if(response.error){
                                    alert(response.error)
                                }else{
                                    //this.setState({modalNotificacion:true,response:res})

                                    this.finishPay(false,response)
                                }
                            
                            }else{
                                const errorMessage = ManagmentErrors(res.errores.id)
                                const error = () => toast.error(errorMessage);
                                error();
                                //alert("Error", "Si el error persiste comunicate con Movilgo");
                                
                            }
    
                    }) 
        }
            
    
    }
    verifyField=(fullName,email,phone,street,street2,dni,postalCode,documentType,personType,banck,deparment,city)=>{
        
        
        if(fullName){
            
            if(this.state.fullName===""){           
                this.setState({errorFullName:true})
                return false
            }else{            
                this.setState({errorFullName:''})
                
            }
        }
    
        if(email){
            if(!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                this.setState({errorEmail:'Formato del correo incorrecto'})
                return false
            }else{
                this.setState({errorEmail:''})
                
            }
        }
        if(phone){
            if(this.state.contactPhone===""){
                this.setState({errorContactPhone:true})
                return false
            }else if(this.state.contactPhone.length<7){
                this.setState({errorContactPhone:'El numero es demasiado corto'})
                return false
            }else {
                this.setState({errorContactPhone:''})
                
            }
        }
        if(street){
            if(this.state.street1===""){
                this.setState({errorStreet1:true})
                return false
            }else{
                this.setState({errorStreet1:''})
                
            }
        }
        if(dni){
            if(this.state.dniNumber===""){
                this.setState({errorDniNumber:true})
                return false
            }else{
                this.setState({errorDniNumber:''})
                
            }
        }
        if(postalCode){
            if(this.state.postalCode==""){
                this.setState({errorPostalCode:true})
                return false
            }else{
                this.setState({errorPostalCode:''})
                
            }
        }
        if(documentType){
            if(this.state.documentType.value==="-1"){            
                this.setState({errorDocumentType:true})
                return false
            }else{
                this.setState({errorDocumentType:false})
                
            }
        }
        if(personType){        
            if(this.state.personTypeSelected.value===-1){
                this.setState({errorPersonType:true})
                return false
            }else{
                this.setState({errorPersonType:false})
                
            }
        }
        if(banck){        
            if(this.state.bank.value===-1){           
                this.setState({errorBank:true})
            return false
            }else{
                this.setState({errorBank:false})
                
            }
        }
        if(deparment){
            if(this.state.selectedDepartment.value===-1){            
                this.setState({errorDepartment:true})
                return false
            }else{
                this.setState({errorDepartment:false})
                
            }
        }
        if(city){
            if(this.state.selectedCity.value===-1){
                this.setState({errorCity:true})
                return false
            }else{
                this.setState({errorCity:false})
                
            }
        }
    
        if(street2){
            if(this.state.street2===""){
                this.setState({errorStreet2:true})
                return false
            }else{
                this.setState({errorStreet2:false})
                
            }
        }
    
        return true
    
    }
   
     
    selectedDepartment=(department)=>{
        ////console.log("department",department)
        this.setState({cityOptions:[]})
        let cityOptions=[]
        this.state.cities.forEach((element)=>{            
            if(parseInt(department.value)===parseInt(element.stateId)){
                //console.log(element)
                cityOptions.push({label: element.label,value: element.value,stateId: element.stateId,})
                cityOptions.sort((a, b) => {
                    return a.label < b.label ? -1 : 1;
                    });
            }
        })
        ////console.log(cityOptions)
        this.setState({cityOptions})
        
    } 
    finishPay=async(flag,response)=>{
        const json= await parseToString(response)
        if(!flag){
            this.props.closeModal(flag,false,true)
            //console.log("finishPay",json.transactionResponse.extraParameters.BANK_URL)
            console.log("json del pse",json )
            window.open(json.transactionResponse.extraParameters.BANK_URL);
            /* window.location.replace('http://45.79.43.96:8099/Rifas/') */
            window.close();
            
        }

    }
    
    render(){
        return(
            <>
            <ToastContainer autoClose={2000} />
            <Modal
                style={{ opacity: 1 }}
                size='md'
                show={this.props.visible}/* this.props.visible */
            >
                <Modal.Header style={{ backgroundColor: '#0D606E' ,alignSelf:'center',width:'100%'}} >
                    <Modal.Title className={'defaultTitle'} style={{ color: 'white' }}>Pago por Pse</Modal.Title>
                    <Button onClick={() => {this.props.closeModal(false,false)}} type="button" style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none' }}>
                        <FontAwesomeIcon color={'white'} className="mr-2 align-self-center" icon={faTimes} />
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: '100%', height: '100%' }}>
                    <div style={{width:'100%',height:900}}>
                        {this.state.show&&<div style={{ opacity: 1 }}>
                            <Form.Group className={'defaultTitle'}>
                                <Form.Control 
                                    placeholder="Nombre Completo" 
                                    onBlur={() => this.verifyField(true,false,false,false,false,false,false,false,false,false)} 
                                    value={this.state.fullName} 
                                    onChange={(event) => this.setState({fullName:event.target.value})} 
                                    type="text" 
                                    />
                                <br/>    
                                {this.state.errorFullName && <p style={{ fontSize: 12, color: 'red' }}> {this.state.mensaje}<br></br></p>}
                                                                    
                                <Form.Control 
                                    placeholder="Email"
                                    onBlur={() => this.verifyField(false,true,false,false,false,false,false,false,false,false)} 
                                    value={this.state.email} 
                                    onChange={(event) => this.setState({email:event.target.value})} 
                                    type="email"
                                    />
                                <br/>    
                                {this.state.errorEmail && <p style={{ fontSize: 12, color: 'red' }}>Correo es obligatirio o esta en un formato invalido   <br></br></p>}
                                
                                <Form.Control 
                                    placeholder="Celular"
                                    onBlur={() => this.verifyField(false,false,true,false,false,false,false,false,false,false)} 
                                    value={this.state.contactPhone} 
                                    onChange={(event) => this.setState({contactPhone:event.target.value.replace(/\D/, '') })} 
                                    arial-label="Amount (to the nearest dollar)"
                                    maxLength={10} 
                                    />
                                <br/>
                                {this.state.errorContactPhone && <p style={{ fontSize: 12, color: 'red' }}>  {this.state.mensaje}<br></br></p>}
                                
                                <Form.Control 
                                    placeholder="Direccion"
                                    onBlur={() => this.verifyField(false,false,false,true,false,false,false,false,false,false)} 
                                    value={this.state.street1} 
                                    onChange={(event) => this.setState({street1:event.target.value})} 
                                    type="text" 
                                    />
                                <br/>
                                {this.state.errorStreet1 && <p style={{ fontSize: 12, color: 'red' }}>{this.state.mensaje} <br></br></p>}
                                <Form.Control 
                                    placeholder="Otra Direccion"
                                    onBlur={() => this.verifyField(false,false,false,false,true,false,false,false,false,false)} 
                                    value={this.state.street2} 
                                    onChange={(event) => this.setState({street2:event.target.value})} 
                                    type="text" 
                                    />
                                <br/>
                                {this.state.errorStreet2 && <p style={{ fontSize: 12, color: 'red' }}>Correo es obligatiro o esta en un formato invalido <br></br></p>}
                                <Fragment>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"                                    
                                        value={this.state.documentType}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="color"
                                        options={this.state.documentOptions}
                                        onChange={(documentType) => { this.setState({documentType})}}
                                    />
                                </Fragment>
                                <br/>

                                {this.state.errorDocumentType && <p style={{ fontSize: 12, color: 'red' }}>{this.state.mensaje} <br></br></p>}
                                
                                <Form.Control 
                                    placeholder="Numero De Documento"
                                    arial-label="Amount (to the nearest dollar)"
                                    onBlur={() => this.verifyField(false,false,false,false,false,true,false,false,false,false)} 
                                    value={this.state.dniNumber} 
                                    onChange={(event) => this.setState({dniNumber:event.target.value.replace(/\D/, '') })} 
                                    maxLength={10} 
                                    />
                                <br/>    
                                {this.state.errorDniNumber && <p style={{ fontSize: 12, color: 'red' }}> {this.state.mensaje} <br></br></p>}
                                <Form.Control 
                                    placeholder="Codigo Postal"
                                    arial-label="Amount (to the nearest dollar)"
                                    onBlur={() => this.verifyField(false,false,false,false,false,false,true,false,false,false)} 
                                    value={this.state.postalCode} 
                                    maxLength={6}
                                    onChange={(event) => this.setState({postalCode:event.target.value.replace(/\D/, '') })} 
                                    
                                    />
                                <br/>    
                                {this.state.errorPostalCode && <p style={{ fontSize: 12, color: 'red' }}> {this.state.mensaje} <br></br></p>}
                                <Fragment>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"                                    
                                        value={this.state.personTypeSelected}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="color"
                                        options={this.state.personTypeOptions}
                                        onChange={(personTypeSelected) => { this.setState({personTypeSelected})}}
                                    />
                                </Fragment>
                                <br/>
                                {this.state.errorPersonType&& <p style={{ fontSize: 12, color: 'red' }}>{this.state.mensaje}<br></br></p>}
                                <Fragment>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"                                    
                                        value={this.state.bank}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="color"
                                        options={this.state.bankOptions}
                                        onChange={(bank) => { this.setState({bank})}}
                                    />
                                </Fragment>
                                <br/>
                                {this.state.errorBank&& <p style={{ fontSize: 12, color: 'red' }}>{this.state.mensaje}<br></br></p>}
                                <Fragment>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"                                    
                                        value={this.state.selectedDepartment}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="color"
                                        options={this.state.departmentsOptions}
                                        onChange={(selectedDepartment) => { this.setState({selectedDepartment});
                                                                            this.selectedDepartment(selectedDepartment)
                                                                            }}
                                    />
                                </Fragment>
                                <br/>
                                {this.state.errorDepartment&& <p style={{ fontSize: 12, color: 'red' }}>{this.state.mensaje}<br></br></p>}
                                <Fragment>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"                                    
                                        value={this.state.selectedCity}
                                        isDisabled={false}
                                        isLoading={false}
                                        isClearable={true}
                                        isRtl={false}
                                        isSearchable={true}
                                        name="color"
                                        options={this.state.cityOptions}
                                        onChange={(selectedCity) => { this.setState({selectedCity});}}
                                    />
                                </Fragment>
                                <br/>
                                {this.state.errorCity&& <p style={{ fontSize: 12, color: 'red' }}>{this.state.mensaje}<br></br></p>}
                                
                                </Form.Group>
                        </div>} 
                    </div>
                      
                    </Form>
                    

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {this.props.closeModal(false)}}>
                        Cancelar
                        </Button>
                    <Button
                        variant="info"
                        onClick={() => this.senToOdoo()}
                        /* disabled={!identificacion || !nombre || !apellido || !direccion || !celular || (!correo.test(email) ? email : "")} */
                    >
                        Comprar
                    </Button>
                </Modal.Footer>
                <div style={{backgroundColor:'red',marginLeft:130}}>
                  {this.state.loading && <LoadingMovilGo />}  
                </div>                                                            
                
             </Modal>
             {/* {this.state.modalNotificacion && <ModalNotificationTransaction 
                                                closeModal={(flag,dataResponse) => {this.finishPay(flag,dataResponse)
                                                    this.setState({modalNotificacion:flag})}} 
                                                reponse={this.state.response}    
                                                message={"Su Transaccion fue exitosa"} />} */}
                 
                 
             </>
            
        )
    }
}