import React from 'react';
import {Image} from 'react-bootstrap';

export const ButtonImage = (props) =>{
    const {image, onClick}= props;

    return(
        <>
        <style>
            {`                
                .telephoneOperatorButton{
                    color: white;
                    text-align: center;
                    transition: all 0.4s ease 0s;
                }
                .telephoneOperatorButton:hover{
                    transform: translateY(-7px);
                    cursor: pointer;
                }
            `}
        </style>
        <Image 
            className='telephoneOperatorButton'
            style={styles.container} 
            onClick={onClick} 
            src={image}            
        /> 
        </>
    );
}

export const ButtonImagePay = (props) =>{
    const {image, onClick}= props;
    //console.log("esto entro al ButtonImagePay")
    return(
        <>
        <style>
            {`                
                .telephoneOperatorButton{
                    color: white;
                    text-align: center;
                    transition: all 0.4s ease 0s;
                }
                .telephoneOperatorButton:hover{
                    transform: translateY(-7px);
                    cursor: pointer;
                }
            `}
        </style>
        <Image 
            className='telephoneOperatorButton'
            style={styles.containerPay} 
            onClick={onClick} 
            src={image}            
        /> 
        </>
    );
}
const styles={
    container: {
        height: '90px',
        width: '90px',
        margin: '15px'
    },
    containerPay: {
        alignItems: "center",
        margin: '10%',
        aspectRatio: 1,
        marginVertical: 0,
        width:'30%',
        height:'20%',
        justifyContent: "space-around"
    }
}