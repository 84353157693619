import React from 'react';
import { Button,  Modal } from 'react-bootstrap';

export const LinkSoat = (props) => {    
    return (
        <Modal centered show={true} backdrop="static" keyboard={false} >
            <Modal.Header  >
                <Modal.Title style={{ color: '#07A2BA' }} className={'defaultTitle'}>{props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div style={{ textAlign: 'center' }}>                  
                    <a href={props.response} style={{ color: '#07A2BA' }} rel='noreferrer'  >
                        {props.msj}
                        {/* Presiona aquí para descargar tu SOAT. <br></br>Recuerda usar el número de placa en Mayúscula para abrirlo */}
                    </a>                    
                </div>
            </Modal.Body >
            <Modal.Footer >
                <Button variant='info' onClick={() => props.closeModal()}>Aceptar</Button>
                {/* <Button variant='info' onClick={() => props.closeModal(false)}>Aceptar</Button> */}
            </Modal.Footer>
        </Modal>
    )

}