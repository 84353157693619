import React, { PureComponent } from 'react';
import {Image, Col} from 'react-bootstrap';
import woman from '../../assets/home/img4.png';
import { Button, Card, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../../shared/header/header';
import HeaderCardoso from '../../shared/header/header-cardoso';
import { Url_home } from '../../utilities/odoo-config';
export class Soat extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            deslogear: false,
            idHeader: localStorage.getItem('idDelPadre')
        }

    }
    componentDidMount = () => {

    }

    render() {
        return (
            <>
                <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
                {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>
                <Container style={{ marginTop: '5%', height: 633, width: '40%' }}>

                    {localStorage.getItem('idDelPadre') !== "movilgo" ?
                        <div>
                            <Row className="justify-content-md-center">
                                <Card className='reloadsCard' style={{ minWidth: '40%' }}>
                                    <Card.Body>
                                        <div>
                                            <h3 style={{ marginLeft: '30%' }}>¿Que desea realizar?</h3>
                                        </div>
                                        <Button
                                            style={{ marginLeft: '30%' }}
                                            variant={'info'}
                                            className="mt-2"
                                        >
                                            <Link to='/Soat/Buscar' style={{ color: 'white' }}>Buscar</Link>
                                        </Button>
                                        <Button
                                            style={{ marginLeft: '10%' }}
                                            variant={'info'}
                                            className="mt-2"
                                        >
                                            <Link to='/Soat/Comprar' style={{ color: 'white' }}>Comprar</Link>
                                        </Button>

                                    </Card.Body>
                                </Card>
                            </Row>

                        </div> :


                        <div className="container" style={{ height: 633, opacity: this.state.opacity, marginTop: 20, alignContent: 'center' }}>
                            <p>Esta pagina no esta disponible.</p>
                            <Button
                                style={{ backgroundColor: '#019CA1', border: 'none', color: 'white', fontSize: 25, borderWidth: 2, borderColor: '#07A2BA' }}
                                onClick={() => window.open(Url_home, '_self')}>  Volver al inicio</Button>

                            <Col md={8} xs={8} sm={8} lg={8}>
                                <Image
                                    style={{ marginTop: '30%', marginLeft: '20%', marginBottom: '10%' }}
                                    src={woman} />
                            </Col>
                        </div>

                    }
                </Container>
            </>

        )
    }
}
