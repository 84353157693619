import React, {Component} from 'react';
import { faMobile} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button} from 'react-bootstrap'
import {Col,Row,Image} from 'react-bootstrap';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import {faFacebookF,faInstagram} from '@fortawesome/free-brands-svg-icons'
import movilgo from '../assets/header/movilgo.png';
import facebook from '../assets/footer/FB.png'
import instagram from '../assets/footer/IN.png'
import go from '../assets/footer/GO.png'
import whastapp from '../assets/footer/WA.png'
import {faEnvelope  } from '@fortawesome/free-solid-svg-icons';
import apk from '../utilities/apk/Cliente.apk';
import footer from '../assets/footer/footer.css';
export default class Footer extends Component{
    constructor(props) {
        super(props)
        this.state={
                     
        }
      }
      render(){
        return(
            <div style={{backgroundColor:'#0D606E'}}>
                <Row>
                    
                        <Image   className='movilgo'                          
                            style={{width:'20%',height:'30%',marginLeft:'40%',marginBottom:'2%' }} 
                            src={movilgo}            
                        /> 
                    <Col md={6} xs={6} sm={6} lg={6} className="my-auto footer" >
                        <div style={{display:'flex',marginLeft:'10%'}}>
                            <Image className='icono1' style={{width:'5%',height:'5%'}} src={go}/>
                            <p className='text1' style={{fontSize:30,color:'white',marginLeft:'2%'}}> Carrera 23 #62-39 Edificio Capitalia B404</p>
                        </div>
                        <div style={{display:'flex',marginLeft:'10%'}} >
                            <a target='_blank' href="https://wa.me/573113704937"><Image className='icono2' src={whastapp}/> </a>
                            <p className='text2' style={{fontSize:30,color:'white',marginLeft:'2%'}}>(+57) 316 819 4210</p>
                        </div>
                        <div style={{display:'flex',marginLeft:'10%'}}>
                            <FontAwesomeIcon className='icono3' color={'white'} size="2x"icon={faEnvelope} />
                            <p className='text3' style={{fontSize:30,color:'white',marginLeft:'2%',marginTop:-8}}>mercadeo@movilgo.com.co</p>   
                        </div>
                    </Col>
                    <Col md={6} xs={6} sm={6} lg={6} className="my-auto footer" >
                        <div style={{display:'flex'}}>
                            <a target='_blank' href="https://www.facebook.com/Movilgo"><Image className='icono4' src={facebook}/> </a>
                            <p className='text4' style={{fontSize:30,color:'white',marginLeft:'2%'}}> Movilgo</p>
                        </div>
                        <div style={{display:'flex'}}>
                            <a target='_blank' href="https://www.instagram.com/movilgored/"><Image className='icono5' src={instagram}/> </a>
                            <p className='text5' style={{fontSize:30,color:'white',marginLeft:'2%'}}> Movilgored</p>
                        </div>
                        <div style={{display:'flex',width:'20%',marginLeft:'1%'}}>
                            <div className='icono6'>
                            <a href={apk} target="_blank" rel="noopener noreferrer" download="Cliente.apk" > 
                                <FontAwesomeIcon color={'white'}  fontSize={50} className="mr-2 align-self-center  " icon={faMobile} />                                        
                            </a>
                            </div>
                            <p className='text6' style={{fontSize:30,color:'white',marginLeft:'15%',marginTop:'5%',marginTop:'-1%'}}>App</p>
                        </div>
                        
                    </Col>                   
                </Row>
            </div>
            
            
                
                
          
        )
    }
}   