import React, { PureComponent } from 'react';
import woman from '../../assets/home/img4.png';
import { useNavigate } from 'react-router-dom';
import { ListOperator, ListTypes } from '../../shared/list-operator';
import { Row, Col, Form, Card, Button, Image } from 'react-bootstrap';
import { Transaction } from '../../services/product.service';
import { Carrusel } from '../../shared/carousel';
import { Summary } from './components/summary';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ManagmentErrors } from '../../shared/managmentErrors';
import { LoadingMovilGo } from '../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/modalNotificationTransaction';
import { jsPdfGenerator } from '../../shared/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';
import Header from '../../shared/header/header';
import { PayScreen } from '../../shared/pay/pay';
import { Url_home, idClienteFinal } from '../../utilities/odoo-config';
import HeaderCardoso from '../../shared/header/header-cardoso';
import { faAlignCenter, faAlignJustify } from '@fortawesome/free-solid-svg-icons';
//const navigation= useNavigate();
class PaquetesComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.operators = [];
        this.state = {
            idPackage: -1,
            active: false,
            deslogear: false,
            loading: true,
            selectedOperator: {
                id: -1,
                image_medium: '',
                operador: '',
            },
            paquetes: [],
            selectedType: '',
            tagList: [],
            operators: [],
            precio: '',
            phone: '',
            vigencia: '',
            name: '',
            carrouselView: false,
            opacity: 1,
            modalNotification: false,
            idClienteFinal: -1,
            product: {},
            localProduct: {},
            idHeader: localStorage.getItem('idDelPadre')
        }
        this.packagesByOperator = {};
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.namePacket = '';
        this.clean = ''
    }

    componentDidMount = () => {
        this.loadData()

    }

    loadData = async () => {
        const product = await JSON.parse(localStorage.getItem("products"));
        //console.log(" product de paquetes",product)
        if (product) {
            this.initialice(product.paquete, product.recarga)
        } else {
            const error = () => toast.error("Este producto no esta disponible");
            error();
        }

        this.setState({ loading: false })

    }
    cleanField = () => {
        this.setState({
            clean: false,
            name: '',
            price: ''
        })
    }

    initialice = (listPackages, listCharges) => {
        let operators = [];
        let packagesByOperator = {}
        listPackages.forEach(element => {
            if (!packagesByOperator[element.operador]) {

                packagesByOperator[element.operador] = {};
                for (let i = 0; i < listCharges.length; i++) {
                    if (listCharges[i].operador === element.operador) {
                        operators.push(listCharges[i]);
                    }
                }
            }
            if (!packagesByOperator[element.operador][element.categ_id_name]) {
                packagesByOperator[element.operador][element.categ_id_name] = [];
            }
            packagesByOperator[element.operador][element.categ_id_name].push(element);
        });
        this.packagesByOperator = packagesByOperator
        this.setState({ operators, active: true });

    }



    componentWillUnmount = () => {
        this.packagesByOperator = {};
    }
    getResponse = (flag, response) => {
        if (flag) {
            this.response = response
            const success = () => toast.success("Paquete comprado exitosamente");
            success();
            this.setState({ loading: false, opacity: 1, modalNotification: true })
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            this.setState({ loading: false, opacity: 1 })
        }
    }

    sendData = async () => {
        this.phone = this.state.phone;
        this.valor = this.state.price;
        this.namePacket = this.state.name;

        //const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal")); 
        this.setState({ loading: true })
        const product = {
            product_id: this.state.idPackage,
            clienteexterno_user_id: idClienteFinal,
            atributes: {
                numero: this.state.phone,
                precio: this.state.price
            }
        }
        this.setState({ pay: true, product: product })
        //await Transaction(product, this.getResponse);
    }

    selectOperator = (operator) => {
        this.cleanField();
        this.setState({ selectedOperator: operator, tagList: Object.keys(this.packagesByOperator[operator.operador]) });
        this.setState({ carrouselView: false })

    }

    selectType = (type) => {
        this.operador = type
        this.setState({ paquetes: this.packagesByOperator[this.state.selectedOperator.operador][type] });
        this.setState({ carrouselView: true })
    }

    closeModal = (flag, res) => {
        this.setState({
            paquetes: [],
            carrouselView: false,
            selectedOperator: {
                id: -1,
                image_medium: '',
                operador: '',
            },
            phone: '',
            clean: false,
            name: '',
            price: ''
        });

        if (!flag && res) {
            const data = [
                "Factura No: " + this.response.valida.id,
                "Fecha:" + this.response.valida.fecha,
                "No.Aprobacion:" + this.response.valida.numero_aprobacion,
                "Producto: Paquete  " + this.state.name,
                "Celular: " + this.phone,
                "Valor: " + FormatMoney(this.valor)
            ]
            jsPdfGenerator(data);
        }

    }

    volver_inicio = () => {

        this.state.navigate.navigate('/')

    }


    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                    weigth: 50 ;
                  
                }
                .contenedor{
                    padding-left: 10%;
                    padding-right: 10%;
                }
                ` }
                </style>
                <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
                {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>



                <div className="container" style={{ height: 633, opacity: this.state.opacity, marginTop: 20, alignContent: 'center' }}>

                    {this.state.idHeader !== "movilgo" ?
                        <div>
                            {this.state.active && <div className="col-md-12">
                                <h2 style={{ color: '#0D606E', marginLeft: '45%', marginTop: '1%' }} className={'defaultTitle'}>Paquetes</h2>
                                <ToastContainer />
                                <Row className="justify-content-md-center">
                                    <Col>
                                        {this.state.operators && <ListOperator onClick={(operador) => this.selectOperator(operador)} operators={this.state.operators} />}
                                    </Col>
                                </Row>
                                <Card className='reloadsCard'>
                                    <Card.Body>
                                        <Row className="justify-content-md-center">

                                            <Col xs lg="2">
                                                {this.state.selectedOperator.image_medium && <Image src={'data:image/jpeg;base64,' + this.state.selectedOperator.image_medium} style={{ maxHeight: '80px' }} />}
                                            </Col>
                                            <Col md="auto">
                                                <ListTypes clean={(flag) => this.setState({ clean: flag })} limpiar={this.state.clean} onClick={(type) => { this.selectType(type) }} types={this.state.tagList} />
                                            </Col>

                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs={12} md={8} style={{ padding: "10px" }}>
                                                {this.state.carrouselView && <Carrusel onClick={(namePacket, pricePacket, id) => { this.setState({ name: namePacket, price: pricePacket, idPackage: id }) }} packages={this.state.paquetes} operador={this.state.selectedOperator.name} type={this.state.selectedType} />}
                                            </Col>

                                            <Col xs={12} md={4}>
                                                <Form.Group >
                                                    <h4 className={'defaultTitle'}>Celular</h4>
                                                    <Form.Control
                                                        className="text-center"
                                                        placeholder='Celular'
                                                        arial-label="Amount (to the nearest dollar)"
                                                        value={this.state.phone}
                                                        maxLength={10}
                                                        onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}
                                                    />
                                                </Form.Group>
                                                <Summary
                                                    operator={this.state.selectedOperator.operador}
                                                    price={this.state.price}
                                                    phone={this.state.phone}
                                                    packet={this.state.name}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Col>
                                        <Card.Footer className="text-muted"> <Button disabled={!this.state.phone || !this.state.selectedOperator.operador} size="lg" variant={'info'} onClick={this.sendData}>Finalizar</Button></Card.Footer>
                                    </Col>
                                </Card>
                            </div>}

                            {this.state.pay && <PayScreen
                                visible={this.state.pay}
                                closeModal={(flag, data) => {
                                    this.setState({ pay: flag })
                                    this.closeModal(flag, data)
                                }}
                                product={this.state.product} />}

                            <Col>
                                {this.state.operators && <ListOperator onClick={(operador) => this.selectOperator(operador)} operators={this.state.operators} />}
                            </Col>
                        </div>

                        :
                        <div className="container" style={{ marginTop: '5%', height: 633, width: '40%' }}>
                            <p>Esta pagina no esta disponible.</p>
                            <Button
                                style={{ backgroundColor: '#019CA1', border: 'none', color: 'white', fontSize: 25, borderWidth: 2, borderColor: '#07A2BA' }}
                                onClick={() => window.open(Url_home, '_self')}>  Volver al inicio</Button>
                            <Col md={8} xs={8} sm={8} lg={8}>
                                <Image
                                    style={{ marginTop: '30%', marginLeft: '20%', marginBottom: '10%' }}
                                    src={woman} />
                            </Col>
                        </div>

                    }




                </div>


            </>
        )
    }
}





export default PaquetesComponent;