import React, { useEffect, useState } from 'react';
import {Image, Col} from 'react-bootstrap';
import woman from '../../assets/home/img4.png';
import { Button, Card, Row, Container, FormControl, Modal, ModalBody } from 'react-bootstrap';
import { searchPrestamo } from '../../services/nova.service';
import { LoadingMovilGo } from '../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../shared/modalNotificationTransaction';
import { jsPdfGenerator } from '../../shared/pdfTransaction';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Transaction } from '../../services/product.service';
import { FormatMoney } from '../../utilities/formats';
import Header from '../../shared/header/header';
import { Url_home, idClienteFinal } from '../../utilities/odoo-config';
import HeaderCardoso from '../../shared/header/header-cardoso';
export const Prestamos = (props) => {

   const [cedula, setCedula] = useState("");
   const [id_solicitud, setId_solicitud] = useState("");
   const [pago, setPago] = useState("");
   const [showPago, setShowPago] = useState(false);
   const [active, setActive] = useState(false);
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({});
   const [notification, setNotification] = useState(false);
   const [flagError, setFlagError] = useState(false)
   const [dataInfo, setDataInfo] = useState();
   const [mitecnova, setMitecnova] = useState([]);
   const [cedulaError, setCedulaError] = useState(false);
   const [id_solicitudError, setId_solicitudError] = useState(false);
   const [deslogear, setDeslogear] = useState(false)



   const clearData = () => {
      setLoading(false);
      setShowPago(false)
      setCedula('');
      setId_solicitud('');
   };

   const searchDisburse = (datatipo) => {

      if (id_solicitud === "") {
         setId_solicitudError(true)
      } else if (cedula === "") {
         setCedulaError(true)
      } else {
         setCedulaError(false)
         setId_solicitudError(false)
         setLoading(true)
         searchPrestamo(mitecnova[0].id, datatipo, id_solicitud, cedula, searchedData)
      }
   }

   const searchedData = (res, flag, tipo2) => {
      setLoading(false)
      if (flag) {
         if (res.errores) {
            const error = () => toast.error(res.errores.observacion);
            error()
            alert('Error, ' + res.errores.observacion);
         } else {
            if (res.solictud_ids.length === 0) {
               const success = () => toast.warning(" No tiene facturas pendientes asociados a ese Id");
               success();
            } else {
               setData(res.solictud_ids[0])
               setShowPago(true)



            }
         }
      } else {
         const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuniquese con MóvilGo");
         error();
      }
   };


   const closeModal = (flag) => {
      setNotification(flag);

      let data2 = [
         "Factura No: " + dataInfo.id,
         "Fecha: " + dataInfo.fecha,
         "No.Aprobacion: " + dataInfo.numero_aprobacion,
         "Id. Cliente: " + cedula,
         "Id. Solicitud: " + id_solicitud,
         "Valor del Abono: " + pago,
         "Saldo: " + (parseInt(data.valor_deuda) - parseInt(pago))
      ]

      jsPdfGenerator(data2);
      clearData();
   }
   const navigateNext = (flag, response) => {
      setLoading(false);
      if (flag) {
         if (response.errores) {
            if (response.errores.observacion) {
               const error = () => toast.error('Error,' + response.errores.observacion);
               error();
            } else {
               Error(response.errores.id)
            }
         } else {
            setDataInfo(response.valida);
            const success = () => toast.success('Móvilgo, ¡Tu transacción fue exitosa!');
            success();
            setNotification(true)
            setShowPago(false);
         }

      } else {
         clearData()
         setShowPago(false)
         const error = () => toast.error('Error,' + response.errores.observacion);
         error();

      }
   };
   /* const Desembolsar = async() => {      
      setLoading(true);
      const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal"));
      const product = {       
          product_id: mitecnova[0].id,
          clienteexterno_user_id:idClienteFinal,
          atributes: {          
            valorPagar: data.solicitud_valoraprobado,
            idsolicitud: data.id,
            cedula: cedula,
            cliente_nombre: data.partner_name,
            tipo: 'venta'
          }
        }
      
      Transaction(product, navigateNext);
      
    }; */
   const Pagar = async () => {
      if (pago < 1000) {
         setFlagError(true)
      } else {
         setLoading(true);
         //const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal")); 
         const product = {
            product_id: mitecnova[0].id,
            clienteexterno_user_id: idClienteFinal,
            atributes: {
               valorPagar: pago,
               idsolicitud: data.account_move_id,
               cedula: cedula,
               cliente_nombre: data.partner_name,
               tipo: 'pago'
            }
         }

         Transaction(product, navigateNext);
      }


   };
   const loadData = async () => {
      const product = await JSON.parse(localStorage.getItem("products"));
      if (product) {
         setMitecnova(product.mitecnova)
         setActive(true)
      } else {
         const error = () => toast.error("Este producto no esta disponible");
         error();
      }

   }

   useEffect(() => {
      loadData();

   }, [])
   return (
      <>
         {loading && <LoadingMovilGo />}
         <ToastContainer />
         <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
            {localStorage.getItem('idDelPadre') == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={deslogear}
                                                               newlogeo={(flag) => setDeslogear(flag)} /> : <Header
                                                               deslogear={deslogear}
                                                               newlogeo={(flag) => setDeslogear(flag)} />}
         </div>

         <div style={{ height: 600, width: '50%', marginLeft: '25%' }}>

            {localStorage.getItem('idDelPadre') !== "movilgo" ?
               <div>

                  {active && <div className="justify-content-md-center" style={styles.container}>
                     <Container style={{ backgroundColor: "white", marginTop: 5, width: '60%', height: 450 }}>
                        <h2 style={{ color: '#0D606E', marginLeft: '20%', marginTop: '1%' }}>Consulta de Crédito</h2>     <br></br>
                        <Row className="justify-content-md-center" >
                           <Card className="reloadsCard" style={{ width: "90%", borderColor: "#078FA5", height: '90%' }} >
                              <Card.Body>
                                 <h3>Ingrese el Id</h3>
                                 <FormControl value={id_solicitud} placeholder="Id de la solicitud"
                                    onChange={e => { setId_solicitud(e.target.value) }}
                                 />
                                 <div> {id_solicitudError && (<p style={{ color: 'red' }}>El valor de la cedula es requerido</p>)} </div>
                                 <br></br>
                                 <h3>Ingrese n° Cédula</h3>
                                 <FormControl placeholder="Cédula" value={cedula}
                                    onChange={e => { setCedula(e.target.value) }}
                                 />
                                 <div> {cedulaError && (<p style={{ color: 'red' }}>El valor de la cedula es requerido</p>)} </div>

                                 <br></br>
                                 {/* <h3>¿Qué desea realizar?</h3> */}

                                 {/*  <Button variant={'info'} className="mt-2"onClick={() => { searchDisburse("venta") }}>
                                       Desembolsar
                                    </Button> */}

                                 <Button variant={'info'} className="mt-2" onClick={() => { searchDisburse("pago") }}>
                                    Pagar
                                 </Button>

                              </Card.Body>
                           </Card>
                        </Row>
                        {/* <Modal style={{ justifyContent:'center',alignItems:'center',marginTop:200 }} show={showDesembolso}
                              onHide={() => {setShowDesembolso(false) }} backdrop="static" >
                              <Modal.Header style={{textAlign:'center'}} closeButton>
                                 <Modal.Title >Realizar Desembolso</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                 <Card className='reloadsCard' style={{ minWidth: '40%' }}>
                                    <Card.Body>
                                       <div>
                                          <h3>Cliente</h3>                                    
                                          <p>{data.partner_name}</p>
                                       </div>
                                       <div>
                                          <h3>Solicitud</h3>                                 
                                          <p>{data.id}</p>
                                       </div>
                                       <div>
                                          <h3>Valor aprobado</h3>                                    
                                          <p>{data.solicitud_valoraprobado}</p>
                                       </div>
                                       <div>
                                          <h3>Fecha de solicitud</h3>                                 
                                          <p>{data.fecha_solicitud}</p>
                                       </div>
                                       <div style={{ justifyContent:'center',textAlign:'center'}}>
                                          <Button onClick={Desembolsar} variant={'info'} className="mt-2" >
                                             Obtener
                                          </Button>
                                          <Button onClick={() => clearData()} className='mt-2'style={{ backgroundColor: 'transparent', borderColor: '#ccc', color: '#696969', marginLeft: '5%' }}>
                                             Cancelar
                                          </Button>
                                       </div>
                                       
                                    </Card.Body>
                                    <div style={{marginLeft:'30%',width:'100%'}}>
                                          {loading && <LoadingMovilGo />}
                                    </div>
                                 </Card>                           
                              </Modal.Body>
                           </Modal> */}
                        <Modal style={{ justifyContent: 'center', alignItems: 'center', marginTop: 200 }}
                           show={showPago} onHide={() => { setShowPago(false) }}
                           backdrop="static" >
                           <Modal.Header closeButton>
                              <Modal.Title>Realizar Pago</Modal.Title>
                           </Modal.Header>
                           <ModalBody>
                              <div style={styles.marco}>
                                 <div>
                                    <div>
                                       <h3>Cliente</h3>
                                       <p>{data.partner_name} </p>

                                       <h3>Solicitud</h3>
                                       <p>{id_solicitud}</p>

                                       <h3>Documento</h3>
                                       <p>{cedula} </p>
                                       <h3>Saldo pendiente</h3>
                                       <p>{FormatMoney(data.valor_deuda ? data.valor_deuda : 0)}</p>
                                       <h3>Saldo a cancelar</h3>
                                       <FormControl value={pago} placeholder="Valor"
                                          onChange={e => { setPago(e.target.value) }}
                                       />
                                    </div>
                                    <div> {flagError && (<p style={{ color: 'red' }}>El valor a pagar tiene que ser mayor a $1000</p>)} </div>
                                 </div>
                                 <div>
                                    <Button onClick={() => Pagar()} variant={'info'} className="mt-2" style={{ marginLeft: '15%' }}>
                                       Pagar
                                    </Button>
                                    <Button onClick={() => clearData()} className='mt-2' style={{ backgroundColor: 'transparent', borderColor: '#ccc', color: '#696969', marginLeft: '5%' }}>
                                       Cancelar
                                    </Button>
                                 </div>
                              </div>
                              <div style={{ marginLeft: '30%', width: '100%' }}>
                                 {loading && <LoadingMovilGo />}
                              </div>

                           </ModalBody>
                        </Modal>
                        {notification && <ModalNotificationTransaction
                           closeModal={(flag) => closeModal(flag)}
                           type={"Crédito"}
                           message={"Crédito diligenciado exitosamente"} />}
                     </Container>
                  </div>}
               </div>
               :
               <div style={{ marginTop: '5%', height: 633, width: '40%' }}>
                  <p>Esta pagina no esta disponible.</p>
                  <Button
                     style={{ backgroundColor: '#019CA1', border: 'none', color: 'white', fontSize: 25, borderWidth: 2, borderColor: '#07A2BA' }}
                     onClick={() => window.open(Url_home, '_self')}>  Volver al inicio</Button>

                  <Col md={8} xs={8} sm={8} lg={8}>
                     <Image
                        style={{ marginTop: '30%', marginLeft: '20%', marginBottom: '10%' }}
                        src={woman} />
                  </Col>
               </div>
            }

         </div>

      </>
   );
};

const styles = {
   title: {
      fontWeight: "bold",
      align: "center",
   },
   container: {
      paddingHorizontal: 10,
      maxHeight: "100%",
      //backgroundColor: '#078FA5'
   }, marco: {
      margin: 5,
      borderWidth: 1,
      padding: 5,
      borderColor: '#078fa4',
      borderRadius: 10
   },
   titleSummary: {
      alignSelf: "center",
      fontWeight: "bold",
      fontSize: 18,
      color: "#07A2BA",
   },
   colContainer: {
      width: "32%",
   },
   rowContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 5,
      width: "100%",
      paddingLeft: 15,
   },
};
