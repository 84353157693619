import React, { PureComponent, Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import woman from '../../assets/home/img4.png';
import { Row, Button, Card, Container, Col, Image } from 'react-bootstrap';
import { searchCertification } from '../../services/certification.service';
import Select from "react-select";
import ReactShadowScroll from 'react-shadow-scroll';
import { Capitalize } from '../../utilities/formats'
import Header from '../../shared/header/header';
import HeaderCardoso from '../../shared/header/header-cardoso';
import { Url_home } from '../../utilities/odoo-config';
export class CertificadoComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            optionPlaces: {},
            optionDepartment: {},
            departmentSelected: '',
            showScroll: false,
            deslogear: false,
            active: false,
            idHeader: localStorage.getItem('idDelPadre')
        }
        this.certificados = [];
        this.places = [];
    }
    componentDidMount = async () => {
        const product = await JSON.parse(localStorage.getItem("products"));
        console.log("certificadors products", product)
        if (product.certificados && product.certificados > 0) {
            this.certificados = product.certificados;

            searchCertification(this.certificados[0].id, this.offices)
            //this.getCertification()
        } else {
            const error = () => toast.error('No se han encontrado certificados');
            error();
            this.setState({ loading: false })

        }

    }
    offices = (response, flag) => {
        this.setState({ loading: false })
        if (flag) {
            this.setState({ loading: false })
            response.forEach((element) => {
                if (this.places[element.group]) {
                    this.places[element.group].push(element);
                } else {
                    this.places[element.group] = [];
                    this.places[element.group].push(element);
                }
            })
            const sucess = () => toast.success('Se encontraron oficinas Disponibles')
            sucess();
            this.loadDepartments(this.places)

        } else {
            const error = () => toast.error('No se han encontrado oficinas disponibles');
            error();
        }

    }
    loadDepartments = (places) => {
        const keys = Object.keys(places);
        let deptos = []
        keys.forEach((element) => {
            deptos.push({ value: element, label: element })
            deptos.sort((a, b) => {
                return a.label < b.label ? -1 : 1;
            });
        })

        this.setState({ optionDepartment: deptos, active: true })

    }
    loadPlaces = (value) => {

        let row = []
        let rowOrder = []
        this.places[value.value].forEach((element) => {
            row.push({ code: element.code, group: element.group, name: element.name })
            row.sort((a, b) => {
                return a.name < b.name ? -1 : 1;
            });
        })
        row.forEach((element) => {
            rowOrder.push(
                <Button style={{ marginLeft: 50, backgroundColor: '#005F6E', width: '90%', marginTop: 10, borderRadius: 10, height: 100 }}>
                    <div style={{ flexDireccion: "row" }}>
                        <h6>{"Nombre= " + Capitalize(element.name)}</h6>
                        <h6>{"Grupo= " + Capitalize(element.group)}</h6>
                        <h6>{"Codigo= " + element.code}</h6>
                    </div>
                </Button>
            )
        })

        this.setState({ showScroll: true, payplace: rowOrder })


    }
    render() {
        return (
            <>
                <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
                {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>

                {this.state.idHeader !== "movilgo" ?
                    <div>
                        <ToastContainer />
                        <Container style={{ height: 600, width: '40%', marginTop: '2%' }}>
                            {this.state.active && <Card className='reloadsCard'>
                                <Card.Body>

                                    <hr />
                                    <Row>
                                        <Col xs={12} md={4}>

                                        </Col>

                                    </Row>
                                    <Fragment>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={this.state.departmentSelected}
                                            isDisabled={false}
                                            isLoading={false}
                                            isClearable={true}
                                            isRtl={false}
                                            isSearchable={true}
                                            name="color"
                                            options={this.state.optionDepartment}
                                            onChange={(value) => {
                                                this.setState({ departmentSelected: value });
                                                this.loadPlaces(value)
                                            }}
                                        />
                                    </Fragment>
                                    {this.state.showScroll && <ReactShadowScroll scrollWidth={20}>
                                        <div style={{ height: '400px', width: 600 }}>
                                            {this.state.payplace}
                                        </div>
                                    </ReactShadowScroll>}
                                </Card.Body>

                            </Card>}
                        </Container>

                    </div> :
                    <div className="container" style={{ marginTop: '5%', height: 633, width: '40%' }}>
                        <p>Esta pagina no esta disponible.</p>
                        <Button
                            style={{ backgroundColor: '#019CA1', border: 'none', color: 'white', fontSize: 25, borderWidth: 2, borderColor: '#07A2BA' }}
                            onClick={() => window.open(Url_home, '_self')}>  Volver al inicio</Button>

                        <Col md={8} xs={8} sm={8} lg={8}>
                            <Image
                                style={{ marginTop: '30%', marginLeft: '20%', marginBottom: '10%' }}
                                src={woman} />
                        </Col>
                    </div>

                }

            </>
        )
    }
}





