
import React, {Component} from 'react';
import CarruselHome from './components/carruselhome';
import {Button,Image, Row, Col,Card,Modal} from 'react-bootstrap';
import fondo from '../../assets/home/fondo.png';
import fondo2 from '../../assets/home/fondo2.png'
import men from '../../assets/home/img1.png';
import personaje from '../../assets/home/personaje.png'
import { Register } from '../../shared/header/components/register';
import incon from '../../assets/home/icono.png'
import woman from '../../assets/home/img2.png'
import rifa from '../../assets/home/RIFA.jpg'
import Header from '../../shared/header/header';
import { idClienteFinal } from '../../utilities/odoo-config';
import firsthome from '../home/components/FirstHome.css';
  export default class FirstHomeComponent extends Component{
    constructor(props) {
        super(props)
        this.state={
                showRegister:false ,
                deslogear:false,
                show:false
        }
      }
      componentDidMount=()=>{
        this.setState({show:true})
        //console.log("componentDidMount props",this.props)
      }
      render(){
        return(<>
                
                <div>
                    <div className='principal' style={{height:3500}} >
                        <div style={{backgroundImage: `url(${fondo})`,height:1500}}>
                            <div style={{position:'absolute',index:1,display:'inlineBlock',marginTop:50}}>
                                <Header showSeller={true} idPadre={idClienteFinal} deslogear={this.state.deslogear} newlogeo={(flag)=>this.setState({deslogear:flag})} /> 
                                <div className='carrusel' style={{marginBottom:'20%',marginTop:'3%',marginBottom:'5%'}}>
                                   <CarruselHome/> 
                                </div>
                                
                            </div>
                            <Row >
                                <Col md={4} xs={4} sm={4} lg={4} >
                                    <div className='texto' style={{marginTop:800,marginLeft:'10%',}}>
                                        <p style={{ textAlign:'left', color:'#0D606E', fontSize: 70, left:80}}>
                                            
                                            Tener en tu celular todo lo que necesitas es mas sencillo
                                        </p>
                                        <p style={{ textAlign:'left', color:'white', fontSize: 40, left:20 }}>
                                            
                                            Encuentra todos los productos que utilizas en tu dispositivo
                                        </p>                                     
                                    </div>
                                    
                                </Col>
                                <Col md={8} xs={8} sm={8} lg={8}>                              
                                <Image  className='imagen'                           
                                        style={{marginTop:'65%' }} 
                                        src={men}            
                                    /> 
                                </Col>
                            </Row>
                            
                        </div>
                       
                        <div style={{backgroundImage: `url(${fondo2})`,width:'100%'}}>                       
                            <div style={{height:'15%'}}>
                                <Row>
                                    <Col md={4} xs={4} sm={4} lg={4} >
                                        <div style={{marginTop:'10%',marginLeft:'20%'}}>
                                            <p style={{ textAlign:'left', color:'#0D606E', fontSize: 70, left:80}}>
                                                Nuestros productos
                                            </p>
                                            <p style={{ textAlign:'left', color:'#0D606E', fontSize: 40, left:80 }}>
                                                Un lugar en donde puedes encontrar todos los servicios de mas utilizas
                                            </p>
                                            
                                        </div>
                                        
                                    </Col>
                                    <Col md={8} xs={8} sm={8} lg={8}>
                                        <Card style={{backgroundColor:'white',top:30,width:'80%',left:120}}>
                                            <Card.Body style={{borderRadius:10,borderWidth:50}}>
                                                <div style={{position:'relative',width:'30%',top:100}}>
                                                    <p style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        RECARGAS Y PAQUETES
                                                    </p>
                                                    <p style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        PINES                                            
                                                    </p>
                                                    <p style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        APUESTAS DEPORTIVAS
                                                    </p>
                                                    <p style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        CERTIFICADOS DE TRADICION
                                                    </p>
                                                    <p style={{textAlign:'left', fontSize: 20, left:20,color:'#0D606E'}}>
                                                        RIFAS
                                                    </p>
                                                </div>
                                                <div style={{width:'70%',left:280,position:'relative',bottom:150}}>
                                                    <Image                             
                                                        style={{ width: '40%',height:'50%',borderLeftWidth:8,borderLeftColor:'#0D606E',borderLeftStyle:'solid'}} 
                                                        src={personaje}            
                                                    /> 
                                                </div>
                                                
                                            </Card.Body>
                                        </Card>  
                                    
                                    </Col>  
                                </Row>
                                <div style={{width:'80%',position:'relative',left:150,marginTop:70,borderBottomStyle:'solid',borderBottomWidth:10,borderBottomColor:'#0D606E'}}>

                                </div>
                            </div>
                            <div style={{height:'85%'}}>
                                    <div style={{width:'100%'}}>
                                    <p style={{ textAlign:'center', color:'#0D606E', fontSize: 70,}}>
                                        Es muy sencillo
                                    </p>
                                </div> 
                                <div style={{position:'relative',marginTop:'2%',marginLeft:'10%'}}>
                                    <div style={{width:'40%'}}>
                                        <Row>
                                            <Image                             
                                                style={{ width: '10%',height:50}} 
                                                src={incon}            
                                            />
                                            <p style={{ textAlign:'left', color:'#0D606E', fontSize: 30,width:'90%'}}>
                                                Ingresa desde tu computador y registrate con tus datos personajes e informarcion
                                            </p> 
                                        </Row>                                        
                                    </div>
                                    <div style={{borderTopStyle:'solid',borderTopWidth:5,borderTopColor:'#0D606E',width:'40%',marginTop:30}}>
                                        <Row>
                                            <Image                             
                                                style={{ width: '10%',height:50}} 
                                                src={incon}            
                                            />
                                            <p style={{ textAlign:'left', color:'#0D606E', fontSize: 30,width:'90%'}}>
                                                Accede servicio o producto que necesita
                                            </p> 
                                        </Row>                                        
                                    </div>
                                    <div style={{borderTopStyle:'solid',borderTopWidth:5,borderTopColor:'#0D606E',width:'40%',marginTop:30}}>
                                        <Row>
                                            <Image                             
                                                style={{ width: '10%',height:50}} 
                                                src={incon}            
                                            />
                                            <p style={{ textAlign:'left', color:'#0D606E', fontSize: 30,width:'90%'}}>
                                                Seleciona el medio de pago
                                            </p> 
                                        </Row>                                        
                                    </div>
                                </div>
                                <div style={{position:'relative',marginTop:'-20%',marginLeft:'40%'}}>
                                    <Image                             
                                        style={{ width:'100%',height:'100%'}} 
                                        src={woman}            
                                    />
                                </div>
                                <div style={{position:'relative',marginTop:'-20%',marginLeft:'75%'}}>
                                    <div >
                                        <Row>
                                            <p style={{ textAlign:'left', color:'#0D606E' ,fontSize: 50}}>
                                                Nuestra App
                                            </p>
                                            <p style={{ textAlign:'left' ,color:'#0D606E', fontSize: 30}}>
                                                Ingresa desde tu computador y registrate con tus datos personajes e informarcion
                                            </p> 
                                        </Row>                                        
                                    </div>
                                </div>
                            </div> 
                        </div> 
                        
                        
                    </div> 
                    <Register 
                        visible={this.state.showRegister} 
                        closeModal={(flag)=>this.setState({showRegister:flag})} 
                        onLogin={(resp, flag) => { this.props.login(resp, flag) }}/>
                </div>
                {/* <Modal show={this.state.show} onHide={()=>this.setState({show:false})}  style={{width:'100%'}}>
                    <Modal.Body style={{width:1240, marginLeft:-420,height:700}}>
                        <Button onClick={()=>this.setState({show:false})} style={{height: '100%',marginLeft:'1%',marginTop:'-1%', width: 1210,backgroundColor:'transparent',borderWidth:'transparent'}}>
                            <Card.Img src={rifa}  style={{ height: 850, width: 1320,marginLeft:'-2%',marginTop:'-1%'}} alt="Card image" />
                        </Button>
                            
                    </Modal.Body>
                </Modal>   */}  
           </>)}
    }

