import React, { PureComponent } from 'react';
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import { LoadingMovilGo } from '../loadingMovilGo';
import pse from '../../assets/pagos/pse.png'
import efecty from '../../assets/pagos/efecty.png'
import card from '../../assets/pagos/card.png'
import nequi from '../../assets/pagos/nequi.png'
import daviplata from '../../assets/pagos/daviplata.png'
import { ButtonImagePay } from '../buttons';
import { PseScreen } from './component/pse';
import { PayCardScreen } from './component/card';
import { Desencrypt } from '../../utilities/cipher';
export class PayScreen extends PureComponent{
    constructor(props) {
        super(props);
        /* this.recargas = this.props.recargas; */
        this.state = {
            buttonsPay:[],
            pse:false,
            card:false,
            userlogin:{}
        }
    }
    componentDidMount=()=>{
        console.log("this.props del pay ",this.props)
        this.loadPay()
    }
    loadPay=async()=>{
        const data = await Desencrypt();
        this.setState({userlogin:{user:data.user,password:data.password}})
        let buttons = []
        for (let k = 0; k < imageOptions.length; k = k + 2) {
            buttons.push(
               <div key={'key' + k} style={styles.containerButton}>
                <ButtonImagePay onClick={() =>  this.pressImage(imageOptions[k].name) }  image={imageOptions[k].source}></ButtonImagePay>
                {k + 1 < imageOptions.length && <ButtonImagePay onClick={() => this.pressImage(imageOptions[k + 1].name) } image={imageOptions[k+1].source }></ButtonImagePay>}
                </div>
                
            )
        }
        this.setState({buttonsPay:buttons})
    }

    pressImage=(routePay)=>{
        console.log("imagen presionada",routePay)
        this.setState({[routePay]:true})

    }
    render(){
        return(
            <Modal
                style={{ opacity: 1 }}
                size='md'
                show={this.props.visible}/*true */
            >
                <Modal.Header style={{ backgroundColor: '#0D606E' ,alignSelf:'center',width:'100%'}} >
                    <Modal.Title className={'defaultTitle'} style={{ color: 'white' }}>Metodos de pago</Modal.Title>
                    <Button onClick={() => {this.props.closeModal(false)}} type="button" style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none' }}>
                        <FontAwesomeIcon color={'white'} className="mr-2 align-self-center" icon={faTimes} />
                    </Button>
                </Modal.Header>
                <Modal.Body >
                    {this.state.buttonsPay}

                </Modal.Body>
                        
            {this.state.loading && <LoadingMovilGo />}
            {this.state.pse&&<PseScreen 
                            userLogin={this.state.userlogin}
                            visible={this.state.pse} 
                            closeModal={(flag,data)=>{this.setState({pse:flag});    
                                                    this.props.closeModal(flag,data)}} 
                            product={this.props.product} /* navigation={this.props.navigation} *//>}
           {this.state.card&&<PayCardScreen 
                                    userLogin={this.state.userlogin}
                                    visible={this.state.card} 
                                    closeModal={(flag,data)=>{this.setState({card:flag})
                                                this.props.closeModal(flag,data)}} 
                                    product={this.props.product} 
                                    />}
            </Modal>
            
        )
    }
}

const imageOptions = [
    {
      name:'pse',
      source: pse
    },
    {
      name:'card',
      source: card 
    }/*,
    {
      name:'efecty',
      source: efecty 
    },
    {
      name:'nequi',
      source: nequi 
    },
    {
      name:'daviplata',
      source: daviplata 
    } ,
    {
      name:"bitcoin",
      source: bitcoin 
    } */ 
  ]


  const styles = {
    containerButton: {
        height: '25%',
        width: '100%',
        marginVertical: '1%',
        flexDirection: "row",
       
    },
  };