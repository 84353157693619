import React from 'react';

// Campos de solo texto
export const InputForm = (props) => {
    const { title = "", value = "", type = "", handleChange, placeholder = "", onBlur = () => { }, required, setError, name, error = "", enterKey=()=>{} } = props
    if (typeof title === 'string') {
        return (
            <div >
                <label >{title}{required && <span style={{ color: "red" }}>*</span>}</label>
                <input
                    className="form-control"
                    value={value}
                    type={type}
                    onChange={(e) => { handleChange(e.target.value); setError('') }}
                    onBlur={() => onBlur(name, value, required, setError)}
                    onKeyDown={(e) => { if(e.keyCode === 13) {enterKey()} }}
                    placeholder={placeholder}
                    style={{ borderColor: error !== '' ? 'red' : '' }}
                >
                </input>
                <label style={{ color: "red" }}>{error}</label>
            </div>
        )
    } else {
        return (
            <div >
                {title}
                <input
                    className="form-control"
                    value={value}
                    type={type}
                    onChange={(e) => { handleChange(e.target.value); setError('') }}
                    onBlur={() => onBlur(name, value, required, setError)}
                    placeholder={placeholder}
                    style={{ borderColor: error !== '' ? 'red' : '' }}
                    onKeyDown={(e) => { if(e.keyCode === 13) {enterKey()} }}
                >
                </input>
                <label style={{ color: "red" }}>{error}</label>
            </div>
        )
    }

}
// Campos de solo numeros
export const InputNumber = (props) => {
    const { title = "", field = {}, type = "", handleChange, placeholder = "", onBlur = () => { } } = props
    return (
        <div className="form-group">
            <h4> <label >{title}{field.required && <span style={{ color: "red" }}>*</span>}</label></h4>
            <input
                className="form-control"
                value={field.value}
                type={type}
                onChange={(e) => handleChange(field.name, e.target.value.replace(/\D/, ''))}
                onBlur={() => onBlur(field.name)}
                placeholder={placeholder}
            >
            </input>
            <h5>  <label style={{ color: "red" }}>{field.error}</label></h5>
        </div>
    )
}


export const RadioForm = (props) => {
    const { title = "", field = {}, type = "vertical", handleChange, options = [] } = props
    if (type === 'horizontal') {
        return (
            <div className="form-group">
                <h5> <label >{title}{field.required && <span style={{ color: "red" }}>*</span>}</label></h5>
                <div className="form-check">
                    {options.map((option, i) => {
                        return (
                            <label key={i} style={{ marginLeft: 24, fontSize: '22px' }}>
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    value={option.value}
                                    checked={field.value === option.value}
                                    onChange={() => handleChange(field.name, option.value)}
                                />
                                <label className="form-check-label">
                                    {option.title}
                                </label>
                            </label>
                        )
                    })}
                </div>
                <h6>  <label style={{ color: "red" }}>{field.error}</label></h6>
            </div>
        )
    } else {
        return (
            <div className="form-group">
                <h5> <label >{title}{field.required && <span style={{ color: "red" }}>*</span>}</label></h5>
                <div>
                    {options.map((option, i) => {
                        return (
                            <div key={i} className="form-check">
                                <h6> <label className="form-check-label" >
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        value={option.value}
                                        checked={field.value === option.value}
                                        onChange={() => handleChange(field.name, option.value)}
                                    />
                                    {option.title}
                                </label></h6>
                            </div>)
                    })}
                </div>
                <h6>  <label style={{ color: "red" }}>{field.error}</label></h6>
            </div>
        )
    }

}

export const ComboBoxForm = props => {
    const { title = "", field = {}, handleChange, options = [] } = props
    return (
        <div className="form-group">
            <h5> <label >{title}{field.required && <span style={{ color: "red" }}>*</span>}</label> </h5>
            <div className="form-group">
                <select className="custom-select" onChange={(event) => handleChange(field.name, event.target.value)}>
                    {options.map((option, i) => {
                        return (
                            <option key={i} value={option.value}>{option.title}</option>
                        )
                    })}
                </select>

            </div>
            <h6>  <label style={{ color: "red" }}>{field.error}</label></h6>
        </div>
    )
}


// tipo date
export const DateForm = props => {
    const { title = "", field = {}, handleChange, type = "" } = props
    return (
        <div className="form-group">
            <h5> <label >{title}{field.required && <span style={{ color: "red" }}>*</span>}</label></h5>
            <div className="form-group">
                <input
                    data-provide="datepicker"
                    className="form-control"
                    value={field.value}
                    type={type}
                    onChange={(e) => handleChange(field.name, e.target.value)}
                />
            </div>
            <h6>  <label style={{ color: "red" }}>{field.error}</label></h6>
        </div>
    )
}