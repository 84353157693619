import React, { Component } from 'react';
import { Row, Col, Card,FormControl, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { ListPrices } from './list';
import NumberFormat from 'react-number-format';
import { ModalRegistro } from './modal-registro';
import { ModalBoletas } from './modal-boletas';
import { searchNumber } from '../../../services/rifa.service';
import { searchClient } from '../../../services/cliente.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Desencrypt } from '../../../utilities/cipher';
import { FormatMoney } from '../../../utilities/formats';
import { getClient } from '../../../services/cliente.service';
import Header from '../../../shared/header/header';
import { PayScreen } from '../../../shared/pay/pay';
import { useNavigate } from 'react-router-dom';
import { jsPdfGenerator } from '../../../shared/pdfTransaction';
import { idClienteFinal } from '../../../utilities/odoo-config';
import HeaderCardoso from '../../../shared/header/header-cardoso';
export default class Compras extends Component {

    constructor(props) {
        super(props);
        this.state = {
            deslogear:false,
            visible: false,
            selectedPrice: '',
            boleta: '',
            visibleRegistro: false,
            visibleBoletas: false,
            creditoRifa: '',
            buyMessage: <></>,
            numberTicket: '',
            identification: '',
            userTicket: '',
            message: <></>,
            styleTicket: {},
            styleUser: {},
            viewTicket: false,
            viewUserTicket: false,
            partner_id: -1,
            prices: [],
            loading: false,
            opacity: 1,
            modalNotification: false,
            showRifa: <></>,
            showModalRegister:false,
            pay:false,
            product:{},
            client:{},
            luckyNumber:false,
            idHeader: localStorage.getItem('idDelPadre'),
            idDelPadre: localStorage.getItem("IdPadreCliente")
        };
        this.numbersTicket = [];
        this.allNumbersTicket = [];
        this.numSuerte = 0;
        this.Mounted=false
    }
    /* componentDidMount = async() => {
        //this.price();
        console.log("estamos solucionando el error ",this.props)
        
        //
        
    } */
    componentDidMount = () => { 
            //console.log("componentDidMount ",this.props)
            this.price();
            this.setState({opacity: 0.4 })
            this.loadClientOdoo() 
        
        
    }

    loadClientOdoo=()=>{

         getClient((res,flag)=>{
            //console.log("getcliente flag",flag)
            //console.log("getcliente response",res.xidentification)
            
            if(flag){
                this.setState({identification:res.xidentification,client:res})
                //
             this.loadClienRafle(res.xidentification)
                
            }else{
                this.setState({loading:false})
                const error = () => toast.error("¡No se encontraron Datos del cliente !");
                error();
            }

        })
    }
    loadClienRafle=async(document)=>{
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        //console.log("loadClienRafle rifa",rifa)
        await searchClient(rifa.id, document, this.foundClient);

    }


    calcularAbono = (price, valorRifa) => {

        if (price === valorRifa) {
            this.setState({
                buyMessage: (
                    <h5> Seleccionó el pago Completo </h5>
                )
            }
            );
        } else {
            this.setState({
                buyMessage: (
                    <h5> Abonó: {FormatMoney(price)}</h5>
                )
            }
            );
        }
    }
    selectTicket = (number) => {
        this.setState({ numberTicket: number })
    }
    selectPrice = (price) => {

        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        this.calcularAbono(price, rifa.precio)

        if (typeof (price) === 'string' && price.toLowerCase() === 'otro valor') {
            this.setState({ visible: true, selectedPrice: '' });
        }
        else {
            this.setState({ selectedPrice: price, visible: false });
        }
    }
    showModal = (flag) => {
        this.setState({ visibleRegistro: flag, boleta: '' });
        this.setState({ visibleBoletas: flag });
    }
    chooseTicket = (flag) => {
        this.setState({ visibleBoletas: flag, styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true });

    }
    foundNumber = (response, flag) => {

        //const noEncontre = () => toast.error("¡No se encontraron resultados relacionados!");
        const encontrada = () => toast.info("¡Se encontró la boleta solicitada!");
        const boletasRelacionadas = () => toast.warning("¡No se encontró la boleta solicitada, pero hay algunas con relación!");
        const boletasNumSuerte = () => toast.info("Tu número de la suerte es el "+this.numSuerte+", éstas son tus sugerencias");
        
        if (flag) {
            let esta = false;
            //this.numbersTicket = response
            let newNumber=[]
            this.allNumbersTicket =response.disponibles
            //console.log("response de los tikes",this.state.luckyNumber,response.disponibles)
            if(this.state.luckyNumber){
                this.numbersTicket.push(response.disponibles[0])
            }else{
                for (let k = 0; k < 6; k = k + 1) {
                    newNumber.push(response.disponibles[k])
                }
                this.numbersTicket = newNumber

            }
            /* if(response?.errores){
                alert("Se produjo un error al consultar la boleta")
                return

            } else*/ if (response.disponibles.length > 0) {
                if(this.state.luckyNumber){
                    this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                }else{
                    for (let index = 0; index < response.disponibles.length; index++) {
                        //console.log("for ",this.state.numberTicket,response.disponibles[index])
                        if (this.state.numberTicket == response.disponibles[index].numero_boleta) {
                            esta = true;
                        }
                    }
                    if (esta) {
                        encontrada();
                        this.setState({ styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true, loading: false, opacity: 1 });
                    } else {
                        //noEncontre();
                        //this.setState({ loading: false, opacity: 1 })
                        this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                    }

                }
                /* if (this.numbersTicket.disponibles.length === 1) {
                    encontrada();
                    this.setState({ styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true, loading: false, opacity: 1 })
                } else {
                    const size = response.disponibles[0].length
                    const enviSize = this.state.numberTicket.length
                    if (size === enviSize) {
                        for (let index = 0; index < response.disponibles.length; index++) {
                            if (this.state.numberTicket === response.disponibles[index]) {
                                esta = true;
                            }
                        }
                        if (esta) {
                            encontrada();
                            this.setState({ styleTicket: { borderWidth: 2, borderColor: '#07A2BA' }, viewTicket: true, loading: false, opacity: 1 });
                        }else{
                            noEncontre();
                            this.setState({ loading: false, opacity: 1 })
                        }
                    }  
                    else {
                        if(this.numSuerte>0){
                            boletasNumSuerte();
                            this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                        }else{
                            boletasRelacionadas();
                            this.setState({ visibleBoletas: true, loading: false, opacity: 1 })
                        }                        
                    }
                } */
            } else {
                //noEncontre();
                this.setState({ loading: false, opacity: 1 })
            }
        }
    }

    searchTicket = () => {
        const errorDigitar = () => toast.error("¡No se le olvide digitar un número de boleta!");
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        this.numbersTicket = [];
        if (this.numSuerte !== 0 ) {
            this.setState({ loading: true, opacity: 0.4 });
            searchNumber(this.numSuerte, rifa.id, this.foundNumber);
        } else if (this.state.numberTicket.trim() !== "") {
            this.setState({ loading: true, opacity: 0.4 });
            searchNumber(this.state.numberTicket, rifa.id, this.foundNumber);
        } else {
            errorDigitar();
        }
    }
    numeroDeLaSuerte = (flag) => {
        this.setState({luckyNumber:flag})
        const min = 1;
        const max = 99;
        const numeroAleatorio = Math.floor(Math.random() * (max - min)) + min;
        this.numSuerte = numeroAleatorio;
        this.setState({numberTicket:''})
        this.searchTicket();
    }

    foundClient = (response, flag) => {
        //console.log("flag  del foundClietn",flag,response)
        const encontre = () => toast.info("¡Usuario encontrado!");
        const noEncontre = () => toast.error("¡Usuario no encontrado, por favor registrarlo !");
        if (flag) {
            if (response.name !== false) {
                encontre();
                this.setState({
                    loading: false, 
                    opacity: 1,
                    userTicket: response.name,
                    styleUser: {
                        borderWidth: 2, borderColor: '#07A2BA'
                    }, viewUserTicket: true, partner_id: response.partner_id
                });
            } else {
                noEncontre();
                this.setState({ visibleRegistro: true, loading: false, opacity: .8 })
            }
        }
    }

    handleChange = (event) => {
        this.numSuerte=0;
        this.setState({ numberTicket: event.target.value.replace(/\D/, ''), styleTicket: { borderWidth: 1 }, viewTicket: false });
    }

    handleChangeUser = (event) => {
        this.setState({ identification: event.target.value.replace(/\D/, ''), styleUser: { borderWidth: 1 }, userTicket: '' })
    }

    navigateNext = async(flag, response) => {
       /*  console.log("comprar navigateNext flag",flag)
        console.log("comprar navigateNext response",response) */
        this.setState({numberTicket: '',selectedPrice: '',  })
        if (!flag&&response) {
            const rifa = JSON.parse(localStorage.getItem('datosRifa'));
            
            let data=[]
            data = [
                "Factura No: " + response.valida.id,
                "Fecha:" + response.valida.fecha,
                "No.Aprobacion:" + response.valida.numero_aprobacion,                
                "Producto: " + rifa.name,                
                "Resolucion: " + rifa.resolucion,
                "Fecha del sorteo: " + rifa.sorteo,
                "Número de boleta: " + this.state.numberTicket,
                "Cliente: " + this.state.userTicket,
                "Valor: " + FormatMoney(this.state.selectedPrice)
            ]
            if (rifa.precio > this.state.selectedPrice) {
                data = [
                    "Factura No: " + response.valida.id,
                    "Fecha:" + response.valida.fecha,
                    "No.Aprobacion:" + response.valida.numero_aprobacion,
                    "Producto: Abono " + rifa.name,
                    "Resolucion: " + rifa.resolucion,
                    "Fecha del sorteo: " + rifa.sorteo,
                    "Número de boleta: " + this.state.numberTicket,
                    "Cliente: " + this.state.userTicket,
                    "Valor total: " + FormatMoney(rifa.precio),
                    "Valor abonado: " + FormatMoney(parseInt(this.state.selectedPrice)),
                    "Valor por pagar: " + FormatMoney(rifa.precio - this.state.selectedPrice),
                ]
                
            }
            jsPdfGenerator(data) 
           this.props.navigate('/')
            //this.setState({ modalNotification: true, loading: false, opacity: 1, userTicket: '', viewTicket: false, selectedPrice: '', numberTicket: '', styleTicket: { borderWidth: 1 }, styleUser: { borderWidth: 1 }, identification: '' })

        } else{
            this.props.navigate('/')
        }
    }

    payTicket = async() => {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        const user = Desencrypt();
        try {
            let idProduct=''
            for (const element of  this.numbersTicket) {
                if(parseInt(this.state.numberTicket)==parseInt(element.numero_boleta)){
                    idProduct=element.product_id
                  break;
                }
                //console.log(element); // 👉️ bobby
              }
            const product = {
                product_id: idProduct,
                clienteexterno_user_id:parseInt(this.state.idDelPadre), 
                atributes: {
                    numeroBoleta: "" + this.state.numberTicket,
                    valorPagar: parseInt(this.state.selectedPrice),
                    partner_id: this.state.partner_id,
                    vendedor_externo_id: user.id,
                    nombre_cliente: this.state.userTicket
                }
            }
            
            localStorage.setItem("NumBolet", this.state.numberTicket);
            //console.log("product de la rifa",product)
            this.setState({product:product,pay:true})
            //Transaction(product, this.navigateNext)
        } catch (error) {

        }
    }

    price = () => {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        let prices = []
        const idPadre = localStorage.getItem('idDelPadre');
        if (rifa.porcentaje_recaudo === 100 || idPadre == "cardoso" || idPadre == "movilgo") {
            prices = [rifa.precio]
        } else {
            prices = [
                rifa.precio * rifa.porcentaje_recaudo / 100,
                rifa.precio / 2,
                rifa.precio,
                "Otro valor"
            ];
        }

        this.setState({ prices })
    }

    render() {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        //const navigate = useNavigate();
        return (
            <>
                {this.state.showRifa}
                <div style={{position:'relative',index:1,display:'inlineBlock',marginTop:20}}>
                    {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                    newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                    deslogear={this.state.deslogear} 
                                                                    newlogeo={(flag)=>this.setState({deslogear:flag})} />} 

                </div>
                <h2 style={{color:'white'}} className={'defaultTitle'}>Compras</h2>
                <div style={{ marginTop: '70px', opacity: this.state.opacity,marginBottom:50}}>
                    <ToastContainer autoClose={2000} />                    
                    <Row className="justify-content-md-center" >
                        <Col md={1} xs={1} sm={1} lg={1}>

                        </Col>
                        <Col md={5} xs={5} sm={5} lg={5}>
                            <Image src={'data:image/jpeg;base64,' + rifa.imagen} style={{ height: '70%', width: '70%' }} />
                        </Col>
                        <Col md={5} xs={5} sm={5} lg={5}>
                            <Row className="justify-content-md-center" >
                                <Card>
                                    <Card.Body>
                                        <Col xs="auto">
                                            <div>                                               
                                                <div className="mb-2">
                                                    <div  style={{display:'flex'}}>
                                                        <FormControl
                                                                style={this.state.styleTicket}
                                                                type="number"
                                                                placeholder="Número de boleta"
                                                                arial-label="Número de la boleta"
                                                                value={this.state.numberTicket}
                                                                onChange={(event) => { this.handleChange(event) }}
                                                            />
                                                        <div style={{backgroundColor:'#c2c2c2',width:40,height:40,}}>
                                                            <FontAwesomeIcon onClick={() => this.searchTicket()} style={{margin:'10 5 5 5'}} className="mr-2 align-self-center" icon={faSearch} /> 
                                                    
                                                        </div>    
                                                        </div>
                                                    <div style={{display:'flex',marginTop:5}}>
                                                        <FormControl
                                                            type="number"
                                                            disabled={true}
                                                            style={this.state.styleUser}
                                                            placeholder="Cédula"
                                                            arial-label="Número de identificación"
                                                            value={this.state.identification}
                                                            onChange={(event) => { this.handleChangeUser(event) }}
                                                        />
                                                        
                                                    </div>
                                                       
                                                </div>
                                                <div>
                                                    <p>
                                                        {rifa.name} <br />
                                                        {rifa.sorteo} <br />
                                                        {rifa.precio}<br />
                                                         Boleta: {this.state.viewTicket && this.state.numberTicket}<br />
                                                        Cliente:{this.state.userTicket}
                                                    </p>

                                                </div>
                                            </div>
                                        </Col>
                                    </Card.Body>
                                </Card>
                            </Row>
                            <Row className="justify-content-md-center">

                                <div style={{ width: '25rem', marginTop: '5px' }} >
                                    <ListPrices onClick={(i) => this.selectPrice(i)} prices={this.state.prices} /><br />
                                    {this.state.visible &&

                                        <NumberFormat className="form-control mt-1"
                                            thousandSeparator={true}
                                            prefix='$ '
                                            placeholder='$ Montos de Mil'
                                            maxLength={9}
                                            value={this.state.selectedPrice}
                                            onChange={(event) => {
                                                this.setState({ selectedPrice: parseInt(event.target.value.replace("$", "").replace(",", "").replace(",", "")) })
                                                this.calcularAbono(event.target.value, rifa.precio)
                                            }}
                                        />
                                    }
                                </div>

                            </Row>
                            <Row className="justify-content-md-center" style={{ marginTop: '5px' }}>
                                <div className='defaultTitle'>

                                    {this.state.buyMessage}
                                </div>
                            </Row>
                            <Row className="justify-content-md-center">
                                {this.state.userTicket && this.state.viewTicket && this.state.selectedPrice &&
                                    <Button onClick={() => this.payTicket()} variant={'info'} >Comprar</Button>}
                            </Row>
                            <Row style={{ marginTop: '5px' ,justifyContent:'space-between' ,width:'106%'}}>
                                <Button style={{width:'45%'}} onClick={() => this.numeroDeLaSuerte(true)} variant={'info'} >Número de la suerte</Button>
                                <Button style={{width:'45%'}} onClick={() => this.numeroDeLaSuerte(false)} variant={'info'} >Números para jugar</Button>
                            </Row>
                            {/* <Row className="justify-content-md-center" style={{ marginTop: '5px' }}>
                                <Button onClick={() => this.props.navigate('ValidarBoleta')} variant={'info'} >Validar Boleta</Button>
                            </Row> */}

                        </Col>
                        <Col md={1} xs={1} sm={1} lg={1}>
                        </Col>
                    </Row>

                </div>
                {this.state.visibleRegistro&&<ModalRegistro 
                        cedula={this.state.identification} 
                        setCliente={(cedula, nombre) => { this.setState({ userTicket: cedula + " - " + nombre, styleUser: { borderWidth: 2, borderColor: '#07A2BA' }}) }} 
                        id_product={rifa.id} 
                        visible={this.state.visibleRegistro} 
                        setParther={(id)=>this.setState({partner_id:id})} 
                        closeModal={(flag) => { this.showModal(flag); this.setState({ opacity: 1 }) }}
                        clientOdoo={this.state.client}
                        />}
                {this.numbersTicket.length>0&&<ModalBoletas selectTicket={(number) => this.selectTicket(number)}luckyNumber={this.state.luckyNumber} chooseTicket={(flag) => this.chooseTicket(flag)} numbersTicket={this.numbersTicket} visible={this.state.visibleBoletas} closeModal={(flag) => this.showModal(flag)} />}
                {this.state.pay&&<PayScreen 
                                    visible={this.state.pay} 
                                    closeModal={(flag,data)=>{this.setState({pay:flag});
                                                        this.navigateNext(flag,data)}} 
                                    product={this.state.product}/>}
            </>
        )
    }

}

