import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { clientSession } from '../utilities/odoo-config';
export const cambiarContrasena = async (cadena, callback) => {

  const args = [[0, cadena]];
  const config = await OdooConfig();//SESSION     
  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username:clientSession.user,
    password: clientSession.password
  });
  
  const params = {
    model: 'movilgo.webservice',
    method: 'cambiarContrasena',
  }

  client.connect((err, response) => {
    //console.log("err",err)
      //console.log("rese",response)  
    if (err) {          
      return callback(false,err) ;
    }  
    client.execute_kw(params.model, params.method, args, (err2, response2) => { 
      //console.log("err2",err2)
      //console.log("rese2",response2)     
      
      if (err2) {        
        
        return callback(false,err);
      }
      return callback(true,response2);
    });
    
  })
}

export const sendEmail = async (email, callback) => {

  const args = [[0, email]];
  const config = await OdooConfig();//SESSION  
  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username:clientSession.user,
    password: clientSession.password
  });
  
  const params = {
    model: 'movilgo.webservice',
    method: 'recuperarCuenta',
  }
  //console.log("salido el email",args)
  client.connect((err, response) => {
    ////console.log("cleinte coonec err",err)
    ////console.log("cleinte coonec res",response)
    if (err) {          
      return callback(false,err) ;
    }  
    client.execute_kw(params.model, params.method, args, (err2, response2) => {      
      //console.log("execute_kw coonec err",err)
      //console.log("execute_kw coonec res",response)
      
      if (err) {   
        return callback(false,err);
      }else{
        return callback(true,response);
      }
      
    });
    
  })
}
