import React, { PureComponent } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/modalNotificationTransaction';
import { cambiarContrasena } from '../../../services/nuevaContraseña.service';
class RecuperarContrasena extends PureComponent {

    constructor(props) {
        super(props);
        /* this.recargas = this.props.recargas; */
        this.state = {
            password:''
        }
    }

    
    componentDidMount = async() => {
       

    }
   
    recuperarPassword = () => {

        let url = window.location.search.split("&b=");

        let valor = url[0];
        let  llave = url[1];

        valor=valor.split("?a=")[1];
        //llave=llave.split("%27")[0];   

        valor = valor.trim();
        llave= llave.trim();

        let cadena = {
        valor: valor,
        llave: llave,
        password: this.state.password
        }

        cadena = JSON.stringify(cadena);

    
        //console.log("salida del la contraseña",cadena)
        cambiarContrasena(cadena,(flag, response)=>{
            //console.log("flag del cambiar contraseña",flag)
            //console.log("response del cambiar contraseña",response)
        if(flag){
            alert("¡ Haz cambiado tu contraseña, ya puedes ingresar de nuevo a tu cuenta MITECNOVA !");
            window.location = "/";
        }else{
            alert("¡¡ Ups!!!, Ocurrio algo, intenta de nuevo más tarde !");
        }
        
        })
    }

    
    
    render() {

        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                .contenedor{
                    padding: 0px 10vw 0px 5vw;
                    
                    
                }
                ` }
                </style>

                <div className="contenedor" style={{height:610,width:'50%',marginLeft:500 ,opacity: this.state.opacity,alignItems:'center'/* ,backgroundColor:'red' */ }} >

                    <ToastContainer />
                    <h2 style={{color:'white'}} className={'defaultTitle'}>Recuperar Contraseña</h2>
                    <Card className="justify-content-md-center">
                        <Card.Body>
                            <Container >
                                
                                <Row style={{marginLeft:100}}>                                    

                                    <Col md={12} xs={12} lg={6} className="my-auto">
                                        <Form.Group >                                            
                                            <Form.Control
                                                placeholder='contraseña'
                                                arial-label="Amount (to the nearest dollar)"
                                                value={this.state.phone}
                                                onChange={(event) => this.setState({ password: event.target.value.replace(/\D/, '') })}

                                            />
                                            <Form.Control
                                                placeholder=' repetir contraseña'
                                                arial-label="Amount (to the nearest dollar)"
                                                value={this.state.phone}
                                                onChange={(event) => this.setState({ password: event.target.value.replace(/\D/, '') })}

                                            />
                                        </Form.Group>

                                    

                                    </Col>


                                </Row>
                            </Container>
                        </Card.Body>
                        <Card.Footer className="text-muted">
                            <Row>
                                <Col>
                                    <Button size="lg" variant={'info'} onClick={()=>{this.recuperarPassword()}}>Cambiar Contraseña</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>

                </div>
                {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.closeModal(flag)} type={"Recarga"} message={"Recarga realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}


export default RecuperarContrasena;