import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Login } from '../../services/login.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faTimes } from '@fortawesome/free-solid-svg-icons';
import { LoadingMovilGo, } from '../../shared/loadingMovilGo';
import { InputForm } from '../../shared/inputs';
export const LoginComponent = (props) => {
    const { onLogin, viewModalLogin, loading } = props
    const [cargar, setCargar] = useState(false);
    const [user, setUser] = useState("");
    const [opacity, setOpacity] = useState(1);
    const [password, setPassword] = useState("");
    const [errUser, setErrUser] = useState("");
    const [errPass, setErrPass] = useState("");
    const login = async () => {
        let campos = [{ name: 'name', value: user, required: true, setError: setErrUser },
        { name: 'password', value: password, required: true, setError: setErrPass },]
// alguito
        //let allOk = true;
        campos.forEach(element => {
            const verify = verifyField(element.name, element.value, element.required, element.setError);
            if (verify) {
                
            }
        });
        
        const data = { user, password };
        if (user === '' && password === '') {
            onLogin(3, false)
        } else if (user === '') {
            onLogin(4, false)
        } else if (password === '') {
            onLogin(5, false)
        } else {
            setCargar(true);
            setOpacity(0.4);
            console.log("onLogin.....", onLogin)
            Login(data, onLogin);
        }
    }

    useEffect(() => {
        setCargar(false);
        setOpacity(1);
       
  
    }, [loading])
    const verifyField = (name, value, required, setError) => {
        let errorMessage = "";
        if (required) {
            if (typeof (value) == 'string') {
                if (value.trim() === "") {
                    errorMessage = "Este campo es obligatorio";

                }
            }
        }
        if (errorMessage !== "") {

            setError(errorMessage);
            return true;
        } else {
            return false;
        }
    }

    const handleClose = () => {
        viewModalLogin(false)
    }

   

    return (
            <div  style={{ position: 'absolute', marginTop: '50%', justifySelf:'center',textAlign:'center', zIndex: 2, }}>

                {cargar && <LoadingMovilGo />}

                    <Modal
                        style={{ opacity: opacity }}
                        size='lg'

                        show={true}
                        onHide={handleClose}
                    >
                        <Modal.Header style={{ backgroundColor: '#0D606E' }} >
                            <Modal.Title className={'defaultTitle'} style={{ color: 'white' }}>Iniciar Sesión</Modal.Title>
                            <Button onClick={() => handleClose()} type="button" style={{ backgroundColor: 'rgba(138,221,45,0)', border: 'none' }}>
                                <FontAwesomeIcon color={'white'} className="mr-2 align-self-center" icon={faTimes} />
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            

                            <InputForm
                                name={"name"}
                                title={<h5 style={{ color: '#07A2BA', textAlign: 'left' }} >Email</h5>}
                                required={true}
                                onBlur={(name, value, required, setError) => verifyField(name, value, required, setError)}
                                enterKey={login}
                                error={errUser}
                                value={user}
                                handleChange={(e) => { setUser(e) }}
                                setError={setErrUser}
                                placeholder="Usuario"
                            />

                            <InputForm
                                name={"password"}
                                title={<h5 style={{ color: '#07A2BA', textAlign: 'left' }} >Contraseña</h5>}
                                required={true}
                                onBlur={(name, value, required, setError) => verifyField(name, value, required, setError)}
                                error={errPass}
                                value={password}
                                handleChange={(e) => { setPassword(e) }}
                                setError={setErrPass}
                                enterKey={login}
                                type="password"
                                placeholder="Contraseña"
                            /> 

                        </Modal.Body>
                        <Modal.Footer>
                        <a target='_blank' href='OlvideContrasena'  >Olvide mi contraseña</a>
                            <Button disabled={cargar} variant='info' onClick={() => { login() }} >Iniciar Sesión</Button>
                        </Modal.Footer>

                    </Modal>
                
                
            </div>
            
            

    )
}

