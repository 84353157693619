
import React, {Component} from 'react';
import pdfC from '../../assets/documents/terminos.pdf'
  export default class Terms extends Component{
    constructor(props) {
        super(props)
        this.state={
        }
      }
      componentDidMount=()=>{
        console.log("componentDidMount del terms props")
      }
      render(){
        return(<>
               <div style={{width:'100%',height:'100%', alignItems:'center', justifyContent:'center',marginTop:'3%'}}>
                    <div style={{  width: '100%', height: 800, padding:15 }}>
                        <object
                            data={pdfC}
                            type="application/pdf"
                            width="100%"
                            height="100%"
                        >
                        </object>
                        
                    </div>
                </div>
                    
           </>)}
    }

