import React,{useEffect, useState} from "react";
import { Route,Routes  } from 'react-router-dom';
import HomeComponent from './screens/home/home';
import FirstHomeComponent from "./screens/home/firstHome";
import Abonos from './screens/rifas/components/abonar'
import Compras from './screens/rifas/components/comprar'
import RifasComponent from './screens/rifas/rifas-component'
import Recargas from "./screens/recargas/recargas";
import RecargasPines from "./screens/recargas-pines/recargas-pines";
import RecargasDeportivas from "./screens/recargas-deportivas/recargas-deportivas";
import { Desencrypt } from "./utilities/cipher";
import PaquetesComponent from "./screens/paquetes/paquetes-component";
import {SoatBuscar} from "./screens/soat/buscar/buscar";
import {SoatComprar} from "./screens/soat/comprar/comprar";
import {Soat} from "./screens/soat/soat";
import { CertificadoComponent } from "./screens/certificados/certificado";
import { PerfilComponent } from "./screens/Perfil/perfil";
import { Prestamos } from "./screens/prestamos/prestamos";
import { Caja } from "./screens/cajas/caja";
import RecuperarContrasena from "./screens/login/component/recuperarContrasena"
import ForgetPassword from "./screens/login/component/forgetPassword";
import ValidarBoleta from "./screens/rifas/components/validarBoleta";
import { useNavigate } from 'react-router-dom';
/* import Terminos from "./shared/documents/terms";
import Politics from "./shared/documents/politics"; */
import Terms from './shared/documents/terms'
import { PagosExternosScreen } from "./screens/PagosExterno/Pago";
import Politics from "./shared/documents/politics";
import HomeCardoso from "./screens/cardoso/cardoso";

const RoutesHome=(props)=>{
   const { deslogear,login} = props; 
   const data = Desencrypt();  
   const navigate = useNavigate();
   useEffect(() => {      
    }, [data])
   if(data){
      return(
          <Routes>
            <Route exact path="/" element={<HomeComponent/>} />
            <Route exact sensitive path="/Rifas" element={<RifasComponent />} />
            <Route exact sensitive path="/Rifas/Abonos" element={<Abonos navigate={(ruta)=>navigate(ruta)}/>} />
            <Route exact sensitive path="/Rifas/Compras" element={<Compras navigate={(ruta)=>navigate(ruta)}/>} />
            <Route exact sensitive path="/Recargas" element={<Recargas />} />
            <Route exact sensitive path="/Pines" element={<RecargasPines />} />
            <Route exact sensitive path="/Recargas-Deportivas" element={<RecargasDeportivas />} />
            <Route exact sensitive path="/Paquetes" element={<PaquetesComponent/>} />
            <Route exact sensitive path='/Soat' element={<Soat />} />
            <Route exact sensitive path='/Soat/Buscar' element={<SoatBuscar  />} />
            <Route exact sensitive path='/Soat/Comprar' element={<SoatComprar  />} /> 
            <Route exact sensitive path='/Certificados' element={<CertificadoComponent  />} /> 
            <Route exact sensitive path='/Perfil' element={<PerfilComponent  deslogeo={deslogear} />} /> 
            <Route exact sensitive path='/Prestamos' element={<Prestamos />} /> 
            <Route exact sensitive path='/Caja' element={<Caja />} /> 
            <Route exact sensitive path="/PagosExternos" element={<PagosExternosScreen navigate={(ruta)=>navigate(ruta)} />} />
            {/* <Route exact sensitive path='/validarBoleta' element={<ValidarBoleta />} />   */}
            {/* ?b=b%272kWHwRbWWLj9swFBduiTjw==%27 */}
          </Routes> 
          )
   }else{
      return(
        <Routes>
          <Route exact path="/" element={<FirstHomeComponent login={login}/>} />
          <Route exact sensitive path='/resetPassword' element={<RecuperarContrasena />} />
          <Route exact sensitive path='/OlvideContrasena' element={<ForgetPassword />} />
          <Route exact sensitive path='/ValidarBoleta' element={<ValidarBoleta />} />  
          <Route exact sensitive path="/Terms" element={<Terms/>} />
          <Route exact sensitive path="/Politics" element={<Politics/>} />
          <Route exact sensitive path="/PagosExternos" element={<PagosExternosScreen navigate={(ruta)=>navigate(ruta)}/>} />
          <Route exact path="/Cardoso" element={<HomeCardoso login={login}/>} />

        </Routes> 
      )
        
   }
    
}
export default RoutesHome;