import Odoo from 'odoo-xmlrpc';
import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';


export const searchCertification = async (id, callback) => {
  const args = [[0, { product_id: id }]];

  const config = await OdooConfig();
  const data = await Desencrypt();

  

  const params = {
    model: 'movilgo.webservice',
    method: 'certificadoConsultaOficinas',
  };

  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username: data.user,
    password: data.password
  });

  client.connect((err, response) => {
    if (err) {
      return callback(response, false)
    } else {
      client.execute_kw(params.model, params.method, args, (err, response) => {
        if (err) {
          callback(err, false);
          return false;
        }
        callback(response, true);
      });
    }
  })
};


export const searchSnr = async (id, code, license, callback) => {

  const args = [0, { product_id: id, snrOffice: code, snrLicense: license }];

  const config = await OdooConfig();
  const data = await Desencrypt();

 
  const params = {
    model: 'movilgo.webservice',
    method: 'certificadoConsultaMatricula',
  };

  const client = new Odoo({
    url: config.host,
    port: config.port,
    db: config.database,
    username: data.user,
    password: data.password
  });

  client.connect((err, response) => {
    if (err) {
      return callback(response, false)
    } else {
      client.execute_kw(params.model, params.method, args, (err, response) => {
        if (err) {
          callback(err, false);
          return false;
        }
        callback(response, true);
      });
    }
  })
};


