import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { Transaction } from '../../../services/product.service';
import { ListPrices } from './list';
import { ToastContainer, toast } from 'react-toastify';
import { ManagmentErrors } from '../../../shared/managmentErrors';
import { FormatMoney } from '../../../utilities/formats';
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/modalNotificationTransaction';
import { jsPdfGenerator } from '../../../shared/pdfTransaction';
import { PayScreen } from '../../../shared/pay/pay';
export const ModalAbonar = (props) => {
    const { visible, closeModal, dataTicket,navigate } = props
    const [active, setActive] = useState(true);
    const [visibleN, setVisibleN] = useState(false);
    const [loading, setLoading] = useState(false);
    const precio = [dataTicket.deuda, dataTicket.deuda / 2, "Otro valor"];
    const [modalNotification, setModalNotification] = useState(false);
    const [price, setPrice] = useState(0);
    const [pay, setPay] = useState(false);
    const [product, setProduct] = useState({});
    const [localProduct, setLocalProduct] = useState({});
    
    const callbackPayment = (flag, response) => {
        //console.log("callbackPayment",flag,response)
        
        
        if (!flag&&response) {
            const rifa = JSON.parse(localStorage.getItem('datosRifa'));
           
            const success = () => toast.success("Abono realizado exitosamente");
            success();
            setModalNotification(true)
            

            let data = [
                "Factura No: " + response.valida.id,
                "Fecha:" + response.valida.fecha,
                "No.Aprobacion:" + response.valida.numero_aprobacion,   
                "Producto: Abono " + rifa.name,                             
                "Resolucion: " + rifa.resolucion,
                "Fecha del sorteo: " + rifa.sorteo,
                "Número de boleta: " + dataTicket.boleta,
                "Cliente: " + dataTicket.nombre,
                "Valor total: " + FormatMoney(rifa.precio),
                "Valor abonado: " + FormatMoney(parseInt(price)),
                "Saldo pendiente: " + FormatMoney(dataTicket.deuda - price),
            ]
            
            navigate('/')
            jsPdfGenerator(data);
        }else{

            navigate('/')
        } 
        closeModal();
        setLoading(false);
        setVisibleN(false);
        setPrice('');
    }
    const paytmentTicket = async() => {
        const rifa = JSON.parse(localStorage.getItem('datosRifa'));
        const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal")); 
        if (price <= dataTicket.deuda) {
            setLoading(true);
            const idP = JSON.parse(localStorage.getItem('datosRifa'));
            const product = {
                product_id: idP.id, 
                clienteexterno_user_id:idClienteFinal, 
                atributes: {
                    numeroBoleta: dataTicket.boleta,
                    valorPagar: parseInt(price),
                    partner_id: dataTicket.partner_id,
                    vendedor_externo_id: dataTicket.vendedor_externo_id,
                    nombre_cliente: dataTicket.nombre
                }
            }
            setPay(true)
            setProduct(product)
            setLocalProduct({
                nameProduct:'rifa',
                nombreRifa:rifa.name,
                resolucion:rifa.resolucion,
                sorteo:rifa.sorteo,
                nombreCliente:dataTicket.nombre,
                tipo:"abonar",
                precioRifa:FormatMoney(rifa.precio),
                precioPagado:FormatMoney(parseInt(price)),
                saldo:FormatMoney(dataTicket.deuda - price)

            })
            //Transaction(product, callbackPayment)
        } else {
            const warn = () => toast.warning("¡Atención, no puede abonar " + FormatMoney(price) + " porque su deuda es de " + FormatMoney(dataTicket.deuda));
            warn();
            setActive(true);
            setVisibleN(false);
            setPrice('')
        }
    }
    const otherValue = (precio) => {
        if (precio === "Otro valor") {
            setVisibleN(true);
            setActive(false);
        } else
            setVisibleN(false);
        setActive(false);
    }

    return (

        <>
            <ToastContainer />

            <Modal
                centered
                show={visible}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className={'defaultTitle'}>Abonar a la rifa</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                            </div>
                            <ListPrices onClick={(precio) => { setPrice(precio); otherValue(precio) }} prices={precio} />
                            {visibleN && <NumberFormat className="form-control mt-1"
                                thousandSeparator={true}
                                prefix='$ '
                                placeholder='$ Montos de Mil'
                                value={price}
                                maxLength={9}
                                onChange={(event) => setPrice(parseInt(event.target.value.replace("$", "").replace(",", "")))}
                            />}
                        </div>
                        <Form.Label style={{ textAlign: 'center' }}>Abonar: {FormatMoney(price)}</Form.Label>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { closeModal(); setVisibleN(false); setPrice(0) }}>
                        Cancelar
                        </Button>
                    <Button disabled={active} variant="primary" onClick={() => paytmentTicket()} >Abonar</Button>
                </Modal.Footer>
            </Modal>
            {pay&&<PayScreen visible={pay} closeModal={(flag,data)=>{setPay(flag);
                                                                     callbackPayment(flag,data)  } } product={product} localProduct={localProduct}/>} 
                 
        </>


    )
}