import React from 'react';
import { FormatMoney, Capitalize } from '../../../utilities/formats';

export const Summary = (props) => {
    const { phone, operator, price, packet } = props

    return (
        <div> 
            <h5 className={'defaultTitle'}>Operador:</h5>
            <h5>{Capitalize(operator)}</h5>
            <h5 className={'defaultTitle'}>Celular:</h5>
            <h5>{phone}</h5>
            <h5 className={'defaultTitle'}>Paquete:</h5>
            <h6>{packet}</h6>
            <h5 className={'defaultTitle'}>Valor del paquete:</h5>
           {price && <h5>{FormatMoney(parseInt(price))}</h5>}
        </div>
    );
}
