import React, { PureComponent } from 'react';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { ModalNotificationTransaction } from '../../../shared/modalNotificationTransaction';
import { sendEmail } from '../../../services/nuevaContraseña.service';
import Header from '../../../shared/header/header';
class ForgetPassword extends PureComponent {

    constructor(props) {
        super(props);
        /* this.recargas = this.props.recargas; */
        this.state = {
            email:''
        }
    }

  
   
    recuperarCuenta  = () => {
        let correo = /^[^@\s]+@[^@\.\s]+(\.[^@\.\s]+)+$/;
        const validate = correo.test(this.state.email);
        if(this.state.email!==""&&validate){
            this.setState({loading:true})
            sendEmail({email:this.state.email.trim()},(flag,res)=>{
                console.log("respuesta del sendEmail flag",flag)
                console.log("respuesta del sendEmail res",res)
                this.setState({loading:false})
                if(flag){
                    const success = () => toast.success("Se envio exitosamente un email a tu correo para restrablecer tu contraseña");
                    success();
                }else{
                    const error = () => toast.error("Se presento un problema al enviar el correo");
                    error();
                }
            })

        }

    }

    
    
    render() {

        return (
            <>
               <div style={{position:'relative',index:1,display:'inlineBlock',marginTop:20}}>
                        <Header /* deslogear={deslogear} newlogeo={(flag)=>setDeslogear(flag)}  *//> 
                </div>

                <Container style={{height:610,width:'40%' }} >

                    <ToastContainer />
                    <div >
                        <h2 style={{color:'#0D606E',marginLeft:'22%',width:'70%',marginTop:'5%'}} className={'defaultTitle'}>¡Recuperación de contraseña!</h2>
                    </div>
                    
                    <Card  style={{width:'70%',marginLeft:'18%'}}>
                        <Card.Body> 
                            
                                <Row >                                    

                                        <Col md={12} xs={12} lg={12} className="my-auto">
                                            <Form.Group > 
                                                <Form.Control
                                                    placeholder=' Email'
                                                    value={this.state.email}
                                                    onChange={(event) => this.setState({ email: event.target.value})}

                                                />
                                            </Form.Group>

                                        

                                        </Col>


                                </Row>
                                                         
                                
                        </Card.Body>
                        <Card.Footer className="justify-content-md-center">
                            <Row>
                                <Col>
                                    <Button size="lg" variant={'info'} onClick={()=>this.recuperarCuenta()}>Enviar Email</Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>

                </Container>
                {this.state.modalNotification && <ModalNotificationTransaction closeModal={(flag) => this.closeModal(flag)} type={"Recarga"} message={"Recarga realizada exitosamente"} />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}


export default ForgetPassword;