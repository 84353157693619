import * as React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {  Button, Card, Container,FormControl,Col,Modal} from 'react-bootstrap';
import { getClient } from '../../services/cliente.service';
import { updateClient } from '../../services/cliente.service';
import { LoadingMovilGo} from '../../shared/loadingMovilGo'
import { updatePassword } from '../../services/cliente.service';
import Header from '../../shared/header/header';
import { ModalNotificationTransaction } from '../../shared/modalNotificationTransaction'; 
import HeaderCardoso from '../../shared/header/header-cardoso';
export class PerfilComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            active:false,
            data:{},
            newData:{},
            modalUpdateData:false,
            modalUpdatePassword:false,
            newPassword:'',
            newuser:false,
            deslogear:false,
            modalNotificacion:false,
            idHeader: localStorage.getItem('idDelPadre')
        }
    }

    componentDidMount = () => {
        this.setState({loading:true})
        getClient(this.responseClient)
    }
    responseClient=(response,flag)=>{
        console.log("responseClient",response)
        this.setState({loading:false})
        if(flag){
            this.setState({data:response,newData:response,active:true})

        }else{
            alert("No se obtuvieron datos del Usuario")
        }
       
    }
    updateData=()=>{
        this.setState({loading:true})
        updateClient(this.state.newData.id,this.state.newData,(flag)=>{
            this.setState({loading:false,modalUpdateData:false})
            if(flag){
                const success = () => toast.success("Datos Actualizados exitosamente");
                success();
                getClient(this.responseClient)
            }else{
                const error = () => toast.error('Se presento un error al actualizar los Datos');
                error();
            }

       })
    }

    updatePassword=()=>{
        //jejejej
        this.setState({loading:true})
        updatePassword(this.state.data.id,this.state.newPassword,(flag)=>{
            this.setState({loading:false})
            if(flag){
                const success = () => toast.success("Contraseña Actualizada Correctamente");
                success();
                this.setState({loading:false,modalNotificacion:true})
          
            }else{
                const error = () => toast.error('Se presento un error al actualizar la contraseña');
                error();
            }

        })
    }
    closeModal=(flag)=>{
        
        this.setState({active:true,deslogear:true})
    }
    render() {
        return (
            <>
            <ToastContainer/>
           {/*  {this.state.newuser && ( <Navigate to="/" replace={true} />)} */}
            <div style={{position:'relative',index:1,display:'inlineBlock',marginTop:20}}>
            {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
            </div>
            <Container style={{height:570, marginTop:50}}>

                {this.state.active&&<Card className='reloadsCard'>
                    <Card.Body>
                        <div>
                            <div style={{borderBottomWidth: 1,backgroundColor: "#07A2BA",textAlign: "center",}}>
                                <p style={{ fontWeight: "bold" }}>Datos Personales</p>
                            </div>
                            <div style={styles.rowContainer} className="row">
                                <div style={styles.colContainer}>
                                    <p style={styles.title}>Nombre </p>
                                    <p> {this.state.data.x_name1+" "+this.state.data.x_name2} </p>
                                </div>
                                <div style={styles.colContainer}>
                                    <p style={styles.title}>Apellido</p>
                                    <p> {this.state.data.x_lastname1+" "+this.state.data.x_lastname2} </p>
                                </div>
                                <div style={styles.colContainer}>
                                    <p style={styles.title}>Documento</p>
                                    <p> {this.state.data.xidentification} </p>
                                </div>
                            </div>
                            <div style={styles.rowContainer} className="row">
                                <div style={styles.colContainer}>
                                    <p style={styles.title}>Email </p>
                                    <p> {this.state.data.email} </p>
                                </div>
                                <div style={styles.colContainer}>
                                    <p style={styles.title}>Direccion</p>
                                    <p> {this.state.data.street} </p>
                                </div>
                                <div style={styles.colContainer}>
                                    <p style={styles.title}>Constraseña</p>
                                    <p> {this.state.data.clave} </p>
                                </div>
                            </div>
                            <div style={styles.rowContainer} className="row">
                                <div style={styles.colContainer}>
                                    <p style={styles.title}>Movil </p>
                                    <p> {this.state.data.mobile} </p>
                                </div>
                                <div style={styles.colContainer}>
                                   
                                </div>
                                <div style={styles.colContainer}>
                                   
                                </div>
                            </div>
                        </div>
                        
                    </Card.Body>
                    <Col>
                        <Card.Footer className="text-muted" > 
                            <Button size="lg" style={{backgroundColor:"#07A2BA",marginLeft:'40%'}} onClick={()=> this.setState({modalUpdateData:true})}>Editar Datos</Button>
                            <Button size="lg" style={{backgroundColor:"#07A2BA",marginLeft:20}} onClick={()=> this.setState({modalUpdatePassword:true})}>Editar Contraseña</Button>
                        </Card.Footer>
                    </Col>
                </Card>}

                <Modal show={this.state.modalUpdateData} onHide={()=>this.setState({modalUpdateData:!this.state.modalUpdateData})}>
                <Modal.Header closeButton>
                    <Modal.Title>Actualizar Datos Personales</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="justify-content-md-center col-xs-12 mb-2"  >
                        <p style={styles.title}>Prime Nombre</p>
                        <FormControl                        
                        value={this.state.newData.x_name1}
                        onChange={(e) => {
                            this.setState(prev => {
                                let newData = Object.assign({}, prev.newData); 
                                newData.x_name1 = e.target.value; 
                                                 
                                return { newData };               
                                    })
                        }}
                        />
                        <p style={styles.title}>Segundo Nombre</p>
                        <FormControl
                        value={this.state.newData.x_name2}
                        onChange={(e) => {
                            this.setState(prev => {
                                let newData = Object.assign({}, prev.newData); 
                                newData.x_name2 = e.target.value; 
                                                 
                                return { newData };               
                                    })
                        }}
                        />
                        <p style={styles.title}>Prime Apellido</p>
                        <FormControl
                        value={this.state.newData.x_lastname1}
                        onChange={(e) => {
                            this.setState(prev => {
                                let newData = Object.assign({}, prev.newData); 
                                newData.x_lastname1 = e.target.value; 
                                                 
                                return { newData };               
                                    })
                        }}
                        />
                        <p style={styles.title}>Segundo Apellido</p>
                        <FormControl
                        value={this.state.newData.x_lastname2}
                        onChange={(e) => {
                            this.setState(prev => {
                                let newData = Object.assign({}, prev.newData); 
                                newData.x_lastname2 = e.target.value; 
                                                 
                                return { newData };               
                                    })
                        }}
                        />
                        <p style={styles.title}>Movil</p>
                        <FormControl
                        value={this.state.newData.mobile}
                        onChange={(e) => {
                            this.setState(prev => {
                                let newData = Object.assign({}, prev.newData); 
                                newData.mobile = e.target.value; 
                                                 
                                return { newData };               
                                    })
                        }}
                        />
                        <p style={styles.title}>Direccion</p>
                        <FormControl
                        value={this.state.newData.street}
                        onChange={(e) => {
                            this.setState(prev => {
                                let newData = Object.assign({}, prev.newData); 
                                newData.street = e.target.value; 
                                                 
                                return { newData };               
                                    })
                        }}
                        />
                    </div>
                    <div style={{justifyContent:"center"}}>
                       {this.state.loading&& <LoadingMovilGo left={'20%'}/>} 
                    </div>
                    
                </Modal.Body>
                
                <Modal.Footer>
                    <div style={{ width: '100%', }}>
                        <div style={{ textAlign: 'center' }}>
                            <Button onClick={() => this.updateData()} className='m-2' size="lg" variant="info" >
                                Actualizar
                             </Button>
                        </div>

                    </div>
                </Modal.Footer>
               
            </Modal>
             <Modal show={this.state.modalUpdatePassword} onHide={()=>this.setState({modalUpdatePassword:!this.state.modalUpdatePassword})}>
                <Modal.Header closeButton>
                    <Modal.Title>Actualizar Contraseña</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="justify-content-md-center col-xs-12 mb-2"  >
                        <p style={styles.title}>Constraseña</p>
                        <p >{this.state.newData.clave}</p>
                        <p style={styles.title}>Nueva Constraseña</p>
                        <FormControl
                        value={this.state.newPassword}
                        onChange={(e) => {
                            this.setState({newPassword:e.target.value})
                        }}
                        />
                    </div>
                    <div style={{marginLeft:130}}>
                         {this.state.loading&& <LoadingMovilGo  left={'20%'}/>}
                    </div>                  
                    {this.state.modalNotificacion && <ModalNotificationTransaction 
                                                closeModal={(flag) => {this.closeModal(flag)}} 
                                                reponse={null}    
                                                message={"El cambio de contrasela fue exitoso debes volver a logear"} />}    
                </Modal.Body>
                
                <Modal.Footer>
                    <div style={{ width: '100%', }}>
                        <div style={{ textAlign: 'center' }}>
                            <Button onClick={() => this.updatePassword()} className='m-2' size="lg" variant="info" >
                                Actualizar
                             </Button>
                        </div>

                    </div>
                </Modal.Footer>
               
            </Modal>
            </Container>
            </>
        )
    }
}    
const styles = {
    title: {
      fontWeight: "bold",
      align: "center",
    },
    container: {
      paddingHorizontal: 10,
      maxHeight: "100%",
      //backgroundColor: '#078FA5'
    },
    titleSummary: {
      alignSelf: "center",
      fontWeight: "bold",
      fontSize: 18,
      color: "#07A2BA",
    },
    colContainer: {
      width: "32%",
    },
    rowContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginVertical: 5,
      width: "100%",
      paddingLeft: 15,
    },
  };