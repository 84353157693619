import React, { PureComponent } from 'react';
import { Button, Card, Row, Container, FormControl } from 'react-bootstrap';
import { searchPolisaSoat } from '../../../services/soat.service';
import { DateToString } from '../../../utilities/formats';
import { LoadingMovilGo } from '../../../shared/loadingMovilGo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LinkSoat } from './link';
import Header from '../../../shared/header/header';
export class SoatBuscar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            Numberplate: "",
            deslogear:false,
            email: "",
            InsurancePolicyNumber: "",
            date: this.getDate(1),
            loading: false,
            soats: [{ id: -1 }],
            source_request: "W",
            linkres: "",
            modalLink: false,
        }
        this.soats=[]
        this.minDate = this.getDate(1);
    }

    getDate = (addDays = 0) => {
        let date = new Date();
        date.setDate(date.getDate() + addDays);
        return DateToString(date, true);
    }

    clearData = () => {
        this.setState({
            loading: false,
            date: this.getDate(1),
            Numberplate: "",
            licensePlate: "",
            email: ""
        })
    }
    componentDidMount =async () => {
        const product = await JSON.parse(localStorage.getItem("products"));        
        
        if (product.soats) {
           this.soats = product.soats;  
            this.setState({ loading: false })
           
        }
      
    }
    verifyFields = (Numberplate, InsurancePolicyNumber, email) => {

        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
       
        if (Numberplate === "") {
            const error = () => toast.error("Error, El número de placa o póliza es obligatorio");
            error();   
        } else if (email === "") {
            const error = () => toast.error("Error, El E-mail es obligatorio");
            error();  
        } else if (!emailValid) {
            const error = () => toast.error("Error, El E-mail es incorrecto");
            error(); 
        } else {
          
            searchPolisaSoat(
                    this.state.source_request,
                    this.state.Numberplate.trim(),
                    this.state.InsurancePolicyNumber.trim(),
                    this.soats[0].id,
                    this.state.email.trim(),
                    this.setSearchedData)
           
        }
    }


    setSearchedData = (res, flag) => {
        if (flag) {
            if (res.errores) {
                const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuníquese con MóvilGo" /* + res.errores.observacion */);
                error(); 
            } else {
           
                this.setState({ linkres: res.policyData })                
                this.setState({ modalLink: true })
                this.clearData();
              
            }
        } else {
            const error = () => toast.error("Error, Por favor verifique los datos. Si el error persiste comuníquese con MóvilGo");
            error();
            if (error) { this.clearData(); }
           
        }
    }

    render() {
        return (
        <>
            <div style={{position:'relative',index:1,display:'inlineBlock',marginTop:20}}>
                        <Header deslogear={this.state.deslogear} newlogeo={(flag)=>this.setState({deslogear:flag})} /> 
            </div>
            <Container >
                <ToastContainer />
                <Row className="justify-content-md-center" style={{height:580,marginTop:'5%'}}>
                    <Card className='reloadsCard' style={{ width: '40%',height:'60%' }}>
                        <Card.Body>
                            <div>
                                <div> <h3>Ingrese número de placa</h3> </div>
                                <FormControl 
                                    placeholder='Es Obligatorio'
                                    value={this.state.Numberplate}
                                    onChange={e => this.setState({ Numberplate: e.target.value })}
                                />
                            </div>
                            <div>
                                <div> <h3>Ingrese número de póliza</h3> </div>
                                <FormControl 
                                    placeholder='No es Obligatorio'
                                    value={this.state.InsurancePolicyNumber}
                                    onChange={(event) => this.setState({ InsurancePolicyNumber: event.target.value })}
                                />
                            </div>
                            <div>
                                <div> <h3>E-mail</h3> </div>
                                <FormControl 
                                    placeholder='Es Obligatorio'
                                    value={this.state.email}
                                    onChange={(event) => this.setState({ email: event.target.value })}
                                />
                            </div>
                            <Button variant={'info'} className="mt-2"
                                onClick={() => {
                                    this.verifyFields(//es necesario mandar estos 3 valores a la función?
                                        this.state.Numberplate,
                                        this.state.InsurancePolicyNumber,
                                        this.state.email)
                                }}
                            > Buscar
                            </Button>
                        </Card.Body>
                    </Card>
                    {this.state.loading && <LoadingMovilGo />}
                    {this.state.modalLink && <LinkSoat name="Descargue su SOAT"
                        closeModal={() => this.setState({ modalLink: false })}
                        response={this.state.linkres}
                        msj="Presiona aquí para descargar tu SOAT. Recuerda usar el número de placa en Mayúscula para abrirlo"
                    /* closeModal={(flag) => this.closeModal(flag)}  */
                    />}
                </Row>
            </Container>
        </>
            
        )
    }
}
