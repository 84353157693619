import React, { useState }  from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { LoadingMovilGoRegistro } from '../../loadingMovilGo';
import { registNewUser } from '../../../services/cliente.service';
import { Login } from '../../../services/login.service';
import { idClienteFinal } from '../../../utilities/odoo-config';
export const Register =(props)=>{
    const { visible, closeModal, onLogin,idPadre } = props;
    //const [cedula, setCedula] = useState('');o
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [identificacion, setIdentificacion] = useState('');
    const [email, setEmail] = useState('');
    const [celular, setCelular] = useState('');
    const [clave, setClave] = useState('');
    const [repeatClave, setRepeatClave] = useState('');    
    const [direccion, setDireccion] = useState('');
    const [politicas, setPoliticas] = useState(false);
    const [terminos, setTerminos] = useState(false);    
    const [opacity, setOpacity] = useState(1);
    const [loading, setLoading] = useState(false);
    let correo = /^[^@\s]+@[^@\.\s]+(\.[^@\.\s]+)+$/;
    const [nombreError, setNombreError] = useState(false);
    const [apellidoError, setApellidoError] = useState(false);
    const [identificacionError, setIdentificacionError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [celularError, setCelularError] = useState(false);
    const [claveError, setClaveError] = useState(false);
    const [repeatClaveError, setRepeatClaveError] = useState(false);    
    const [direccionError, setDireccionError] = useState(false);
    const [checkBoxAlertTerminos, setCheckBoxAlertTerminos] = useState(false);
    const [checkBoxAlertPoliticas, setCheckBoxAlertPoliticas] = useState(false);
    const linkTerminos='https://drive.google.com/file/d/1esEYVUmb4JFzoT-OQaWbBydmxwkEBAMH/view?usp=sharing';
    const linkPoliticasPrivacidad='https://drive.google.com/file/d/1ijD3vGX9gW5PS4ikgscDam9BbxLSgcqc/view?usp=sharing'
    const texto="es Invalido o muy corto"
    
    const saved = (response,flag) => {
        console.log("saved falg",flag)
        console.log("saved response",response)
        setLoading(false)

        if (flag) {

            if(response[0].errores){
                if(response[0].errores.error){
                    if(response[0].errores.error.includes("ERROR:  duplicate key value violates unique")){
                        alert("El usuario ya se encuentra registrado") 
                    }else if(response[0].errores.error){
                        alert(response[0].errores.error) 
                    }
                }else{
                    alert("Se presento un error")
                }  
            }else if(response.errores){
                if(response.errores.error){
                    if(response.errores.error.includes("integer out of range")){
                        alert("El numero del documento no esta dentro del rango permitido")
                    }else{
                        alert("Se presento un error al realizar el registro")
                    }
                }else{
                    alert("Se presento un error al realizar el registro")
                }
                
                

            }else{
                
                Login({user:email,password:clave}, onLogin);
                setOpacity(1)
            }
            
            //closeModal(false);

        }else{
            alert("Se presento un error al realizar el registro") 
        }
        cleanFields()
    }

    const cleanFields = () => {
        setNombre('');
        setApellido('');
        setIdentificacion('');
        setClave('');
        setRepeatClave('')
        setDireccion('');
        setEmail('');
        setCelular('');
        setPoliticas(false)
        setTerminos(false)
    }
    const registrarCliente = () => {
        if(onBlur(true,true,true,true,true,true,true,true,true,true)){   
            console.log("id padre a registrar", idPadre)    
            // el id  344 pertenece al usuario cliente final  del entorno al que este apuntando 
            let sendData={
                id:parseInt(idPadre),
                cliente:{
                    team_members_ids:[[0,parseInt(idPadre),{
                        comment:false,
                        codigo:identificacion,
                        message_follower_ids:false,
                        create_date:false,
                        personType:1,
                        permiso:false,
                        team_papas:[],
                        property_product_pricelist_id:9,
                        team_hijos:[],
                        x_name1 : nombre.split(' ')[0],
                        in_group_70: true,
                        x_name2 :  nombre.split(' ')[1],
                        x_lastname1:apellido.split(' ')[0],
                        x_lastname2:apellido.split(' ')[1],
                        in_group_55: false,
                        in_group_56: false,
                        in_group_57: false,
                        xidentification:identificacion,
                        message_ids:false,
                        email:email,
                        child_ids:[],
                        phone:false,
                        active:true,
                        tope:0,
                        mobile:celular,
                        doctype:13,
                        street:direccion,
                        caja_id:false,
                        login:email,
                        clave:clave,
                    }]]
                }
                
                
            }
            setLoading(true);
            //setOpacity(0.4)
            console.log("sendData jejeje",sendData)
            registNewUser(sendData, saved);
        }
    }

    const onBlur = (name,lasname,identification,emai,mobil,adress,password,repeatpassword,term,poli) => {
        if(name){
            if(nombre===''){
                setNombreError(true)
                return false
            }else if (nombre.length<2){
                setNombreError(true)
                return false
            }else{
                setNombreError(false)
            }

        }
        if(lasname){
            if(apellido===""){
                setApellidoError(true)
                return false
            }else if (apellido.length<4){
                setApellidoError(true)
                return false
            }else{
                setApellidoError(false)
            }

        }
        if(identification){
            if(identificacion===""){
                setIdentificacionError(true)
                return false
            }else if (identificacion.length<6 || identificacion.length>10){
                setIdentificacionError(true)
                return false
            }else{
                setIdentificacionError(false)
            }

        }
        if(emai){
           const validate = correo.test(email); 
            if(email===""){
                setEmailError(true)
                return false
            }else if (!validate){
                setEmailError(true)
                return false
            }else{
                setEmailError(false)
            }

        }
        if(mobil){
            if(celular===""){
                setCelularError(true)
                return false
            }else if (celular.length>11||celular.length<7){
                setCelularError(true)
                return false
            }else{
                setCelularError(false)
            }

        }
        if(adress){
            if(direccion===""){
                setDireccionError(true)
                return false
            }else{
                setDireccionError(false)                
            }
        }
        if(password){
            if(clave===""){
                setClaveError(true)
                return false
            }else if (clave<4){
                setClaveError(true)
                return false
            }else{
                setClaveError(false)
            }

        }
        if(repeatpassword){
            if(clave!==repeatClave){           
                setRepeatClaveError(true)
                return false
            }else{
                setRepeatClaveError(false)
            }

        }
        if(term){
            if(!terminos){           
                setCheckBoxAlertTerminos(true)
                return false
            }else{
                setCheckBoxAlertTerminos(false)
            }

        }
        if(poli){
            if(!politicas){           
                setCheckBoxAlertPoliticas(true)
                return false
            }else{
                setCheckBoxAlertPoliticas(false)
            }

        }

        return true

        
    }

    

    return (
        <>
            <Modal
                size='lg'
                show={visible}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title className={'defaultTitle'}>Crea Tu Nuevo Usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form style={{ width: '100%', height: '100%' }}>
                        <div>
                            <div style={{ textAlign: 'center' }}>
                                {loading && < LoadingMovilGoRegistro />}
                            </div>
                            <div style={{ opacity: opacity }}>
                                <Form.Group className={'defaultTitle'}>
                                    
                                    <Form.Label>Nombre</Form.Label>
                                    <Form.Control 
                                        onBlur={() => onBlur(true,false,false,false,false,false,false,false,false,false)} 
                                        value={nombre} 
                                        onChange={(event) => setNombre(event.target.value)} 
                                        type="text" 
                                        placeholder="Nombre" />
                                        {nombreError && <p style={{ fontSize: 12, color: 'red' }}>Nombre {texto}<br></br></p>}
                                    <Form.Label>Apellido</Form.Label>                                    
                                    <Form.Control 
                                        onBlur={() => onBlur(false,true,false,false,false,false,false,false,false,false)} 
                                        value={apellido} 
                                        onChange={(event) => setApellido(event.target.value)} 
                                        type="text" 
                                        placeholder="Apellido" />
                                        {apellidoError && <p style={{ fontSize: 12, color: 'red' }}>Apellido  {texto}<br></br></p>}
                                        <Form.Label>Identificación</Form.Label>
                                    <Form.Control 
                                        onBlur={() => onBlur(false,false,true,false,false,false,false,false,false,false)} 
                                        value={identificacion} 
                                        onChange={(event) => setIdentificacion(event.target.value)} 
                                        type="number"
                                        maxLength={10}
                                        placeholder="No de Documento" />
                                        {identificacionError && <p style={{ fontSize: 12, color: 'red' }}>Identificacion no debe superar 10 caracteres, {texto}<br></br></p>}
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control 
                                        onBlur={() => onBlur(false,false,false,true,false,false,false,false,false,false)} 
                                        value={email} 
                                        onChange={(event) => setEmail(event.target.value)} 
                                        type="email" 
                                        placeholder="Correo Electonico" />
                                        {emailError && <p style={{ fontSize: 12, color: 'red' }}>Correo es obligatiro o esta en un formato invalido <br></br></p>}
                                    <Form.Label >Celular</Form.Label>
                                    <Form.Control 
                                        onBlur={() => onBlur(false,false,false,false,true,false,false,false,false,false)} 
                                        value={celular} 
                                        onChange={(event) => setCelular(event.target.value.replace(/\D/, ''))} 
                                        arial-label="Amount (to the nearest dollar)"
                                        maxLength={10} 
                                        placeholder="Celular" />
                                        {celularError && <p style={{ fontSize: 12, color: 'red' }}>Celular  {texto} <br></br></p>}
                                    <Form.Label>Direccion</Form.Label>
                                    <Form.Control 
                                        onBlur={() => onBlur(false,false,false,false,false,true,false,false,false,false)}  
                                        value={direccion} 
                                        onChange={(event) => setDireccion(event.target.value)} 
                                        type="text" 
                                        placeholder="Direccion" />
                                        {direccionError && <p style={{ fontSize: 12, color: 'red' }}>Direccion  {texto} <br></br></p>}
                                    <Form.Label >Contraseña</Form.Label>
                                    <Form.Control 
                                        onBlur={() => onBlur(false,false,false,false,false,false,true,false,false,false)} 
                                        value={clave} 
                                        onChange={(event) => setClave(event.target.value)} 
                                        type="text" 
                                        placeholder="Contraseña" />
                                        {claveError && <p style={{ fontSize: 12, color: 'red' }}>Constraseña  {texto} <br></br></p>}
                                    <Form.Label >Confirmar Contraseña</Form.Label>
                                    <Form.Control 
                                        onBlur={() => onBlur(false,false,false,false,false,false,false,false,false)} 
                                        value={repeatClave} 
                                        onChange={(event) => setRepeatClave(event.target.value)} 
                                        type="text" 
                                        placeholder="Confirmar Contraseña" />
                                        {repeatClaveError && <p style={{ fontSize: 12, color: 'red' }}>Confirmar la contraseña es requerida o no coincide <br></br></p>}
                                </Form.Group>
                            </div>
                        </div>
                    </Form>
                    <div>
                        <input
                            className="mr-2"
                            type="checkbox"
                            checked={terminos}
                            onChange={() => setTerminos(!terminos)}
                        />
                        <a target='_blank' href='/Terms' >TÉRMINOS Y CONDICIONES PLATAFORMA MÓVIL GO S.A.S</a>
                        {checkBoxAlertTerminos &&<h6 style={{ color: ' red' }}>
                                Acepta por favor terminos y condiciones</h6>}
                    </div>
                    <div>
                        <input
                            className="mr-2"
                            type="checkbox"
                            checked={politicas}
                            onChange={() => setPoliticas(!politicas)}
                        />
                        <a target='_blank' href='/Politics' >
                            POLÍTICA DE PRIVACIDAD
                        </a>
                        {checkBoxAlertPoliticas &&
                            <h6 style={{ color: ' red' }}>
                                Acepta por favor las politicas de privacidad
                            </h6>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {closeModal(false)
                                                            cleanFields()}}>
                        Cancelar
                        </Button>
                    <Button
                        variant="info"
                        onClick={() => registrarCliente()}
                        /* disabled={!identificacion || !nombre || !apellido || !direccion || !celular || (!correo.test(email) ? email : "")} */
                    >
                        Crear Cliente
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}   