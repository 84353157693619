import React, { PureComponent } from 'react';
import { ListOperator } from '../../shared/list-operator';
import woman from '../../assets/home/img4.png';
import { Summary } from './components/summary';
import { Row, Col, Form, Card, Button, Image } from 'react-bootstrap';
import { Transaction } from '../../services/product.service';
import { LoadingMovilGo } from '../../shared/loadingMovilGo';
import { ToastContainer, toast } from 'react-toastify';
import { ManagmentErrors } from '../../shared/managmentErrors';
import { ModalNotificationTransaction } from '../../shared/modalNotificationTransaction';
import { jsPdfGenerator } from '../../shared/pdfTransaction';
import { FormatMoney } from '../../utilities/formats';
import { Carrusel } from '../../shared/carousel';
import Header from '../../shared/header/header';
import { times } from 'lodash';
import { PayScreen } from '../../shared/pay/pay';
import { Url_home, idClienteFinal } from '../../utilities/odoo-config';
import HeaderCardoso from '../../shared/header/header-cardoso';
class RecargasPines extends PureComponent {

    constructor(props) {
        super(props);
        this.categoryPin = [];
        this.eachObjPin = [];
        this.nameGroupPin = [];
        this.nameEachPin = {};
        this.paquetes = [];
        this.packName = [];
        this.state = {
            deslogear: false,
            selectedType: '',
            price: [],
            name: '',
            idPackage: -1,
            visible: false,
            viewSummary: false,
            loading: true,
            opacity: 1,
            selectedPin: { id: -1, image_medium: null, name: '' },
            id_pin: -1,
            pines: [],
            operators: [],
            vigencia: 0,
            phone: '',
            pinPackage: '',
            selectedPrice: 0,
            buttonsPines: [<div key={'key' + 1}></div>],
            email: '',
            selectedProduct: {},
            //showAnotherValueInput: false,
            modalInfoPines: false,
            modalNotification: false,
            resumeView: <></>,
            listTypes: <></>,
            errorEmail: '',
            product: {},
            localProduct: {},
            active: false,
            // stateButtons: [false, false, false, false, false, false],
            carrouselView: false,
            idHeader: localStorage.getItem('idDelPadre')

        }
        this.namePacket = '';
        this.packPinByOperator = {};
        this.listePricesByOperator = [];
        //this.paquetesPines = this.props.paquetesPines;
        this.paquetesPines = [];
        this.pinesByOperator = [];
        this.response = [];
        this.phone = '';
        this.valor = '';
        this.operador = '';
        this.clean = ''
    }

    cleanField = () => {
        this.setState({
            clean: false,
            name: '',
            price: ''
        })
    }

    componentDidMount = async () => {

        const product = await JSON.parse(localStorage.getItem("products"));
        if (product.pines && product.paquetesPines) {
            this.categoryPin = product.pines;
            this.eachObjPin = product.paquetesPines;
            if (this.categoryPin || this.eachObjPin) {
                this.setState({ loading: false, active: true })
                this.initialice();
            }
        } else {
            this.setState({ loading: false })
            const error = () => toast.error("Este producto no esta disponible");
            error();
        }

    }

    initialice = () => {

        let nameGroup = []
        this.categoryPin.forEach(element => {
            if (element.operador) {
                nameGroup.push(element.operador)
            }
        })


        this.nameGroupPin = nameGroup;

        let listKindPin = [];
        this.eachObjPin.forEach(element => {
            if (listKindPin[element.operador]) {
                listKindPin[element.operador].push(element);
            } else {
                listKindPin[element.operador] = [];
                listKindPin[element.operador].push(element);
            }
        })
        this.setState({ listKindPin });
    }

    componentWillUnmount = () => {
        this.packPinByOperator = {};
    }

    sendData = async () => {
        //const idClienteFinal=await JSON.parse(localStorage.getItem("idClienteFinal"));
        this.namePacket = this.state.name;
        this.phone = this.state.phone;
        this.valor = this.state.price;
        this.operador = this.state.operador
        //console.log("datos de pines",)
        this.setState({ loading: true, opacity: 0.4 })
        const product = {
            product_id: this.state.idPackage,
            clienteexterno_user_id: idClienteFinal,
            atributes: {
                correo: this.state.email,
                numero: this.state.phone,
                precio: this.state.price
            }
        }
        this.setState({
            pay: true, product: product,
            localProduct: {
                nameProduct: 'pines',
                nombrePin: this.namePacket,
                phone: this.phone,
                valor: FormatMoney(this.valor)


            }
        })
        await Transaction(product, this.getResponse);
    }

    getResponse = (flag, response) => {

        if (flag) {
            this.response = response
            const success = () => toast.success("Producto comprado exitosamente");
            /* this.setState({phone: '',email:'',checkPhone:false }); */
            success();
            this.setState({ loading: false, opacity: 1, modalNotification: true, phone: '', email: '', checkPhone: false, name: '', operador: '', price: '' })
        } else {
            const message = ManagmentErrors(response.errores.id);
            const error = () => toast.error(message);
            error();
            this.setState({ loading: false, opacity: 1, phone: '', email: '', checkPhone: false, name: '', operador: '', price: '' })
        }
    }

    selectOperator = (operator) => {

        this.cleanField();
        let paquetes = this.state.listKindPin[operator]
        let operador = operator
        this.setState({ paquetes })
        this.setState({ operador })
        this.setState({ carrouselView: true })
    }

    closeModal = (flag) => {
        this.setState({ modalNotification: flag });
        const data = [
            "Factura No: " + this.response.valida.id,
            "Fecha:" + this.response.valida.fecha,
            "No.Aprobacion:" + this.response.valida.numero_aprobacion,
            "Producto:  " + this.namePacket,
            "Celular: " + this.phone,
            "Valor: " + FormatMoney(this.valor)
        ]
        jsPdfGenerator(data);
    }

    render() {
        return (
            <>
                <style>
                    {`
                .my-auto {
                    margin-top: auto;
                    margin-bottom: auto;
                } 
                .reloadsCard {
                    box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.17);
                    border-radius: 1em;
                    margin: 5px;
                }
                ` }
                </style>
                <div style={{ position: 'relative', index: 1, display: 'inlineBlock', marginTop: 20 }}>
                {this.state.idHeader == "cardoso" ? <HeaderCardoso showSeller={false} deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} /> : <Header
                                                                deslogear={this.state.deslogear} 
                                                                newlogeo={(flag)=>this.setState({deslogear:flag})} />} 
                </div>
                <h2 style={{ color: 'white' }} className={'defaultTitle'}>Pines</h2>
                <div className="contenedor" style={{ width: '90%', height: 600, marginLeft: 100 }} >

                    {this.state.idHeader !== "movilgo" ?
                        <div>
                            <ToastContainer />
                            {this.state.active && <div>
                                <Row className="justify-content-md-center">
                                    {/* MUESTRA LISTADO DE PINES  */}
                                    <Col>
                                        {this.state.operators &&
                                            <ListOperator onClick={(operators) =>
                                                this.selectOperator(operators.operador)}
                                                operators={(this.categoryPin)}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Card className='reloadsCard'>
                                    <Card.Body>
                                        <Row className="justify-content-md-center">
                                            {<Col xs lg="2">
                                                {this.state.selectedPin.image_medium &&
                                                    <Image src={'data:image/jpeg;base64,' + this.state.selectedPin.image_medium}
                                                        style={{ maxHeight: '80px' }} />}
                                            </Col>}
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col xs={12} md={9} style={{ padding: "10px" }}>
                                                {this.state.carrouselView &&
                                                    <Carrusel onClick={(namePacket, pricePacket, id) => {

                                                        this.setState({ name: namePacket, price: pricePacket, idPackage: id })
                                                    }}
                                                        packages={this.state.paquetes}
                                                        operador={this.state.selectedPin.operador}
                                                        type={this.state.selectedType} />
                                                }
                                            </Col>

                                            <Col xs={12} md={3}>
                                                <Form.Group >
                                                    <h4 className={'defaultTitle'}>Celular</h4>
                                                    <Form.Control
                                                        className="text-center"
                                                        placeholder='Celular'
                                                        arial-label="Amount (to the nearest dollar)"
                                                        value={this.state.phone}
                                                        maxLength={10}
                                                        onChange={(event) => this.setState({ phone: event.target.value.replace(/\D/, '') })}
                                                    />
                                                </Form.Group>
                                                <Form.Group >
                                                    <h4 className={'defaultTitle'}>Correo</h4>
                                                    <Form.Control
                                                        className="text-center"
                                                        placeholder='Correo'
                                                        arial-label="Amount (to the nearest dollar)"
                                                        value={this.state.email}
                                                        /* maxLength={10} */
                                                        onChange={(event) => this.setState({ email: event.target.value/* .replace(/\D/, '') */ })}
                                                    />
                                                </Form.Group>

                                                <Summary
                                                    /* operator={this.state.operador} */
                                                    phone={this.state.phone}
                                                    email={this.state.email}
                                                    price={this.state.price}
                                                    pinPackage={this.state.name}
                                                />
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                        <Button size="lg" variant={'info'}
                                            disabled={!this.state.name ||
                                                !this.state.phone ||
                                                !this.state.operador ||
                                                !this.state.price /* || 
                                                !this.state.email */}
                                            onClick={this.sendData}
                                        >Finalizar
                                        </Button>
                                    </Card.Footer>
                                </Card>
                            </div>
                            }

                            {/* </div> */}
                            {this.state.pay && <PayScreen
                                visible={this.state.pay}
                                closemodal={(flag) => times.setState({ pay: flag })}
                                product={this.state.product}
                                localProduct={this.state.localProduct} />}

                        </div> :
                        <div className="container" style={{ marginTop: '5%', height: 633, width: '40%' }}>
                            <p>Esta pagina no esta disponible.</p>
                            <Button
                                style={{ backgroundColor: '#019CA1', border: 'none', color: 'white', fontSize: 25, borderWidth: 2, borderColor: '#07A2BA' }}
                                onClick={() => window.open(Url_home, '_self')}>  Volver al inicio</Button>

                            <Col md={8} xs={8} sm={8} lg={8}>
                                <Image
                                    style={{ marginTop: '30%', marginLeft: '20%', marginBottom: '10%' }}
                                    src={woman} />
                            </Col>
                        </div>


                    }
                </div>
                {this.state.modalNotification && <ModalNotificationTransaction
                    closeModal={(flag) => this.closeModal(flag)}
                    type={"Pin"}
                    message={"Pin " + this.state.name + " comprado exitosamente"}
                />}
                {this.state.loading && <LoadingMovilGo />}
            </>
        )
    }
}

export default RecargasPines;
