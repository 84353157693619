import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ButtonImage = (props) => {
    const { source, onClick } = props;
    
    return (
        <button className='menu_lateral' onClick={onClick}
            style={{width:'240px',display:'flex',height:'50px',border:'none',marginTop:5,flexDirection:'row',justifyContent: 'space-between', backgroundColor:'#005F6E' }}>
            <FontAwesomeIcon style={{marginTop:'8%',width:'20%'}} size="xl"  icon={source.icon} color='white' /* onClick={onClick} */ /> 
            <p /* onClick={onClick} */ style={{marginTop:'8%',width:'80%', textAlign:'left',marginLeft:'10%',color:'white'}}>{source.name}</p>
        </button>
    );
}
