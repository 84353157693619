import Odoo from 'odoo-xmlrpc';

import { OdooConfig } from '../utilities/odoo-config';
import { Desencrypt } from '../utilities/cipher';

export const getBox = async (type, page, fecha,clienteexterno_user_id ,callback) => {
    console.log("args getBox",type, page, fecha,clienteexterno_user_id)
    const config = await OdooConfig();
    let finish = false;
    const args = [[0, { pagina: page, fecha, tipo: type,clienteexterno_user_id:clienteexterno_user_id }]]
    const data = await Desencrypt()
    const params = {
        model: "movilgo.webservice",
        method: "obtenerDatosCaja",
        args: args,
        kwargs: {}
    }

    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });


    client.connect((err, response) => {
        if (err) {
            return callback(err, false)
        } else {
            client.execute_kw(params.model, params.method, params.args, (err, response) => {
               
                finish = true
                if (err) {
                    //console.log("--->Error", err)
                    callback(err, false);
                    return false;
                }
                console.log("box----------", response)
                callback(response, true);

            });
        }

    })

}


export const getPendingPayments = async (callback) => {
    const config = await OdooConfig();
    let finish = false;
    const args = [[0]]
    const data = await Desencrypt()
    console.log("args de las cajas",args)
    console.log("paso por getPendingPayments")
    const params = {
        model: "movilgo.webservice",
        method: "listarTransaccionesPagoPayu",
        args: args,
        kwargs: {}
    }
    setTimeout(() => {
        if (finish) {
            return;
        }
        alert("Tiempo limite excedidoo");
        callback(false, "");
        return;
    }, 30000);

    const client = new Odoo({
        url: config.host,
        port: config.port,
        db: config.database,
        username: data.user,
        password: data.password
    });

    client.connect((err, response) => {
        if (err) {
            console.log("erorrers", err)
            return callback(err, false);
        }else{
            client.execute_kw(params.model, params.method, params.args, (err, response) => {
               
                finish = true
                if (err) {
                    
                    return callback(err, false);
                }
                console.log("Movimientos realizados: ", response)
                return callback(response, true);
                
            });
        }
    })
    
}
