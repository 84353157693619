import React from 'react';
import { FormatMoney } from '../../../utilities/formats';
import { Button } from 'react-bootstrap';


export const ItemBallotsUser = (props) => {
    const { itemBallotsUser, setPayment, setVisible } = props

    return (
        <>
            <tr>
                <td>{itemBallotsUser.vendedor_externo_id}</td>
                <td>{itemBallotsUser.boleta}</td>
                <td>{itemBallotsUser.nombre}</td>
                <td>{itemBallotsUser.celular}</td>
                <td>{FormatMoney(itemBallotsUser.deuda)}</td>
                <td><Button onClick={() => { setPayment(itemBallotsUser); setVisible(); }} >Abonar</Button></td>
            </tr>
        </>

    )
}